import { AvForm } from "availity-reactstrap-validation-safe";
import React, { useEffect } from "react";
import { useState } from "react";
import { PlusCircle } from "react-feather";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

function AddDrugModal({
  addmodal,
  setAddmodal,
  EditValue,
  setEditValue,
  yogam,
  setyogam,
  instru,
  setinstru,
  day,
  setday,
  characters,
  setyogavalue,
  yogavalue,
  instructionlist,
  druglist,
  drugtemplatelist,
  setModalType,
}) {
  const dispatch = useDispatch();

  console.log("EditValue",EditValue);

  const[updateday,setupdateday]=useState()

  useEffect(()=>{
    if(EditValue){
      setyogam(EditValue.DrugId)
      setinstru(EditValue.DrugInstructionId)
      setupdateday(EditValue.Days)
    }
  },[EditValue])

  const handlebuttonupdate = () => {
    let yogam_data = druglist && druglist.find((y_data) => y_data.Id == yogam);
    let yogam_name = yogam_data ? yogam_data.Name : "";
    let instruction_data =
      instructionlist && instructionlist.find((y_data) => y_data.Id == instru);
    let instruction_name = instruction_data ? instruction_data.Name : "";

    const index = characters.findIndex((item) => item.id === EditValue.id);
    const ReplacementObject = {
      DrugId: yogam||"",
      yogam: yogam_name||"",
      instruction: instruction_name||"",
      DrugInstructionId: instru||"",
      Days: updateday||0,
      Orders: 1,
    };
    characters[index] = ReplacementObject;
    setinstru("");
    setyogam("");
    setEditValue("");
    setAddmodal(!addmodal);
    setModalType("")
  };



  const handlebuttonsave = () => {
    let yogam_data = druglist && druglist.find((y_data) => y_data.Id == yogam);
    let yogam_name = yogam_data ? yogam_data.Name : "";
    let instruction_data =
      instructionlist && instructionlist.find((y_data) => y_data.Id == instru);
    let instruction_name = instruction_data ? instruction_data.Name : "";
    
    let yogamvalue = [
      ...yogavalue,
      {
        DrugId: yogam||"",
        yogam: yogam_name||"",
        instruction: instruction_name||"",
        DrugInstructionId: instru||"",
        Days: day||0,
        Orders: 1,
      },
    ];
    setyogavalue(yogamvalue);
console.log("yogamvalue",yogamvalue);
    setinstru("");
    setyogam("");
    setAddmodal(!addmodal);
    setModalType("")
  };

console.log("drugtemplatelist",drugtemplatelist);
  return (
    <Modal isOpen={addmodal} toggle={() => setAddmodal(!addmodal)}>
      <AvForm>
        <ModalHeader
          toggle={() => {
            setAddmodal(!addmodal);
            setEditValue("");
            setyogam("");
            setinstru("");
            setModalType("")
          }}
        >
          {"Add Drug"}
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <div>
              <div>
                <Label className="label text-uppercase"> Template</Label>
                {drugtemplatelist.length &&
                  drugtemplatelist.map((item, i) => {
                    return (
                      <Row className="border 1px solid mb-2" key={i}>
                        <div
                          className="text-uppercase"
                          onClick={() => {
                            console.log("item", item);

                            
                           let yogamvalue = item.DrugTemplates.map((item) => ({
                              Id: 0,
                              DrugId: item.DrugId||"",
                              yogam: item.Name||"",
                              instruction: item.Instruction||"",
                              DrugInstructionId: item.InstructionID||'',
                              Days: day||0, 
                              Orders: 1,
                            }));

                            setyogavalue(yogamvalue);
                            setAddmodal(!addmodal)
                            setModalType("")
                          }}
                        >
                          {item.Name}
                        </div>
                      </Row>
                    );
                  })}
              </div>
              <div style={{ textAlign: "center" }}>Or</div>
              <Label className="label text-uppercase">Yogam</Label>
<div className="d-flex justify-content-between">
              <Input
                type="select"
                value={yogam}
                onChange={(e) => setyogam(e.target.value)}
              >
                <option>Select</option>
                {druglist &&
                  druglist.map((item) => {
                    return <option value={item.Id}>{item.Name}</option>;
                  })}
              </Input>
             
              </div>
              <div className="d-flex justify-content-between ">
                <div style={{ width: "60%" }}>
                  <Label className="label text-uppercase mt-2">
                    Instruction
                  </Label>
                  <Input
                    type="select"
                    value={instru}
                    onChange={(e) => setinstru(e.target.value)}
                  >
                    <option>Select</option>
                    {instructionlist &&
                      instructionlist.map((item) => {
                        return <option value={item.Id}>{item.Name}</option>;
                      })}
                  </Input>
                </div>
                {EditValue ? (
                  <div className="" style={{ width: "30%" }}>
                    <Label className="label text-uppercase mt-2">Days</Label>
                    <Input
                      type="text"
                      id="company_name"
                      value={updateday}
                      onChange={(e) => setupdateday(e.target.value)}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          {EditValue ? (
            <Button color="success" type="submit" onClick={handlebuttonupdate}>
              Update
            </Button>
          ) : (
            <Button
              color="success"
              type="submit"
              onClick={() => {
                handlebuttonsave();
                setinstru("");
                setyogam("");
                setModalType("")
              }}
            >
              Save
            </Button>
          )}
        </ModalFooter>
      </AvForm>
    </Modal>
  );
}

export default AddDrugModal;
