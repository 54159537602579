import React from 'react'
import "../../CSSstyle/chatpagecss/chat.css";
import { ArrowLeft } from 'react-feather';
import profic from '../../Images/Default_pfp.jpg'
import { useNavigate } from 'react-router-dom';

function Doctorchatnav({datavalue,userid,doctid}) {
    const navigate =useNavigate()
    const contact = {
        name: 'Sanjay',
        profilePic: profic,
        status: 'Online',
      };
      // console.log("userid",doctid);
  return (
    <div className='chatnav'>
        <div className="d-flex">
          <h3 className="text-capitalize">
            <ArrowLeft
              size={22}
              onClick={() => {
                navigate(-1);
              }}
            />
            </h3>
             <img src={contact.profilePic} alt={contact.name} style={{marginLeft:'5px'}} className="profile-pic" />
             <h3 style={{marginTop:'5px'}}>{datavalue?.Name}</h3>
       
        </div>
    </div>
  )
}

export default Doctorchatnav