import { AvForm } from "availity-reactstrap-validation-safe";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Slide, toast } from "react-toastify";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { drugmaster } from "../../../redux/Admin/action";
import { drugadd } from "../../../redux/Doctor/action";
import { ErrorToast, SuccessToast } from "../../Common/toastify";
function AddDrugModal({ setAddmodal, addmodal,setSidebarVisible,sidebarVisible,setModalType }) {
  const dispatch = useDispatch();
  const [drugname, setdrugname] = useState();
  const doctordetails = JSON.parse(localStorage.getItem("UserDAta_AyuRx"));
const drugsave =useSelector((state)=>state.Admin.drugmaster)

 

  const handlesumbit = () => {
    if (drugname) {
      dispatch(
        drugmaster({
          Id:0,
          Name: drugname,
          Did: doctordetails.Id,
          Status:0,
        })
      );
      setAddmodal(!addmodal)
      setSidebarVisible(!sidebarVisible);
      setModalType("")
    }
   
  };
  return (
    <Modal isOpen={addmodal} toggle={() => setAddmodal(!addmodal)}>
      <AvForm>
        <ModalHeader toggle={() =>{ setAddmodal(!addmodal)
        setModalType("")}}>
          {"ADD DRUG"}
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <div>
              <Label className="label text-uppercase">Enter Drug Name</Label>
              <Input
                type="text"
                id="company_name"
                value={drugname}
                onChange={(e) => setdrugname(e.target.value)}
              />
            </div>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={handlesumbit}>Submit</Button>
        </ModalFooter>
      </AvForm>
    </Modal>
  );
}

export default AddDrugModal;
