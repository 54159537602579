import React from "react";
import { useEffect } from "react";
import { Card } from "react-bootstrap";
import { ArrowLeft } from "react-feather";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import "../../CSSstyle/doctorpagecss/ResponsiveTable.css";
import { searchpatient } from "../../redux/Doctor/action";
import useHardwareBackButton from "../useHardwareBackButton";
function formatDate(inputDate) {
  const date = new Date(inputDate);
  const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
  return date.toLocaleDateString(undefined, options);
}

function Patientlist() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const parms = useLocation()
  // console.log("parms",parms.state.clinicname);

  const doctordetails = JSON.parse(localStorage.getItem("UserDAta_AyuRx"));
  const patientsearch = useSelector((state) => state.Doctor.searchpatient);

  const handleBackButtonPress = () => {
    navigate("/prescription", {
      state: { clinicname: parms.state.clinicname },
    });
  };
  useHardwareBackButton(0,handleBackButtonPress);

  useEffect(() => {
    dispatch(
      searchpatient({
        Did: doctordetails.Id,
        Mobile: 0,
        clinicid:parms.state.clinicname
      })
    );
  }, []);
  console.log(patientsearch)
  return (
    <div>
      
      <div className="auth-wrapper">
        
      <div className=" pt-3 pl- underline ">
        <h3 className="text-capitalize d-flex" style={{ marginLeft: "18px" }}>
          <ArrowLeft
            size={22}
            onClick={() => {
              navigate("/prescription", {
                state: { clinicname: parms.state.clinicname },
              });
            }}
          />
          <div className="mr-2" style={{ marginLeft: "18px" }}>
            Patient List
          </div>
        </h3>
      </div>
            <div className="ResponsiveTable" style={{paddingTop:'15px'}}>
  <div id="wrapper">
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Serial No</th>
          <th>Mobile</th>
          <th>Date</th>
         
        </tr>
      </thead>
      <tbody>
      {patientsearch &&
        patientsearch.map((item, i) => (
        <tr key={i}>
          <td data-label="Patient:">{item.Pname}</td>
          <td data-label="Serial No:">{item.PSerial}</td>
          <td data-label="Mobile:">{item.Mobile}</td>
          <td data-label="Date:">{formatDate(item.Date)}</td>
        </tr>))}
      </tbody>
    </table>
  </div>
</div>
    </div>
    </div>
  );
}

export default Patientlist;