import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Slide, toast } from "react-toastify";
import { Button, Input, Label } from "reactstrap";
import { adddoctor, adddoctorError, idtype } from "../../../redux/Admin/action";
import { ErrorToast, SuccessToast } from "../../Common/toastify";

function AddDoctor() {
  const dispatch = useDispatch();
  const [name, setname] = useState();
  const [email, setemail] = useState();
  const [phone, setphone] = useState();
  const [regNo, setregNo] = useState();
  const [Gender, setGender] = useState();
  const [specialization, setspecialization] = useState();
  const [qualification, setqualification] = useState();
  const [address, setaddress] = useState();
  const [idproof, setidproof] = useState();
  const [idproofvalue, setidproofvalue] = useState();
  const [validPhoneNumber, setValidPhoneNumber] = useState(true);

  
  const GetIdType = useSelector((state)=>state.Admin.idtypeget)
  const savedoctor =useSelector((state)=> state.Admin.adddoctor)

  

  

  useEffect(() => {
    if (savedoctor) {
      
      if(savedoctor[0]?.Error === 0 && savedoctor[0]?.Id>0 ){
        toast.success(
            <SuccessToast message={'Doctor saved succesfully'} />,
            { transition: Slide, hideProgressBar: true, autoClose: 2000 }
        )
        Clear();
       
    //   }else {
    //     toast.error(
    //         <ErrorToast msg={'Error'} />,
    //         { transition: Slide, hideProgressBar: true, autoClose: 2000 }
    //     )
    }

    }  
}, [savedoctor])
  const Clear = () => {
    setname("");
    setemail("");
    setphone("");
    setregNo("");
    setGender("");
    setspecialization("");
    setqualification("");
    setaddress("");
    setidproof("");
    setidproofvalue("");
    dispatch(adddoctorError({}))
  };

  useEffect(()=>{
    dispatch(
      idtype()
    )
  },[])

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    setphone(value);
    validatePhoneNumber(value);
  };

  const validatePhoneNumber = (value) => {
    const phoneRegex = /^[0-9]{10}$/;

    const isValid = phoneRegex.test(value);

    setValidPhoneNumber(isValid);
  };

  const handlesubmit = () => {
   
    if(name && phone && Gender && email && regNo && specialization && qualification ){
      dispatch(
        adddoctor({
          Id: 1,
    Name: name,
    ClinicId: 0,
    Address: address||"",
    RegNo: regNo||"",
    IdType: idproof||"",
    IdNo: idproofvalue||"",
    Quanlification: qualification||"",
    Mobile: phone,
    EmailId: email||"",
    Gender: Gender,
    Status: 0,
    Remarks: '',
    Specialization: specialization||""
        })
      );
     
     }else{
      alert("Fill All Mandatory")
     }
  };
  return (
    <div>
      <div className="AddUser">
        <div className="mb-2">
          <Label className="label text-uppercase"> Doctor's Name<span className="field-required">*</span></Label>
          <Input
            type="text"
            required=""
            className="no-spinners"
            value={name}
            onChange={(e) => setname(e.target.value)}
          />
        </div>
        <div className="mb-2">
          <Label className="label text-uppercase"> Email id<span className="field-required">*</span></Label>
          <Input
            type="text"
            required=""
            className="no-spinners"
            value={email}
            onChange={(e) => setemail(e.target.value)}
          />
        </div>
        <div className="mb-2">
          <Label className="label text-uppercase"> Registration Number<span className="field-required">*</span></Label>
          <Input
            type="text"
            required=""
            className="no-spinners"
            value={regNo}
            onChange={(e) => setregNo(e.target.value)}
          />
        </div>
        <div className="mb-2 d-flex justify-content-between">
          <div>
            {" "}
            <Label className="label text-uppercase"> Phone number<span className="field-required">*</span></Label>
            <Input
              type="number"
              required=""
              className="no-spinners"
              value={phone}
              onChange={handlePhoneNumberChange}
            />
          </div>
          
          <div>
            {" "}
            <Label className="label text-uppercase"> Gender<span className="field-required">*</span></Label>
            <Input
              type="select"
              name="customerName"
              id="customerNameGender"
              required
              className="UserTypeSelect"
              value={Gender}
              onChange={(e) => setGender(e.target.value)}
            >
              <option value="default" >
                Select Gender
              </option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </Input>
          </div>
        </div>
        {!validPhoneNumber && (
        <p style={{ color: 'red' }}> enter a valid 10-digit phone .</p>
      )}
        <div className="mb-2">
          <Label className="label text-uppercase">specialization<span className="field-required">*</span></Label>
          <Input
            type="text"
            required=""
            className="no-spinners"
            value={specialization}
            onChange={(e) => setspecialization(e.target.value)}
          />
        </div>
        <div className="mb-2">
          <Label className="label text-uppercase">Qualification<span className="field-required">*</span></Label>
          <Input
            type="textarea"
            required=""
            className="no-spinners"
            value={qualification}
            onChange={(e) => setqualification(e.target.value)}
          />
        </div>
        <div className="mb-2">
          <Label className="label text-uppercase">Address<span className="field-required">*</span></Label>
          <Input
            type="textarea"
            required=""
            className="no-spinners"
            value={address}
            onChange={(e) => setaddress(e.target.value)}
          />
        </div>
        <div className="d-flex justify-content-between  mb-3">
          <div>
            <Label className="label text-uppercase">Id proof<span className="field-required">*</span></Label>
            <Input
              type="select"
              value={idproof}
              onChange={(e) => setidproof(e.target.value)}
            >
              <option>Select ID Proof</option>
              {GetIdType.length &&GetIdType.map((item,i)=>{
                return(
                  <option key={i} value={item.Id}>{item.Name}</option>
                )
              })}
            </Input>
          </div>
          <div>
            <Label className="label text-uppercase">Id proof Number<span className="field-required">*</span></Label>
            <Input
              type="text"
              value={idproofvalue}
              placeholder="ID NO"
              onChange={(e) => setidproofvalue(e.target.value)}
            />
          </div>
        </div>
        <div className="row  d-flex mt-4">
          <div className="col-4">
            <Button
              color="danger"
              className="ClearButton"
                onClick={Clear}
            >
              <i
                class="fa-solid fa-arrows-rotate"
                style={{ marginRight: "8px" }}
              ></i>
              Clear
            </Button>
          </div>
          <div className="col-8">
            <Button color="success" onClick={handlesubmit}>
              <i
                className="fa-solid fa-check"
                style={{ marginRight: "8px" }}
              ></i>
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddDoctor;
