
import React, { useEffect } from 'react'
import { ArrowLeft } from 'react-feather';
import { useNavigate, useParams } from 'react-router-dom';
import { Card } from 'reactstrap';
import { useSelector } from 'react-redux';
import { chattopatient } from '../../redux/Doctor/action';
import { useDispatch } from 'react-redux';
import profic from '../../Images/Default_pfp.jpg'
import useHardwareBackButton from '../useHardwareBackButton';
function Chattopatient() {

 

    const navigate = useNavigate()
    const dispatch =useDispatch()
    const {id,userid} =useParams()

    const chatwithpatientlist = useSelector((state) => state.Doctor.chatwithpatient);
console.log("userid",userid);

const contact = {
    name: 'Sanjay',
    profilePic: profic,
    status: 'Online',
  };



    useEffect(() => {
        dispatch(
            chattopatient({
            Doctorid: id,
            
          })
        );
      }, []);

    
    
  return (
    <div>
      
    <div className="auth-wrapper">
      
    <div className=" pt-3 pl- underline ">
      <h3 className="text-capitalize d-flex" style={{ marginLeft: "18px" }}>
       
        <div className="mr-2" style={{ marginLeft: "18px" }}>
          Patient Chat List
        </div>
      </h3>
    </div>

    {chatwithpatientlist.length &&
      chatwithpatientlist.map((item, i) => {
        return (
          <Card className=" mb-1 " key={i} >
            <div className="container-fluid m-3 "  onClick={() => {
                    navigate("/Doctorchatpage", {
                        state: { data: item,id:userid,doctid:id }
                      })
                  }}>
              
              <div className="d-flex justify-content-between">
                <div className="d-flex justify-content-start" >
                <img src={contact.profilePic} alt={contact.name} style={{marginLeft:'5px'}} className="profile-pic" />
                  <div>{item.Name}</div>
                </div>
                
              </div>
            </div>
          </Card>
        );
      })}
  </div>
  </div>
  )
}

export default Chattopatient