import { AvForm } from "availity-reactstrap-validation-safe";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { PlusCircle } from "react-feather";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";

function MedicalReportModal({
  addmodal,
  setAddmodal,
  attributelist,
  setModalType,
  setmedical,
  medical,
  

}) {

  

const handleAttributeChange = (e, Id,attribteid) => {
  const newValue = e.target.value || "";
 
  const index = attributelist.findIndex((item) => (Id=== 0&&attribteid!==0) ? item.Id === attribteid : item.Id === Id);

  if (index !== -1) {
    const newEntry = {
      Id: 0,
      AttributId: attributelist[index].Id || "",
      value: newValue,
    };
    const newData = [...medical];
    newData[index] = newEntry;
   
    const cleanedData = newData.map((entry,i) => entry || { Id: 0, AttributId:  attributelist[i].Id, value: "" });
    const targetArray = (Id === 0 && attribteid!==0) ? medical : attributelist;
    const additionalEntries = targetArray.slice(newData.length).map(item => ({
      Id: 0,
      AttributId: (Id === 0 && attribteid!==0) ? item.AttributId : item.Id,
      value: item.value||""
    }));
    const finalCleanedData = cleanedData.concat(additionalEntries);
    setmedical(finalCleanedData);

  }
};
console.log("medical",medical);

  return (
    <Modal isOpen={addmodal} toggle={() => setAddmodal(!addmodal)}>
      <AvForm>
        <ModalHeader toggle={() => {setAddmodal(!addmodal)
 setModalType("")
        }}>
          {"Medical Report"}
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            
              
              {medical&&medical.length?<div>{medical &&
              medical.map((item, i) => {
                let yogam_data = attributelist && attributelist.find((y_data) => y_data.Id == item.AttributId);
                let yogam_name = yogam_data ? yogam_data.Name : "";
                return (
                  <div key={i}>
                    <div className="mt-2 d-flex ">
                     <div className=" d-flex justify-content-start" style={{width:'50%'}}> <label>{yogam_name}</label></div>
                     <div >   <Input type="text" style={{ marginLeft: '10px' }}   defaultValue={item.value ? item.value: ''} onChange={(e) => handleAttributeChange(e, item.Id,item.AttributId)}
              /></div>
                    </div>
                  </div>
                );
              })}</div>:<div>{attributelist &&
              attributelist.map((item, i) => {
                return (
                  <div key={i}>
                    <div className="mt-2 d-flex ">
                     <div className=" d-flex justify-content-start" style={{width:'50%'}}> <label>{item.Name}</label></div>
                     <div >   <Input type="text" style={{ marginLeft: '10px' }} onChange={(e) => handleAttributeChange(e, item.Id,0)}
              /></div>
                    </div>
                  </div>
                );
              })}</div>}
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            type="submit"
            onClick={(e) => {
              setAddmodal(!addmodal);
              setModalType("")
            }}
          >
            Save
          </Button>
        </ModalFooter>
      </AvForm>
    </Modal>
  );
}

export default MedicalReportModal;
