// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports

import common from '../Common/commonReducer'
import Report from '../Report/reducer'
import Admin from '../Admin/reducer'
import Doctor from '../Doctor/reducer'
import Patient from '../Patient/reducer'


const rootReducer = combineReducers({
  Report,
  common,
  Admin,
  Doctor,
  Patient
})

export default rootReducer