import { actionTypes } from "./action";
export const initialState = {
    adduservalue: "",
    getusertypevalue:'',
    attribute:'',
    drugmaster:'',
    adddoctor:'',
    idtypeget:'',
    clinc:'',
    feedbackvalue:'',
    Adminnotification:'',
    listfeedback:''
   
}
const adminReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_USER:
            return { ...state, adduservalue: "" };
        case actionTypes.ADD_USER_SUCCESS:
            return { ...state, adduservalue: action.payload };
        case actionTypes.ADD_USER_ERROR:
            return { ...state, adduservalue: "" };

        case actionTypes.GET_USER_TYPE:
            return { ...state, getusertypevalue: "" };
        case actionTypes.GET_USER_TYPE_SUCCESS:
            return { ...state, getusertypevalue: action.payload };
        case actionTypes.GET_USER_TYPE_ERROR:
            return { ...state, getusertypevalue: "error" };

        case actionTypes.ADD_ATTRIBUTE:
            return { ...state, attribute: "" };
        case actionTypes.ADD_ATTRIBUTE_SUCCESS:
            return { ...state, attribute: action.payload };
        case actionTypes.ADD_ATTRIBUTE_ERROR:
            return { ...state, attribute: "error" };

        case actionTypes.DRUG_MASTER:
            return { ...state, drugmaster: "" };
        case actionTypes.DRUG_MASTER_SUCCESS:
            return { ...state, drugmaster: action.payload };
        case actionTypes.DRUG_MASTER_ERROR:
            return { ...state, drugmaster: "error" };

        case actionTypes.ADD_DOCTOR:
            return { ...state, adddoctor: "" };
        case actionTypes.ADD_DOCTOR_SUCCESS:
            return { ...state, adddoctor: action.payload };
        case actionTypes.ADD_DOCTOR_ERROR:
            return { ...state, adddoctor: "error" };

        case actionTypes.ID_TYPE:
            return { ...state, idtypeget: "" };
        case actionTypes.ID_TYPE_SUCCESS:
            return { ...state, idtypeget: action.payload };
        case actionTypes.ID_TYPE_ERROR:
            return { ...state, idtypeget: "error" };

        case actionTypes.ADD_CLINIC:
            return { ...state, clinc: "" };
        case actionTypes.ADD_CLINIC_SUCCESS:
            return { ...state, clinc: action.payload };
        case actionTypes.ADD_CLINIC_ERROR:
            return { ...state, clinc: "error" };

        case actionTypes.ADD_FEEDBACK:
            return { ...state, feedbackvalue: "" };
        case actionTypes.ADD_FEEDBACK_SUCCESS:
            return { ...state, feedbackvalue: action.payload };
        case actionTypes.ADD_FEEDBACK_ERROR:
            return { ...state, feedbackvalue: "error" };

        case actionTypes.ADMIN_NOTIFICATION:
            return { ...state, Adminnotification: "" };
        case actionTypes.ADMIN_NOTIFICATION_SUCCESS:
            return { ...state, Adminnotification: action.payload };
        case actionTypes.ADMIN_NOTIFICATION_ERROR:
            return { ...state, Adminnotification: "error" };

        case actionTypes.GET_FEEDBACK:
            return { ...state, listfeedback: "" };
        case actionTypes.GET_FEEDBACK_SUCCESS:
            return { ...state, listfeedback: action.payload };
        case actionTypes.GET_FEEDBACK_ERROR:
            return { ...state, listfeedback: "error" };

       

        default:
            return state;
    }
};

export default adminReducer;