import { Button } from "reactstrap";
import React, { useEffect, useState } from "react";
import { Card } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { patienthistory } from "../../redux/Patient/action";
import { useSelector } from "react-redux";
import Viewpage from "./Viewpage";
import { baseUrl } from "../../configs/config";
import { App } from "@capacitor/app";
import useHardwareBackButton from "../useHardwareBackButton";


function formatDate(inputDate) {
  
  const date = new Date(inputDate);
  const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
  return date.toLocaleDateString(undefined, options);
}

function Homepage({patient}) {
  
  useHardwareBackButton(1)
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const patientdetails = JSON.parse(localStorage.getItem("UserDAta_AyuRx"));
  
  console.log("patient", patient[0]);

  const prescDate = patient?.Date;
  const prescriptiondate = formatDate(prescDate);

 
  return (
    <div>
      <div className="bxs_user_dash_wrap">
        <div className="bxs_user_dash_left">
          <div className="bxs_user_dash_left_head border-bottom mb-3">
            <strong className="d-flex ">
              <div style={{ paddingRight: "10px" }}>
                {patient&&patient[0]?.Name}
              </div>
              
            </strong>
          </div>
        </div>
      </div>
      {patient.length   &&
        patient.map((item, i) => {
          // console.log("item",item);
          const prescDate = item?.Date;
  const prescriptiondate = formatDate(prescDate);

          return (
            <div key={i}>
              <Card className=" mb-3">
                <div className="container-fluid m-3">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex justify-content-start">
                      <div> Serial No:</div>
                      <div>{item.PatientSerial}</div>
                    </div>
                   
                  </div>
                  <div className="d-flex justify-content-start">
                      <div>Date:</div>
                      <div>{prescriptiondate}</div>
                    </div>
                  <div className="d-flex justify-content-start mt-2">
                    <div>Doctor Name:</div>
                    <div>{item.DoctorName}</div>
                  </div>
                  <div className="d-flex justify-content-start mt-2">
                    <div>Clinic Name:</div>
                    <div>{item.Clinic}</div>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <Button
                      color="success"
                      onClick={() => navigate("/viewpage", { state: { data: item} })}
                    >
                      View
                    </Button>
                    <Button color="success" 
                    // onClick={() => navigate("/chat",{ state: { data: item} })}
                    onClick={()=>{window.open(`${baseUrl}/chat/${item.ChatMasterid}/${item.DoctorName}/${patientdetails.Id}/${patientdetails.UserId}`)}}
                    
                    > Chat</Button>
                  </div>
                </div>
              </Card>
            </div>
          );
        })}

    </div>
  );
}

export default Homepage;
