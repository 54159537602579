import { takeEvery, put, call, all } from "redux-saga/effects";
import {actionTypes,patienthistorySucces,patienthistoryError,doctorchatidSucces,
doctorchatidError} from "./action";
import {startLoader,stopLoader} from '../Common/Loader/action'
import patientservice from "../../service/patient/patientservice";


function* patienthistory({ payload }) {
    try {
      yield put(startLoader());
      const data = yield call(patientservice.patienthistory, payload);
      yield put(patienthistorySucces(data));
    } catch (error) {
      yield put(patienthistoryError(error));
      console.log(error);
    } finally {
      yield put(stopLoader());
    }
  }

function* doctorchatid({ payload }) {
    try {
      yield put(startLoader());
      const data = yield call(patientservice.doctorchatid, payload);
      yield put(doctorchatidSucces(data));
    } catch (error) {
      yield put(doctorchatidError(error));
      console.log(error);
    } finally {
      yield put(stopLoader());
    }
  }


  export default function* rootSaga() {
    yield all([takeEvery(actionTypes.PATIENT_HISTORY, patienthistory)]);
    yield all([takeEvery(actionTypes.GET_DOCTOR_ID_CHAT, doctorchatid)]);
   
   
  }