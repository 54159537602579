
import React, { useEffect, useState } from "react";
import { Camera, FACING_MODES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import useHardwareBackButton from "../useHardwareBackButton";

function Camerapage({ datastore, setdisplay,setcameraimage,setdatastore,cameraimage }) {
  const [cameraActive, setCameraActive] = useState(true);
  const [photoDataUri, setPhotoDataUri] = useState(null);
 
  const navigate = useNavigate();
  console.log("datastore",datastore);


  function handleTakePhoto(dataUri) {
    // Do stuff with the photo...
    console.log("takePhoto");
    setPhotoDataUri(dataUri);
    setCameraActive(false);
  }

  const handleBackButtonPress = () => {
    setdatastore(...datastore);
      setdisplay('list');
  };
  useHardwareBackButton(0,handleBackButtonPress);

  return (
    <div>
       
      {cameraActive ? (
        <div>
          <Camera
            isFullscreen={true}
            idealFacingMode={FACING_MODES.ENVIRONMENT}
            isImageMirror={false}
            onTakePhoto={(dataUri) => {
              handleTakePhoto(dataUri);
            }}
          />
        </div>
      ) : (
        <div>
          {photoDataUri && (
            <div>
              <img src={photoDataUri} alt="Captured" style={{width:'100%'}}/>
            </div>
          )}
          <div className="d-flex mt-3  justify-content-center  ">
          <div style={{marginRight:'45px'}}> <Button
            
            color="success"
            onClick={() => {
              setdisplay('list')
              setcameraimage((prevCameraImage) => [{ Base64Image: photoDataUri }, ...prevCameraImage]);
              setdatastore(...datastore)
            }}
          >
            Done
          </Button></div>
          <div> <Button
              color="success"

              onClick={() => {
                setCameraActive(true);
                setPhotoDataUri(null);
              }}
            >
              Retake
            </Button></div>
           
           
          </div>
        </div>
      )}
    </div>
  );
}

export default Camerapage;