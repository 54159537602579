export const actionTypes = {
  DAILY_PATIENT: "DAILY_PATIENT",
  DAILY_PATIENT_SUCCESS: "DAILY_PATIENT_SUCCESS",
  DAILY_PATIENT_ERROR: "DAILY_PATIENT_ERROR",
};

export const dailypatient = (payload) => {
    return {
      type: actionTypes.DAILY_PATIENT,
      payload,
    };
  };
  export const dailypatientSucces = (payload) => {
    return {
      type: actionTypes.DAILY_PATIENT_SUCCESS,  
      payload,
    };
  };
  export const dailypatientError = (payload) => {
    return {
      type: actionTypes.DAILY_PATIENT_ERROR,
      payload,
    };
  };