export const actionTypes = {
  ADD_USER: "ADD_USER",
  ADD_USER_SUCCESS: "ADD_USER_SUCCESS",
  ADD_USER_ERROR: "ADD_USER_ERROR",

  GET_USER_TYPE: "GET_USER_TYPE",
  GET_USER_TYPE_SUCCESS: "GET_USER_TYPE_SUCCESS",
  GET_USER_TYPE_ERROR: "GET_USER_TYPE_ERROR",

  ADD_ATTRIBUTE: "ADD_ATTRIBUTE",
  ADD_ATTRIBUTE_SUCCESS: "ADD_ATTRIBUTE_SUCCESS",
  ADD_ATTRIBUTE_ERROR: "ADD_ATTRIBUTE_ERROR",

  DRUG_MASTER: "DRUG_MASTER",
  DRUG_MASTER_SUCCESS: "DRUG_MASTER_SUCCESS",
  DRUG_MASTER_ERROR: "DRUG_MASTER_ERROR",
  
  ADD_DOCTOR: "ADD_DOCTOR",
  ADD_DOCTOR_SUCCESS: "ADD_DOCTOR_SUCCESS",
  ADD_DOCTOR_ERROR: "ADD_DOCTOR_ERROR",
  
  ID_TYPE: "ID_TYPE",
  ID_TYPE_SUCCESS: "ID_TYPE_SUCCESS",
  ID_TYPE_ERROR: "ID_TYPE_ERROR",
  
  ADD_CLINIC: "ADD_CLINIC",
  ADD_CLINIC_SUCCESS: "ADD_CLINIC_SUCCESS",
  ADD_CLINIC_ERROR: "ADD_CLINIC_ERROR",
  
  ADD_FEEDBACK: "ADD_FEEDBACK",
  ADD_FEEDBACK_SUCCESS: "ADD_FEEDBACK_SUCCESS",
  ADD_FEEDBACK_ERROR: "ADD_FEEDBACK_ERROR",
  
  ADMIN_NOTIFICATION: "ADMIN_NOTIFICATION",
  ADMIN_NOTIFICATION_SUCCESS: "ADMIN_NOTIFICATION_SUCCESS",
  ADMIN_NOTIFICATION_ERROR: "ADMIN_NOTIFICATION_ERROR",
  
  GET_FEEDBACK: "GET_FEEDBACK",
  GET_FEEDBACK_SUCCESS: "GET_FEEDBACK_SUCCESS",
  GET_FEEDBACK_ERROR: "GET_FEEDBACK_ERROR",
  
  
};
export const adduser = (payload) => {
  return {
    type: actionTypes.ADD_USER,
    payload,
  };
};
export const adduserSucces = (payload) => {
  return {
    type: actionTypes.ADD_USER_SUCCESS,  
    payload,
  };
};
export const adduserError = (payload) => {
  return {
    type: actionTypes.ADD_USER_ERROR,
    payload,
  };
};

export const addattribute = (payload) => {
  return {
    type: actionTypes.ADD_ATTRIBUTE,
    payload,
  };
};
export const addattributeSucces = (payload) => {
  return {
    type: actionTypes.ADD_ATTRIBUTE_SUCCESS,  
    payload,
  };
};
export const addattributeError = (payload) => {
  return {
    type: actionTypes.ADD_ATTRIBUTE_ERROR,
    payload,
  };
};

export const getusertype = (payload) => {
  return {
    type: actionTypes.GET_USER_TYPE,
    payload,
  };
};
export const getusertypeSucces = (payload) => {
  return {
    type: actionTypes.GET_USER_TYPE_SUCCESS,  
    payload,
  };
};
export const getusertypeError = (payload) => {
  return {
    type: actionTypes.GET_USER_TYPE_ERROR,
    payload,
  };
};

export const drugmaster = (payload) => {
  return {
    type: actionTypes.DRUG_MASTER,
    payload,
  };
};
export const drugmasterSucces = (payload) => {
  return {
    type: actionTypes.DRUG_MASTER_SUCCESS,  
    payload,
  };
};
export const drugmasterError = (payload) => {
  return {
    type: actionTypes.DRUG_MASTER_ERROR,
    payload,
  };
};

export const adddoctor = (payload) => {
  return {
    type: actionTypes.ADD_DOCTOR,
    payload,
  };
};
export const adddoctorSucces = (payload) => {
  return {
    type: actionTypes.ADD_DOCTOR_SUCCESS,  
    payload,
  };
};
export const adddoctorError = (payload) => {
  return {
    type: actionTypes.ADD_DOCTOR_ERROR,
    payload,
  };
};

export const idtype = (payload) => {
  return {
    type: actionTypes.ID_TYPE,
    payload,
  };
};
export const idtypeSucces = (payload) => {
  return {
    type: actionTypes.ID_TYPE_SUCCESS,  
    payload,
  };
};
export const idtypeError = (payload) => {
  return {
    type: actionTypes.ID_TYPE_ERROR,
    payload,
  };
};
export const addclinic = (payload) => {
  return {
    type: actionTypes.ADD_CLINIC,
    payload,
  };
};
export const addclinicSucces = (payload) => {
  return {
    type: actionTypes.ADD_CLINIC_SUCCESS,  
    payload,
  };
};
export const addclinicError = (payload) => {
  return {
    type: actionTypes.ADD_CLINIC_ERROR,
    payload,
  };
};
export const addfeedback = (payload) => {
  return {
    type: actionTypes.ADD_FEEDBACK,
    payload,
  };
};
export const addfeedbackSucces = (payload) => {
  return {
    type: actionTypes.ADD_FEEDBACK_SUCCESS,  
    payload,
  };
};
export const addfeedbackError = (payload) => {
  return {
    type: actionTypes.ADD_FEEDBACK_ERROR,
    payload,
  };
};
export const adminnotifi = (payload) => {
  return {
    type: actionTypes.ADMIN_NOTIFICATION,
    payload,
  };
};
export const adminnotifiSucces = (payload) => {
  return {
    type: actionTypes.ADMIN_NOTIFICATION_SUCCESS,  
    payload,
  };
};
export const adminnotifiError = (payload) => {
  return {
    type: actionTypes.ADMIN_NOTIFICATION_ERROR,
    payload,
  };
};
export const getfeedback = (payload) => {
  return {
    type: actionTypes.GET_FEEDBACK,
    payload,
  };
};
export const getfeedbackSucces = (payload) => {
  return {
    type: actionTypes.GET_FEEDBACK_SUCCESS,  
    payload,
  };
};
export const getfeedbackError = (payload) => {
  return {
    type: actionTypes.GET_FEEDBACK_ERROR,
    payload,
  };
};

