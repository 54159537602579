
import { AvForm } from "availity-reactstrap-validation-safe";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { TiTickOutline } from "react-icons/ti";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";
import { doctornoti, doctornotiapprove, notificationstatus } from "../../../redux/Doctor/action";
import { AiOutlineDelete } from "react-icons/ai";

function NotificationApprove({ setAddmodal, addmodal,setModalType }) {

   
    const dispatch = useDispatch()
    const doctordetails = JSON.parse(localStorage.getItem("UserDAta_AyuRx"));
    const Notificationlist = useSelector((state) => state.Doctor.doctornotificationapprove);
    const statusnotification = useSelector((state) => state.Doctor.statusnoti);

    

   useEffect(()=>{
    dispatch(doctornotiapprove({
      did: doctordetails.Id,
      
    }))
   },[])

   useEffect(()=>{
    if(statusnotification){
      dispatch(doctornotiapprove({
        did: doctordetails.Id,
        }))
    }
   },[statusnotification])
  return (
    <Modal isOpen={addmodal} toggle={() => setAddmodal(!addmodal)}>
    <AvForm>
      <ModalHeader toggle={() => {setAddmodal(!addmodal)
      setModalType("")}}>
        {"Notifications From Admin"}
      </ModalHeader>
      <ModalBody>
        <FormGroup>
        <div className="mt-3">
          <Table responsive>
            <thead>
              <tr>
                
                <th scope="col">MESSAGE </th>
                <th scope="col">APPROVE</th>
                <th scope="col">REJECT</th>
              </tr>
            </thead>
            {Notificationlist.length ? (
              Notificationlist.map((data, i) => {
                return (
                  <tbody key={i} className="list-body">
                    <tr>
                      
                      <td>{data.MessageFromAdmin}</td>
                      <td><TiTickOutline size={25} color='green' onClick={()=>{
                        dispatch(doctornoti({
                          Message:data.MessageFromAdmin,
                          DoctorId: doctordetails.Id,
                          
                        }))
                        dispatch(notificationstatus({
                          type:1,
                          notiid: data.Id,
                          
                        }))
                       

                      }}/></td>
                      <td><AiOutlineDelete size={25} color='red' onClick={()=>{
                        dispatch(notificationstatus({
                          type:2,
                          notiid: data.Id,
                          
                        }))
                     
                      }}/></td>
                    </tr>
                  </tbody>
                );
              })
            ) : (
              <div className="text-center mt-3">No Message..!</div>
            )}
          </Table>
        </div>
        </FormGroup>
      </ModalBody>
      
    </AvForm>
  </Modal>
  )
}

export default NotificationApprove