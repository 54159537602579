import { AvForm } from 'availity-reactstrap-validation-safe';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { searchpatient, searchpatientError } from '../../../redux/Doctor/action';

function SearchModal({setserachphone,serachphone,setAddmodal,addmodal,clinicname,setModalType,setpatientsdetails,patientsdetails}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const[patient,setpatient]=useState()
    const doctordetails = JSON.parse(localStorage.getItem("UserDAta_AyuRx"));
    const patientsearch = useSelector((state) => state.Doctor.searchpatient);
    console.log("serachphone",serachphone);
    console.log("clinicname",clinicname);

    

    useEffect(()=>{
      dispatch(
              searchpatient({
                Did: doctordetails.Id,
                Mobile: 0,
                clinicid:clinicname
              })
            );
    },[])

   

    const handleSearch = () => {
      // setCurrentPage(1)
      const searchValue = serachphone.toLowerCase();
      // setSearchQuery(searchValue);
  
      const filteredData = patientsearch.filter((item) => {
        for (const key in item) {
          const value = item[key];
          if (typeof value === 'string' && value.toLowerCase().includes(searchValue)) {
            return true;
          } else if (typeof value === 'number' && value.toString().includes(searchValue)) {
            return true;
          }
        }
        return false;
      });
      setpatient(filteredData);
      if ( !(filteredData.length)   ) {
        window.alert("No Such  Patient Found");
        window.location.reload(false);
        }
      console.log(filteredData)
     
    };


  return (
    <Modal isOpen={addmodal} toggle={() => setAddmodal(!addmodal)}>
    <AvForm>
      <ModalHeader toggle={() => {setserachphone("")
      setAddmodal(!addmodal)
      setModalType("")
     }}>
        {"Search Patient"}
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <div>
            <Label className="label text-uppercase">Search with Name and Phone Number</Label>
            <Input
              type="text"
              id="company_name"
              value={serachphone}
              onChange={(e) => setserachphone(e.target.value)}
            />
          </div>
          {/* <div>{!patientsearch=='error'?"Patient List":''}</div> */}
          {patient?patient?.map((item)=>{
            return(
              <option  className='mt-3' onClick={() => {
                console.log("item",item);
                setpatientsdetails(item)
                setAddmodal(!addmodal);
                setserachphone("");
                setpatient("");
                dispatch(searchpatientError(""))
                //  
               
               
              }}>{item.Pname}</option>
            )
          }):''}
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button
          color="success"
          type="submit"
          onClick={handleSearch}
        >
          Search
        </Button>
      </ModalFooter>
    </AvForm>
  </Modal>
  )
}

export default SearchModal