import React, { useEffect, useState } from "react";
import Logo from "../../Images/Img/Logo.png";
import "../../CSSstyle/AdminPageCss/AdminPage.css";
import { Nav, Tab } from "react-bootstrap";
import { AiOutlineHome, AiOutlineSearch ,AiOutlineSetting} from "react-icons/ai";
import { BsQrCode } from "react-icons/bs";
import { GiMedicines } from "react-icons/gi";
import { VscFeedback } from "react-icons/vsc";
import { BiLogOut,BiClinic } from "react-icons/bi";
import { TbReportSearch } from "react-icons/tb";
import AddUserPng from "../../Images/Img/add-user.png";
import Homepage from "../Patient/Homepage";
import FeedbackForm from '../Doctor/Modals/FeedbackForm'
import { useNavigate } from "react-router-dom";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import QRcodemodel from "../Patient/QRcodemodel";
import { useDispatch } from "react-redux";
import { patienthistory } from "../../redux/Patient/action";
import { useSelector } from "react-redux";
import { App } from "@capacitor/app";

function Dashboard() {
  const [activeTab, setActiveTab] = useState("A");
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [addmodal, setAddmodal] = useState(false);
  const [modalType, setModalType] = useState("");
  const navigate =useNavigate()
const dispatch =useDispatch()

const patientdetails = JSON.parse(localStorage.getItem("UserDAta_AyuRx"));
const patient = useSelector((state) => state.Patient.patienthistorydetails);
console.log("patient",patient);

  useEffect(() => {
    dispatch(
      patienthistory({
        PatientId: patientdetails.Id,
      })
    );
  }, []);

  const handleTabSelect = (tab) => {
    setActiveTab(tab);
  };


  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handlelogout = () => {
    const confirmeassage = "do you want to logout?";
    if (window.confirm(confirmeassage)) {
      localStorage.removeItem("UserType_AyuRx");
      localStorage.removeItem("UserDAta_AyuRx");
      navigate("/");
    }
  };

  const rendermodal = () => {
    switch (modalType) {
      case "feedback":
        return (
          <FeedbackForm addmodal={addmodal} setAddmodal={setAddmodal} setSidebarVisible={setSidebarVisible}
          sidebarVisible={sidebarVisible}/>
        );
      case "qrcode":
        return (
          <QRcodemodel addmodal={addmodal} setAddmodal={setAddmodal} setSidebarVisible={setSidebarVisible}
          sidebarVisible={sidebarVisible}/>
        );
      default:
        break;
    }
  };

  return (
    <div className="bodybg cv-bodypadding">
      <div className="header-wrapper">
        <div className="container-fluid">
          <div className="row">
           
          <div className="d-flex">
            <button className="notification-bell" onClick={toggleDrawer}>
              {" "}
              <i
                id="menubtn"
                className={`fas ${sidebarVisible ? "fa-bars" : "fa-bars"}`}
              ></i>
            </button>
            <Drawer
              open={isOpen}
              onClose={toggleDrawer}
              direction="left"
              className="bla bla bla"
            >
              <div>
                <div
                  className="d-flex"
                  style={{
                    padding: "10px",
                    borderBottom: "1px  groove",
                    backgroundColor: "#46cd46",
                  }}
                  onClick={() => {
                    setIsOpen((prevState) => !prevState);
                  }}
                >
                  <AiOutlineHome size={24} />
                  <div style={{ paddingLeft: "40px" }}>Home</div>
                </div>
                
               
                <div
                  className="d-flex"
                  style={{ padding: "10px", borderBottom: "1px  groove" }}
                  onClick={() => {
                    setModalType("qrcode");
                    setAddmodal(true);
                    setIsOpen((prevState) => !prevState);
                  }}
                >
                  <BsQrCode size={24} />
                  <div style={{ paddingLeft: "40px" }}>QR Code</div>
                </div>
                
                
                <div
                  className="d-flex"
                  style={{ padding: "10px", borderBottom: "1px  groove" }}
                  onClick={() => {
                    setModalType("feedback");
                    setAddmodal(true);
                    setIsOpen((prevState) => !prevState);
                  }}
                >
                  <VscFeedback size={24} />
                  <div style={{ paddingLeft: "40px" }}  >Write your Feedback</div>
                </div>
                <div
                  className="d-flex"
                  style={{ padding: "10px", borderBottom: "1px  groove" }}
                  onClick={handlelogout}
                >
                  <BiLogOut size={24} />
                  <div style={{ paddingLeft: "40px" }}>Logout</div>
                </div>
              </div>
            </Drawer>

            <img className="cv-reg-taglahlogo" src={Logo} alt="AruRX" wi />
          </div>
          </div>
        </div>
      </div>

      <Tab.Container activeKey={activeTab} onSelect={handleTabSelect}>
        <div className="savedate-sidebar">
          <Nav
            className="flex-column "
            fill
            variant="pills"
            id="v-pills-tab"
            aria-orientation="vertical"
          >
            <Nav.Link eventKey="A" style={{ textAlign: "left" }}>
              <span
                style={{
                  backgroundImage: `url(${AddUserPng})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></span>
              <h6>Dashboard</h6>
            </Nav.Link>
          </Nav>
        </div>
        <div className="container-fluid">
          <Tab.Content id="v-pills-tabContent">
            <Tab.Pane eventKey="A" style={{ marginTop: "60px" }}>
              <Homepage patient={patient}/>
            </Tab.Pane>
          </Tab.Content>
        </div>
      </Tab.Container>
     
       {addmodal && rendermodal()}
    </div>
  );
}

export default Dashboard;
