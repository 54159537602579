import React, { useState, useEffect } from "react";
import "../CSSstyle/login/Login.css";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../Images/Img/Logo.png";
import axios from "axios";
import { siteUrl } from "../configs/config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PushNotifications } from '@capacitor/push-notifications';
import useHardwareBackButton from "./useHardwareBackButton";


function Login() {
  useHardwareBackButton(1)
  const navigate = useNavigate();
  const [Phone, setPhone] = useState("");
  const [IsLogin, setIsLogin] = useState(true);
  const [OTP, setOTP] = useState("");
  const [IsOtp, setIsOtp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleToggleControl = (val) => {
    if (val === 1) {
      setIsLogin(true);
      setIsOtp(false);
    } else if (val === 2) {
      setIsLogin(false);
      setIsOtp(true);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      GenerateOtp();
    }
  };


  const handleKeyPressotp = (event) => {
    if (event.key === "Enter") {
      SubmitOTP();
    }
  };


  const GenerateOtp = () => {
    if (Phone.length > 0) {
      setIsLoading(true);
      axios
        .get(siteUrl + `/Login/CheckUserStatus?Mobile=${Phone}`)
        .then((response) => {
          // console.log(response.data);
          if (response.data === 1) {
            toast.success("OTP sent successfully!", {
              position: "top-center",
            });
            handleToggleControl(2);
          } else {
            toast.error("User not valid.", {
              position: "top-center",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error("An error occurred while sending OTP.", {
            position: "top-center",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      alert("Please Enter Phone number");
    }
  };



  const SubmitOTP = () => {
    if (OTP.length > 0) {
      setIsLoading(true);
      axios
        .get(siteUrl + `/Login/OtpValidation/?Mobile=${Phone}&Otp=${OTP}`)
        .then((response) => {
          console.log(response.data);
          if (response.data ) {
             PushNotifications.addListener('registration', token => {
              console.info('Registration token: ', token.value);
            
              if(token.value){
               
                let devicetocken=  {
                  Id: 0,
                  TockenId:token.value,
                  Userid: response.data.UserData[0].UserId
                }
              axios
              .post(siteUrl + '/Messages/SaveDeviceTockens?',devicetocken)
              .then((response) => {
                console.log(response.data);
            
                return(response.data)})
                .catch((error) => {
   
                  console.log(error);})
            }
            });
            
            
            localStorage.setItem(
              "ApiToken_AyuRx",
              JSON.stringify(response.data.Result)
            );
            localStorage.setItem(
              "UserType_AyuRx",
              JSON.stringify(response.data.UserData[0].UserTypeId)
            );
            localStorage.setItem(
              "UserDAta_AyuRx",
              JSON.stringify(response.data.UserData[0])
            );
            if (response.data.UserData[0].UserTypeId === 4) {
              navigate("/prescription");
            } else if (response.data.UserData[0].UserTypeId === 3) {
              navigate("/Admin");
            } else if (response.data.UserData[0].UserTypeId === 5) {
              navigate("/dashboard");
            } else {
              alert("User not found");
            }
          } else {
            alert("Invalid Details");
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      alert("Please enter OTP");
    }
  };
  useEffect(() => {
    const UserType = JSON.parse(localStorage.getItem("UserType_AyuRx"));
    if (UserType) {
      if (UserType === 4) {
        navigate("/prescription");
      } else if (UserType === 3) {
        navigate("/Admin");
      } else if (UserType === 5) {
        navigate("/dashboard");
      }
    }
  }, []);
  return (
    <div className="login-page">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6">
            <div className="login-wrapper">
              <span>
                <img src={Logo} alt=" Logo" />
              </span>
              <h3>Welcome</h3>
              {IsLogin && (
                <>
                  <h6>Please enter your Phone number</h6>
                  <div className="form-group">
                    <i className="fas fa-lock"></i>
                    <input
                      type="number"
                      placeholder="Phone"
                      value={Phone}
                      onChange={(e) => setPhone(e.target.value)}
                      className="no-spinners"
                      onKeyPress={handleKeyPress}
                    />
                  </div>
                  <button onClick={GenerateOtp} disabled={isLoading}>
                    {isLoading ? "Loading..." : "Continue"}
                  </button>

                  <div style={{ marginTop: "20px", marginLeft: "160px" }}>
                    {" "}
                    <Link to="/patientlogin">
                      <span>Login As Patient</span>
                    </Link>
                  </div>
                </>
              )}
              {IsOtp && (
                <>
                  <h6>Please enter OTP</h6>
                  <div className="form-group">
                    <i className="fas fa-lock"></i>
                    <input
                      type="number"
                      placeholder="Enter OTP"
                      value={OTP}
                      className="no-spinners"
                      onChange={(e) => setOTP(e.target.value)}
                      onKeyPress={handleKeyPressotp}
                    />
                  </div>
             
                  <button onClick={SubmitOTP} disabled={isLoading}>
                    {isLoading ? "Loading..." : "Submit"}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
