import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { addattribute, addattributeError } from "../../../redux/Admin/action";
import { toast, Slide } from 'react-toastify'
import { Button, Input, Label } from "reactstrap";
import { ErrorToast, SuccessToast } from "../../Common/toastify";

function AddAttributes() {
  const [Name, setName] = useState("");
  const [Range, setRange] = useState("");
  const [Unit, setUnit] = useState("");
  const dispatch = useDispatch();

  const saveattribute =useSelector((state)=> state.Admin.attribute)
  // console.log("saveattribute",saveattribute);
  

  
  useEffect(() => {
    if (saveattribute) {
      
      if(saveattribute[0]?.Error === 0 && saveattribute[0]?.Id>0 ){
        toast.success(
            <SuccessToast message={'Attribute saved succesfully'} />,
            { transition: Slide, hideProgressBar: true, autoClose: 2000 }
        )
        Clear();
        
      // }else {
      //   toast.error(
      //       <ErrorToast msg={'Error'} />,
      //       { transition: Slide, hideProgressBar: true, autoClose: 2000 }
      //   )
    }

    }  
}, [saveattribute])

  const Clear = () => {
    
    setName("");
    setRange("");
    setUnit("");
    dispatch(addattributeError({}))
  };
  const handlesubmit = () => {
   
    if(Name && Range && Unit){
      dispatch(
        addattribute({
          Id: 0,
          Name: Name,
          Normal: Range,
          Unit: Unit,
          GroupingId: 0,
          Status: 0,
        })
      );
      
     }else{
      alert("Fill All Mandatory")
     }
  };
  return (
    <div>
    <div className="AddUser">
      <div className="mb-2">
      <Label>Attribute Name:<span className="field-required">*</span></Label>
      <Input
            type="text"
            value={Name}
            onChange={(e) => setName(e.target.value)}
          />
      </div>
      <div className="mb-2">
      <Label>Normal Range:<span className="field-required">*</span></Label>
      <Input
            type="text"
            value={Range}
            onChange={(e) => setRange(e.target.value)}
          />
      </div>
      <div className="mb-2">
      <Label>Unit:<span className="field-required">*</span></Label>
      <Input
            type="text"
            value={Unit}
            onChange={(e) => setUnit(e.target.value)}
          />
      </div>
     
      <div className="row  d-flex mt-4">
        <div className="col-4">
          <Button
            color="danger"
            className="ClearButton"
            onClick={Clear}
          >
            <i
              class="fa-solid fa-arrows-rotate"
              style={{ marginRight: "8px" }}
            ></i>
            Clear
          </Button>
        </div>
        <div className="col-8">
          <Button color="success"  onClick={handlesubmit}>
            <i
              className="fa-solid fa-check"
              style={{ marginRight: "8px" }}
            ></i>
            Save
          </Button>
        </div>
      </div>
    </div>
  </div>
  
  );
}

export default AddAttributes;
