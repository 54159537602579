import { takeEvery, put, call, all } from "redux-saga/effects";
import {actionTypes,prescriptionaddSucces,prescriptionaddError,
  drugaddSucces,drugaddError,InstructiondrugSucces,InstructiondrugError,
  attributeSucces,attributeError,pathyaapathyaSucces,pathyaapathyaError,
  searchpatientSucces,searchpatientError,agnivaluesSucces,agnivaluesError,
  koshtavaluesSucces,koshtavaluesError,jihwavalueSucces,jihwavalueError,
  naadivalueSucces,naadivalueError,malavalueSucces,malavalueError,
  mutravalueSucces,mutravalueError,nidravalueSucces,nidravalueError,
  prakutivalueSucces,prakutivalueError,drugtemplateSucces,drugtemplateError,
  saveimageSucces,saveimageError,addpathiyaapathayaSucces,addpathiyaapathayaError,
  setclinicSucces,setclinicError,scanqrSucces,scanqrError,
  printSucces,printError,chattopatientSucces,chattopatientError,
  doctornotiSucces,doctornotiError,sentlinkSucces,sentlinkError,
  getlakshanaSucces,getlakshanaError,getcauseSucces,getcauseError,
  helpSucces,helpError,doctornotiapproveSucces,doctornotiapproveError,
  notificationstatusSucces,notificationstatusError} from "./action";
import doctorservice from '../../service/doctor/doctorservice'
import {startLoader,stopLoader} from '../Common/Loader/action'




function* prescriptionadd({ payload,localstorageData}) {
  console.log("saga",localstorageData);
  try {
    yield put(startLoader());
    const data = yield call(doctorservice.prescriptionadd, payload,localstorageData);
    yield put(prescriptionaddSucces(data));
    
  } catch (error) {
    yield put(prescriptionaddError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}

function* drugadd({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(doctorservice.drugadd, payload);
    yield put(drugaddSucces(data));
  } catch (error) {
    yield put(drugaddError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}
function* saveimage({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(doctorservice.saveimage, payload);
    yield put(saveimageSucces(data));
  } catch (error) {
    yield put(saveimageError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}
function* Instructiondrug({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(doctorservice.Instructiondrug, payload);
    yield put(InstructiondrugSucces(data));
  } catch (error) {
    yield put(InstructiondrugError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}
function* attribute({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(doctorservice.attribute, payload);
    yield put(attributeSucces(data));
  } catch (error) {
    yield put(attributeError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}
function* pathyaapathya({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(doctorservice.pathyaapathya, payload);
    yield put(pathyaapathyaSucces(data));
  } catch (error) {
    yield put(pathyaapathyaError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}
function* searchpatient({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(doctorservice.searchpatient, payload);
    yield put(searchpatientSucces(data));
  } catch (error) {
    yield put(searchpatientError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}
function* agnivalues({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(doctorservice.agnivalues, payload);
    yield put(agnivaluesSucces(data));
  } catch (error) {
    yield put(agnivaluesError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}
function* koshtavalues({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(doctorservice.koshtavalues, payload);
    yield put(koshtavaluesSucces(data));
  } catch (error) {
    yield put(koshtavaluesError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}
function* jihwavalue({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(doctorservice.jihwavalue, payload);
    yield put(jihwavalueSucces(data));
  } catch (error) {
    yield put(jihwavalueError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}
function* naadivalue({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(doctorservice.naadivalue, payload);
    yield put(naadivalueSucces(data));
  } catch (error) {
    yield put(naadivalueError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}
function* malavalue({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(doctorservice.malavalue, payload);
    yield put(malavalueSucces(data));
  } catch (error) {
    yield put(malavalueError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}
function* mutravalue({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(doctorservice.mutravalue, payload);
    yield put(mutravalueSucces(data));
  } catch (error) {
    yield put(mutravalueError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}
function* nidravalue({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(doctorservice.nidravalue, payload);
    yield put(nidravalueSucces(data));
  } catch (error) {
    yield put(nidravalueError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}
function* prakutivalue({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(doctorservice.prakutivalue, payload);
    yield put(prakutivalueSucces(data));
  } catch (error) {
    yield put(prakutivalueError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}
function* drugtemplate({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(doctorservice.drugtemplate, payload);
    yield put(drugtemplateSucces(data));
  } catch (error) {
    yield put(drugtemplateError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}
function* addpathiyaapathaya({ payload }) {
  try {
    // yield put(startLoader());
    const data = yield call(doctorservice.addpathiyaapathaya, payload);
    yield put(addpathiyaapathayaSucces(data));
  } catch (error) {
    yield put(addpathiyaapathayaError(error));
    console.log(error);
  } finally {
    // yield put(stopLoader());
  }
}
function* setclinic({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(doctorservice.setclinic, payload);
    yield put(setclinicSucces(data));
  } catch (error) {
    yield put(setclinicError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}
function* scanqr({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(doctorservice.scanqr, payload);
    yield put(scanqrSucces(data));
  } catch (error) {
    yield put(scanqrError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}

function* print({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(doctorservice.print, payload);
    yield put(printSucces(data));
  } catch (error) {
    yield put(printError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}
function* chattopatient({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(doctorservice.chattopatient, payload);
    yield put(chattopatientSucces(data));
  } catch (error) {
    yield put(chattopatientError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}
function* doctornoti({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(doctorservice.doctornoti, payload);
    yield put(doctornotiSucces(data));
  } catch (error) {
    yield put(doctornotiError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}
function* doctornotiapprove({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(doctorservice.doctornotiapprove, payload);
    yield put(doctornotiapproveSucces(data));
  } catch (error) {
    yield put(doctornotiapproveError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}
function* notificationstatus({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(doctorservice.notificationstatus, payload);
    yield put(notificationstatusSucces(data));
  } catch (error) {
    yield put(notificationstatusError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}
function* sentlink({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(doctorservice.sentlink, payload);
    yield put(sentlinkSucces(data));
  } catch (error) {
    yield put(sentlinkError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}
function* getlakshana({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(doctorservice.getlakshana, payload);
    yield put(getlakshanaSucces(data));
  } catch (error) {
    yield put(getlakshanaError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}
function* getcause({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(doctorservice.getcause, payload);
    yield put(getcauseSucces(data));
  } catch (error) {
    yield put(getcauseError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}
function* help({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(doctorservice.help, payload);
    yield put(helpSucces(data));
  } catch (error) {
    yield put(helpError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actionTypes.PRESCRIPTION_ADD, prescriptionadd)]);
  yield all([takeEvery(actionTypes.ADD_DRUG, drugadd)]);
  yield all([takeEvery(actionTypes.DRUG_INSTRUCTION, Instructiondrug)]);
  yield all([takeEvery(actionTypes.ATTRIBUTE, attribute)]);
  yield all([takeEvery(actionTypes.PATHYA_APATHYA, pathyaapathya)]);
  yield all([takeEvery(actionTypes.SEARCH_PATIENT, searchpatient)]);
  yield all([takeEvery(actionTypes.AGNI_VALUE, agnivalues)]);
  yield all([takeEvery(actionTypes.KOSHTA_VALUE, koshtavalues)]);
  yield all([takeEvery(actionTypes.JIHWA_VALUE, jihwavalue)]);
  yield all([takeEvery(actionTypes.NAADI_VALUE, naadivalue)]);
  yield all([takeEvery(actionTypes.MALA_VALUE, malavalue)]);
  yield all([takeEvery(actionTypes.MUTRA_VALUE, mutravalue)]);
  yield all([takeEvery(actionTypes.NIDRA_VALUE, nidravalue)]);
  yield all([takeEvery(actionTypes.PRAKUTI_VALUE, prakutivalue)]);
  yield all([takeEvery(actionTypes.DRUG_TEMPLATE, drugtemplate)]);
  yield all([takeEvery(actionTypes.ADD_PATHIYA, addpathiyaapathaya)]);
  yield all([takeEvery(actionTypes.SAVE_IMAGE, saveimage)]);
  yield all([takeEvery(actionTypes.SET_CLINIC, setclinic)]);
  yield all([takeEvery(actionTypes.SCAN_QR, scanqr)]);
  yield all([takeEvery(actionTypes.PRINT, print)]);
  yield all([takeEvery(actionTypes.CHAT_TO_PATIENT, chattopatient)]);
  yield all([takeEvery(actionTypes.DOCTOR_NOTIFICATION, doctornoti)]);
  yield all([takeEvery(actionTypes.SENT_LINK, sentlink)]);
  yield all([takeEvery(actionTypes.GET_LAKSHANA, getlakshana)]);
  yield all([takeEvery(actionTypes.GET_CAUSE, getcause)]);
  yield all([takeEvery(actionTypes.HELP, help)]);
  yield all([takeEvery(actionTypes.DOCTOR_NOTIFICATION_APPROVE, doctornotiapprove)]);
  yield all([takeEvery(actionTypes.NOTIFICATION_STATUS, notificationstatus)]);
}