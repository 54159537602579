import React, { useState } from "react";
import Logo from "../../Images/Img/Logo.png";
import {
  AiOutlineHome,
  AiOutlineSearch,
  AiOutlineScan,
  AiOutlineSetting,
  AiOutlineUnorderedList,
  AiOutlineNotification,
} from "react-icons/ai";
import { GiMedicines } from "react-icons/gi";
import { VscFeedback } from "react-icons/vsc";
import { BiLogOut, BiClinic, BiHelpCircle } from "react-icons/bi";
import { TbReportSearch } from "react-icons/tb";
import "../../CSSstyle/AdminPageCss/AdminPage.css";
import { useNavigate } from "react-router-dom";
import SearchModal from "../Doctor/Modals/SearchModal";
import DrugmasterModal from "../Doctor/Modals/DrugmasterModal";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import FeedbackForm from "../Doctor/Modals/FeedbackForm";
import { Input } from "reactstrap";
import { setclinic } from "../../redux/Doctor/action";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { BsFillChatDotsFill } from "react-icons/bs";
import NotificationModal from "../Doctor/Modals/NotificationModal";
import HelpModal from "../Doctor/Modals/HelpModal";
import { baseUrl } from "../../configs/config";
import NotificationApprove from "../Doctor/Modals/NotificationApprove";
import { App } from "@capacitor/app";

function DoctorNav({
  setserachphone,
  serachphone,
  setpatientsdetails,
  patientsdetails,
  clinicname,
  setclinicname,
  addmodal,setAddmodal
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [modalType, setModalType] = useState("");
  const [reportmenu, setreportmenu] = useState(false);
  const [settingmenu, setsettingmenu] = useState(false);

  const cliniclist = useSelector((state) => state.Doctor.clinicset);
  const doctordetails = JSON.parse(localStorage.getItem("UserDAta_AyuRx"));
  
  
console.log("modalType",modalType);
  const [isOpen, setIsOpen] = React.useState(false);

  useEffect(() => {
    dispatch(
      setclinic({
        Doctorid: doctordetails.Id,
      })
    );
  }, []);
 
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  // console.log("clinicname",clinicname);
  const handlelogout = () => {
    const confirmeassage = "do you want to logout?";
    if (window.confirm(confirmeassage)) {
      localStorage.removeItem("UserType_AyuRx");
      localStorage.removeItem("UserDAta_AyuRx");
      navigate("/");
    }
    setIsOpen((prevState) => !prevState);
  };

  const rendermodal = () => {
    switch (modalType) {
      case "search":
        return (
          <SearchModal
            addmodal={addmodal}
            setAddmodal={setAddmodal}
            serachphone={serachphone}
            setserachphone={setserachphone}
            setSidebarVisible={setSidebarVisible}
            sidebarVisible={sidebarVisible}
            setpatientsdetails={setpatientsdetails}
            patientsdetails={patientsdetails}
            clinicname={clinicname}
            setModalType={setModalType}
          />
        );
      case "masterdrug":
        return (
          <DrugmasterModal
            addmodal={addmodal}
            setAddmodal={setAddmodal}
            setSidebarVisible={setSidebarVisible}
            sidebarVisible={sidebarVisible}
            setModalType={setModalType}
          />
        );
      case "feedback":
        return <FeedbackForm addmodal={addmodal} setAddmodal={setAddmodal}   setModalType={setModalType}/>;
      case "notification":
        return <NotificationModal addmodal={addmodal} setAddmodal={setAddmodal}  setModalType={setModalType} />;
      case "notificationapprove":
        return <NotificationApprove addmodal={addmodal} setAddmodal={setAddmodal}  setModalType={setModalType} />;
      case "help":
        return <HelpModal addmodal={addmodal} setAddmodal={setAddmodal}  setModalType={setModalType} />;
      default:
        break;
    }
  };
  return (
    <div className="header-wrapper">
      <div className="container-fluid">
        <div className="row">
          <div className="d-flex col-12 col-md-6 offset-md-3">
            <button className="notification-bell" onClick={toggleDrawer}>
              
              <i
                id="menubtn"
                className={`fas ${sidebarVisible ? "fa-bars" : "fa-bars"}` }style={{fontSize:'smaller'}}
              ></i>
            </button>
            <Drawer
              open={isOpen}
              onClose={toggleDrawer}
              style={{overflowX: "scroll"}}
              lockBackgroundScroll={false}
              direction="left"
              className="bla bla bla"
            >
              <div>
                <div
                  className="d-flex"
                  style={{
                    padding: "10px",
                    borderBottom: "1px  groove",
                    backgroundColor: "#46cd46",
                  }}
                  onClick={() => {
                    navigate("/prescription");
                    setIsOpen((prevState) => !prevState);
                  }}
                >
                  <AiOutlineHome size={24} />
                  <div style={{ paddingLeft: "40px" }}>Dashboard</div>
                </div>
                <div
                  className="d-flex"
                  style={{ padding: "10px", borderBottom: "1px  groove" }}
                  onClick={() => {
                    if (clinicname) {
                      navigate("/patientlist", {
                        state: { clinicname: clinicname },
                      });
                      setIsOpen((prevState) => !prevState);
                    } else {
                      alert("Select Clinic");
                      setIsOpen((prevState) => !prevState);
                    }
                  }}
                >
                  <AiOutlineUnorderedList size={24} />
                  <div style={{ paddingLeft: "40px" }}>Patient List</div>
                </div>
                <div
                  className="d-flex"
                  style={{ padding: "10px", borderBottom: "1px  groove" }}
                  onClick={() => {
                    if (clinicname) {
                      setModalType("search");
                      setAddmodal(true);
                      setIsOpen((prevState) => !prevState);
                    } else {
                      alert("Select Clinic");
                    }
                  }}
                >
                  <AiOutlineSearch size={24} />
                  <div style={{ paddingLeft: "40px" }}>Search</div>
                </div>

                <div
                  className="d-flex"
                  style={{ padding: "10px", borderBottom: "1px  groove" }}
                  onClick={() => {
                    setModalType("masterdrug");
                    setAddmodal(true);
                    setIsOpen((prevState) => !prevState);
                  }}
                >
                  <GiMedicines size={24} />
                  <div style={{ paddingLeft: "40px" }}>Add Drug</div>
                </div>
                <div
                  className="d-flex"
                  style={{ padding: "10px", borderBottom: "1px  groove" }}
                  // onClick={() => {
                  //   navigate("/Chattopatient")
                  //   setIsOpen((prevState) => !prevState);
                  // }}
                  onClick={()=>{window.open(`${baseUrl}/Chattopatient/${doctordetails.Id}/${doctordetails.UserId}`)}}
                >
                  <BsFillChatDotsFill size={24} />
                  <div style={{ paddingLeft: "40px" }}>Chat</div>
                </div>

                <div
                  className="d-flex"
                  style={{ padding: "10px", borderBottom: "1px  groove" }}
                  onClick={() => {
                    navigate("/qrscanner");
                    setIsOpen((prevState) => !prevState);
                  }}
                >
                  <AiOutlineScan size={24} />
                  <div style={{ paddingLeft: "40px" }}>Scan</div>
                </div>
                <div
                  className="d-flex"
                  style={{ padding: "10px", borderBottom: "1px  groove" }}
                  onClick={() => {
                    navigate("/addclinic");
                    setIsOpen((prevState) => !prevState);
                  }}
                >
                  <BiClinic size={24} />
                  <div style={{ paddingLeft: "40px" }}>Add Clinic</div>
                </div>
                <div
                  className="d-flex"
                  style={{ padding: "10px", borderBottom: "1px  groove" }}
                >
                  <TbReportSearch size={24} />

                  <div
                    style={{ paddingLeft: "40px" }}
                    onClick={() => {
                      setreportmenu(!reportmenu);
                    }}
                  >
                    Report
                  </div>
                </div>
                {reportmenu ? (
                  <div
                    className="d-flex"
                    style={{
                      padding: "10px",
                      marginLeft: "20px",
                      borderBottom: "1px  groove",
                    }}
                    // onClick={() => {
                    //   navigate("/dailypatientreport");
                    //   setIsOpen((prevState) => !prevState);
                    // }}
                    onClick={()=>{window.open(baseUrl+`/dailypatientreport/${ doctordetails.Id}`)}}
                  >
                    <TbReportSearch size={24} />

                    <div
                      style={{ paddingLeft: "30px" }}
                      onClick={() => {
                        setreportmenu(true);
                      }}
                    >
                      Daily Patient Report
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div
                  className="d-flex"
                  style={{ padding: "10px", borderBottom: "1px  groove" }}
                  onClick={() => {
                    setModalType("notification");
                    setAddmodal(true);
                    setIsOpen((prevState) => !prevState);
                  }}
                >
                  <AiOutlineNotification size={24} />
                  <div style={{ paddingLeft: "40px" }}>Notification</div>
                </div>
                <div
                  className="d-flex"
                  style={{ padding: "10px", borderBottom: "1px  groove" }}
                  onClick={() => {
                    setModalType("notificationapprove");
                    setAddmodal(true);
                    setIsOpen((prevState) => !prevState);
                  }}
                >
                  <AiOutlineNotification size={24} />
                  <div style={{ paddingLeft: "40px" }}>Notification Approve</div>
                </div>
                <div
                  className="d-flex"
                  style={{ padding: "10px", borderBottom: "1px  groove" }}
                  onClick={() => {
                    setsettingmenu(!settingmenu);
                  }}
                >
                  <AiOutlineSetting size={24} />
                  <div style={{ paddingLeft: "40px" }}>Settings</div>
                </div>
                <div>{settingmenu ? (
                  <div
                    className="d-flex"
                    style={{
                      padding: "10px",
                      marginLeft: "20px",
                      borderBottom: "1px  groove",
                    }}
                    onClick={() => {
                      setModalType("help");
                      setAddmodal(true);
                      setIsOpen((prevState) => !prevState);
                    }}
                  >
                    <BiHelpCircle size={24} />

                    <div
                      style={{ paddingLeft: "30px" }}
                      onClick={() => {
                        setreportmenu(true);
                      }}
                    >
                      Help
                    </div>
                  </div>
                ) : (
                  ""
                )}</div>
                <div
                  className="d-flex"
                  style={{ padding: "10px", borderBottom: "1px  groove" }}
                  onClick={() => {
                    setModalType("feedback");
                    setAddmodal(true);
                    setIsOpen((prevState) => !prevState);
                  }}
                >
                  <VscFeedback size={24} />
                  <div style={{ paddingLeft: "40px" }}>Write your Feedback</div>
                </div>
                <div
                  className="d-flex"
                  style={{ padding: "10px", borderBottom: "1px  groove" }}
                  onClick={handlelogout}
                >
                  <BiLogOut size={24} />
                  <div style={{ paddingLeft: "40px" }}>Logout</div>
                </div>
              </div>
            </Drawer>

            <img className="cv-reg-taglahlogo" src={Logo} alt="AruRX" />
           
            <div className="container">
  <div className="row">
    <div >
      <div style={{ marginLeft: "50px", marginTop: "8px" }}>
        <Input
          type="select"
          value={clinicname}
          style={{maxHeight:'37px',fontSize:'small'}}
          onChange={(e) => setclinicname(e.target.value)}
        >
          <option>Choose Clinic</option>
          {cliniclist.ClinicResult &&
            cliniclist.ClinicResult.map((item, i) => (
              <option key={i} value={item.Id}>
                {item.Name}
              </option>
            ))}
        </Input>
      </div>
    </div>
  </div>
</div>

          </div>
        </div>
       
      </div>

      {addmodal && rendermodal()}
    </div>
  );
}

export default DoctorNav;
