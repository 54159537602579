
import React from "react";
import { useEffect } from "react";
import { ArrowLeft } from "react-feather";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Card } from "reactstrap";
import { getfeedback } from "../../redux/Admin/action";

function Feedbacklist() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const feedbacklist = useSelector((state) => state.Admin.listfeedback);
  console.log("feedbacklist", feedbacklist);

  useEffect(() => {
    dispatch(getfeedback());
  }, []);

  
  return (
    <div>
      <div className="auth-wrapper">
        <div className=" pt-3 pl- underline ">
          <h3 className="text-capitalize d-flex" style={{ marginLeft: "18px" }}>
            <ArrowLeft
              size={22}
              onClick={() => {
                navigate("/Admin");
              }}
            />
            <div className="mr-2" style={{ marginLeft: "18px" }}>
              Feedback List
            </div>
          </h3>
        </div>

        {feedbacklist &&
          feedbacklist.map((item, i) => {
            return (
              <Card className=" mb-1 " key={i}>
                <div className="container-fluid m-3 ">
                  <div className="d-flex justify-content-start">
                    <div>User name:</div>
                    <div>{item.Name}</div>
                  </div>
                  <div className="d-flex justify-content-start mt-2">
                    <div>Feedback:</div>
                    <div>{item.FeedBack}</div>
                  </div>
                </div>
              </Card>
            );
          })}
      </div>
    </div>
  );
}

export default Feedbacklist;
