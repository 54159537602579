import React, { useState } from 'react'
import QrReader from 'react-qr-reader';
import { useDispatch } from 'react-redux';
import { scanqr } from '../../redux/Doctor/action';
import { useNavigate } from 'react-router-dom';



function QRreader() {

    const [result, setResult] = useState('No QR code detected yet');
    const dispatch = useDispatch()
    const navigate = useNavigate()

  

    const handleScan = (data) => {
      if (data) {
        console.log("data",data);
        dispatch(
          scanqr({
            PrescriptionNo:data
          })
        )
        setResult(data);
        navigate('/prescription')
      }
      
    };
  
    const handleError = (error) => {
      console.error(error);
    };
  return (
    
           <div>
      <QrReader
      facingmode='environment'
        delay={300}
        onScan={handleScan}
        onError={handleError}
        style={{ width: '100%' }}
      />
      {/* <p>{result}</p> */}
    </div>
  )
}

export default QRreader