
import { AvForm } from "availity-reactstrap-validation-safe";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { doctornoti } from "../../../redux/Doctor/action";

function NotificationModal({ setAddmodal, addmodal,setModalType }) {

    const[noti,setnoti]=useState()
    const dispatch = useDispatch()
    const doctordetails = JSON.parse(localStorage.getItem("UserDAta_AyuRx"));

    const handlesumbit = () => {
        if(noti)
        {
          dispatch(doctornoti({
            Message:noti,
            DoctorId: doctordetails.Id,
            
          }))
          setnoti("")
          setAddmodal(!addmodal)
          
        }else{
          alert("Enter the Notification")
        }
        setModalType("")
      };
  return (
    <Modal isOpen={addmodal} toggle={() => setAddmodal(!addmodal)}>
    <AvForm>
      <ModalHeader toggle={() => {setAddmodal(!addmodal)
      setModalType("")}}>
        {"Notification To Patients"}
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <div>
            <Label className="label text-uppercase">Enter Notification Notes Here</Label>
            <Input
              type="textarea"
              id="company_name"
              value={noti}
              onChange={(e) => setnoti(e.target.value)}
            />
          </div>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="success" onClick={handlesumbit}>Submit</Button>
      </ModalFooter>
    </AvForm>
  </Modal>
  )
}

export default NotificationModal