export const actionTypes = {
    PATIENT_HISTORY: "PATIENT_HISTORY",
    PATIENT_HISTORY_SUCCESS: "PATIENT_HISTORY_SUCCESS",
    PATIENT_HISTORY_ERROR: "PATIENT_HISTORY_ERROR",
    
    GET_DOCTOR_ID_CHAT: "GET_DOCTOR_ID_CHAT",
    GET_DOCTOR_ID_CHAT_SUCCESS: "GET_DOCTOR_ID_CHAT_SUCCESS",
    GET_DOCTOR_ID_CHAT_ERROR: "GET_DOCTOR_ID_CHAT_ERROR",
    
  };

  export const patienthistory = (payload) => {
    return {
      type: actionTypes.PATIENT_HISTORY,
      payload,
    };
  };
  export const patienthistorySucces = (payload) => {
    return {
      type: actionTypes.PATIENT_HISTORY_SUCCESS,  
      payload,
    };
  };
  export const patienthistoryError = (payload) => {
    return {
      type: actionTypes.PATIENT_HISTORY_ERROR,
      payload,
    };
  };

  
  export const doctorchatid = (payload) => {
    return {
      type: actionTypes.GET_DOCTOR_ID_CHAT,
      payload,
    };
  };
  export const doctorchatidSucces = (payload) => {
    return {
      type: actionTypes.GET_DOCTOR_ID_CHAT_SUCCESS,  
      payload,
    };
  };
  export const doctorchatidError = (payload) => {
    return {
      type: actionTypes.GET_DOCTOR_ID_CHAT_ERROR,
      payload,
    };
  };

