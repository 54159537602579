import ApiClient from '../ApiClient'
import { Slide, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ErrorToast, SuccessToast } from '../../Pages/Common/toastify';
class doctorservice {
    constructor(callback) {
        this.callback = callback
    }

   
    async prescriptionadd(payload,localstorageData) {
        console.log("service",localstorageData);
        const response = await ApiClient.post(
            '/Doctor/SetPrescription',
            payload,
        ).then((response) => {
            console.log("response",response.data);
            if (response.data[0]?.Error === 0 && response.data[0]?.Id > 0) {
                toast.success(
                  <SuccessToast message={"Prescription saved succesfully"} />,
                  { transition: Slide, hideProgressBar: true, autoClose: 2000 }
                );
                console.log("payload",payload);
                localStorage.setItem(
                    "myData",
                    JSON.stringify( localstorageData )
                  );
              }
              else {
                toast.error(<ErrorToast msg={"Error"} />, {
                  transition: Slide,
                  hideProgressBar: true,
                  autoClose: 2000,
                });
              }
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }

    async saveimage(payload) {
        const response = await ApiClient.post(
            'ImageUploader/SaveImage',
            payload,
        ).then((response) => {
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
    
    async drugadd(payload) {
        const response = await ApiClient.get(
            `Master/GetDrugs?Did=${payload.Did}`,
            payload,
        ).then((response) => {
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
    async Instructiondrug(payload) {
        const response = await ApiClient.get(
            'Master/GetDrugInstruction',
            payload,
        ).then((response) => {
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
    async attribute(payload) {
        const response = await ApiClient.get(
            'Master/GetAttributes',
            payload,
        ).then((response) => {
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
    async pathyaapathya(payload) {
        const response = await ApiClient.get(
            'Master/GetPathya',
            payload,
        ).then((response) => {
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
    async searchpatient(payload) {
        const response = await ApiClient.get(
            `Patient/GetPatientSearch?Did=${payload.Did}&Mobile=${payload.Mobile}&clinicid=${payload.clinicid}`,
            payload,
        ).then((response) => {
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
    async agnivalues(payload) {
        const response = await ApiClient.get(
            'Master/GetAgniValues',
            payload,
        ).then((response) => {
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
    async koshtavalues(payload) {
        const response = await ApiClient.get(
            'Master/GetKoshtaValues',
            payload,
        ).then((response) => {
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
    async jihwavalue(payload) {
        const response = await ApiClient.get(
            'Master/GetjihwaValues',
            payload,
        ).then((response) => {
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
    async naadivalue(payload) {
        const response = await ApiClient.get(
            'Master/GetjNaadiValues',
            payload,
        ).then((response) => {
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
    async malavalue(payload) {
        const response = await ApiClient.get(
            'Master/GetjMalaValues',
            payload,
        ).then((response) => {
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
    async mutravalue(payload) {
        const response = await ApiClient.get(
            'Master/GetjMutaValues',
            payload,
        ).then((response) => {
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
    async nidravalue(payload) {
        const response = await ApiClient.get(
            'Master/GetjNidraValues',
            payload,
        ).then((response) => {
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
    async prakutivalue(payload) {
        const response = await ApiClient.get(
            'Master/GetjPrakutiValues',
            payload,
        ).then((response) => {
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
    async drugtemplate(payload) {
        const response = await ApiClient.get(
            `Master/GetDrugTemplates?Did=${payload.Did}`,
            payload,
        ).then((response) => {
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
    async addpathiyaapathaya(payload) {
        const response = await ApiClient.post(
            'Master/SetPatyaApathya',
            payload,
        ).then((response) => {
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }

    async setclinic(payload) {
        const response = await ApiClient.get(
            `Doctor/GetClinics?Doctorid=${payload.Doctorid}`,
            payload,
        ).then((response) => {
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }

    async scanqr(payload) {
        const response = await ApiClient.get(
            `Patient/GetPatientDetailsWithPrescriptionNo?PrescriptionNo=${payload.PrescriptionNo}`,
            payload,
        ).then((response) => {
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
    async print(payload) {
        const response = await ApiClient.get(
            `Patient/GetPatientHistoryWithId?PatientId=${payload.PatientId}`,
            payload,
        ).then((response) => {
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
    async chattopatient(payload) {
        const response = await ApiClient.get(
            `Doctor/GetThePatientsForChat?Doctorid=${payload.Doctorid}`,
            payload,
        ).then((response) => {
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
    async doctornoti(payload) {
        const response = await ApiClient.post(
            `Messages/SendPushNotificationDoctorThrougWhatsapp?DoctorId=${payload.DoctorId}&Message=${payload.Message}`,
            payload,
        ).then((response) => {
            if (response.data) {
                toast.success(
                  <SuccessToast message={"Notification Sent Succesfully"} />,
                  { transition: Slide, hideProgressBar: true, autoClose: 2000 }
                );
                
              }
              else {
                toast.error(<ErrorToast msg={"Error"} />, {
                  transition: Slide,
                  hideProgressBar: true,
                  autoClose: 2000,
                });
              }
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
    async sentlink(payload) {
        const response = await ApiClient.get(
            `Messages/SendLinkThrougWhatsapp?Mobile=${payload.Mobile}&Sub=${payload.Sub}`,
            payload,
        ).then((response) => {
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
    async getlakshana(payload) {
        const response = await ApiClient.get(
            'Master/GetComplaintMaster',
            payload,
        ).then((response) => {
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
    async getcause(payload) {
        const response = await ApiClient.get(
            'Master/GetCauseMaster',
            payload,
        ).then((response) => {
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
    async doctornotiapprove(payload) {
        const response = await ApiClient.get(
            `Doctor/GetAdminNotification?did=${payload.did}`,
            payload,
        ).then((response) => {
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
    async notificationstatus(payload) {
        const response = await ApiClient.post(
            `Doctor/ChangeNotificationStatus?type=${payload.type}&notiid=${payload.notiid}`,
            payload,
        ).then((response) => {
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
    async help(payload) {
        const response = await ApiClient.post(
            `Messages/SendHelpRequestToAdmin?Message=${payload.Message}&DoctorID=${payload.DoctorID}`,
            payload,
        ).then((response) => {
            if (response.data) {
                toast.success(
                  <SuccessToast message={"Message Sent Succesfully"} />,
                  { transition: Slide, hideProgressBar: true, autoClose: 2000 }
                );
                
              }
              else {
                toast.error(<ErrorToast msg={"Error"} />, {
                  transition: Slide,
                  hideProgressBar: true,
                  autoClose: 2000,
                });
              }
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
}
export default new doctorservice();