

import { AvForm } from "availity-reactstrap-validation-safe";
import React, { useEffect, useState } from "react";
import { AiOutlinePrinter } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { addfeedback } from "../../../redux/Admin/action";

// import { ErrorToast, SuccessToast } from "../../Common/toastify";
  
  function PrintModal({ setAddmodal,setModalType, addmodal }) {

const navigate = useNavigate()

const prescription = useSelector((state) => state.Doctor.addprescription);
console.log("prescription",prescription);
  
  return (
    <Modal isOpen={addmodal} toggle={() => setAddmodal(!addmodal)}>
      <AvForm>
        <ModalHeader toggle={() => {setAddmodal(!addmodal)
        setModalType("")
        }}>
          {"Print Option"}
        </ModalHeader>
        <ModalBody>
          <FormGroup>
          <div
                  className="d-flex"
                  style={{ padding: "10px", borderBottom: "1px  groove" }}
                  onClick={(e) => {
                 
                    if(prescription[0]?.Id){
                      navigate('/PathyaApthya', { state: { data:prescription[0]?.Id } })
                     
                    }else{
                      alert("Please Save  Prescription")
                    }

                     
                  }}
                >
                  <AiOutlinePrinter size={24} />
                  <div style={{ paddingLeft: "40px" }}>Pathya Apathya</div>
                </div>
          <div
                  className="d-flex"
                  style={{ padding: "10px", borderBottom: "1px  groove" }}
                  onClick={(e) => {
                 
                    if(prescription[0]?.Id){
                      navigate('/SummaryPrint', { state: { data:prescription[0]?.Id } })
                      // navigate('/SummaryPrint', { state: { data:1517 } })
                   
                    }else{
                      alert("Please Save  Prescription")
                    }

                     
                  }}
                >
                  <AiOutlinePrinter size={24} />
                  <div style={{ paddingLeft: "40px" }}>Summary</div>
                </div>
          <div
                  className="d-flex"
                  style={{ padding: "10px", borderBottom: "1px  groove" }}
                  onClick={(e) => {
                 
                    if(prescription[0]?.Id){
                      navigate('/Printprescription', { state: { data:prescription[0]?.Id } })
                  
                    }else{
                      alert("Please Save  Prescription")
                    }

                     
                  }}
                >
                  <AiOutlinePrinter size={24} />
                  <div style={{ paddingLeft: "40px" }}>Prescription</div>
                </div>
          </FormGroup>
        </ModalBody>
        
      </AvForm>
    </Modal>
  );
}

export default PrintModal;
