import React,{useState} from 'react'
import { Nav,Tab } from 'react-bootstrap';
import AddUser from './Tabs/AddUser';
import AddUserPng from '../../Images/Img/add-user.png'
import Logo from '../../Images/Img/Logo.png'
import AddAtributes from '../../Images/Img/medical-file.png'
import AddDoctorimage from '../../Images/Img/medical-team.png'
import AddClinicimage from '../../Images/Img/clinic.png'
import '../../CSSstyle/AdminPageCss/AdminPage.css'
import AddAttributes from './Tabs/AddAttributes';
import AddDoctor from './Tabs/AddDoctor'
import { useNavigate } from 'react-router-dom';
import AddClinic from './Tabs/AddClinic';
import { AiOutlineHome, AiOutlineSearch,AiOutlineScan ,AiOutlineSetting, AiOutlineNotification} from "react-icons/ai";
import { GiMedicines } from "react-icons/gi";
import { VscFeedback } from "react-icons/vsc";
import { BiLogOut,BiClinic } from "react-icons/bi";
import { TbReportSearch } from "react-icons/tb";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import NotificationAdmin from './Tabs/NotificationAdmin';
import useHardwareBackButton from '../useHardwareBackButton';
import { App } from '@capacitor/app';
function AdminPage() {

  const handleBackButtonPress = () => {
    if(addmodal){
      setAddmodal(false)
    }else{
      if(activeTab==='A'){
        App.exitApp();
      }else{
        setActiveTab("A")
      }
    }
   };
   useHardwareBackButton(0,handleBackButtonPress)

  const navigate =useNavigate();
  const [activeTab, setActiveTab] = useState('A');
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [addmodal, setAddmodal] = useState(false);
  const [modalType, setModalType] = useState("");

  const handleTabSelect = tab => {
    setActiveTab(tab);
  };

  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handlelogout = () => {
    const confirmeassage = "do you want to logout?";
    if (window.confirm(confirmeassage)) {
      localStorage.removeItem("UserType_AyuRx");
      localStorage.removeItem("UserDAta_AyuRx");
      navigate("/");
    }
  };

  const rendermodal = () => {
    switch (modalType) {
     
      case "notification":
        return <NotificationAdmin addmodal={addmodal} setAddmodal={setAddmodal} />;
      default:
        break;
    }
  };
  
  return (
    <div className="bodybg cv-bodypadding">
    <div className="header-wrapper">
      <div className="container-fluid">
        <div className="row">
        <div className="d-flex">
            <button className="notification-bell" onClick={toggleDrawer}>
              {" "}
              <i
                id="menubtn"
                className={`fas ${sidebarVisible ? "fa-bars" : "fa-bars"}`}
              ></i>
            </button>
            <Drawer
              open={isOpen}
              onClose={toggleDrawer}
              direction="left"
              className="bla bla bla"
            >
              <div>
                <div
                  className="d-flex"
                  style={{
                    padding: "10px",
                    borderBottom: "1px  groove",
                    backgroundColor: "#46cd46",
                  }}
                  onClick={() => {
                    setIsOpen((prevState) => !prevState);
                  }}
                >
                  <AiOutlineHome size={24} />
                  <div style={{ paddingLeft: "40px" }}>Home</div>
                </div>
                <div
                  className="d-flex"
                  style={{ padding: "10px", borderBottom: "1px  groove" }}
                  onClick={() => {
                    setModalType("notification");
                    setAddmodal(true);
                    setIsOpen((prevState) => !prevState);
                  }}
                >
                  <AiOutlineNotification size={24} />
                  <div style={{ paddingLeft: "40px" }}>Notification</div>
                </div>
                
                
             
                <div
                  className="d-flex"
                  style={{ padding: "10px", borderBottom: "1px  groove" }}
                  onClick={() => {
                    navigate("/Feedbacklist");
                    setIsOpen((prevState) => !prevState);
                  }}
                >
                  <TbReportSearch size={24} />
                  <div style={{ paddingLeft: "40px" }}>Feedback Details</div>
                </div>
                        
                <div
                  className="d-flex"
                  style={{ padding: "10px", borderBottom: "1px  groove" }}
                  onClick={handlelogout}
                >
                  <BiLogOut size={24} />
                  <div style={{ paddingLeft: "40px" }}>Logout</div>
                </div>
              </div>
            </Drawer>

            <img className="cv-reg-taglahlogo" src={Logo} alt="AruRX" />
          </div>
         
          
        </div>
      </div>
    </div>
  
    <Tab.Container activeKey={activeTab} onSelect={handleTabSelect}>
      <div className="savedate-sidebar">
        <Nav className="flex-column " fill variant="pills" id="v-pills-tab" aria-orientation="vertical" >
          <Nav.Link eventKey="A" style={{ textAlign: 'left' }}>
            <span style={{ backgroundImage: `url(${AddUserPng})`, backgroundSize: 'cover', backgroundPosition: 'center' }}></span>
            <h6>Add User</h6>
          </Nav.Link>  
          <Nav.Link eventKey="B" style={{ textAlign: 'left' }}>
            <span style={{ backgroundImage: `url(${AddAtributes})`, backgroundSize: 'cover', backgroundPosition: 'center' }}></span>
            <h6>Add Attributes</h6>
          </Nav.Link>       
          <Nav.Link eventKey="c" style={{ textAlign: 'left' }}>
            <span style={{ backgroundImage: `url(${AddDoctorimage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}></span>
            <h6>Add Doctor</h6>
          </Nav.Link>       
              
        </Nav>
      </div>
      <div className="container-fluid">
        <Tab.Content id="v-pills-tabContent">
          <Tab.Pane eventKey="A" style={{ marginTop: '60px' }}>
            <AddUser/>
          </Tab.Pane>
          <Tab.Pane eventKey="B" style={{ marginTop: '60px' }}>
            <AddAttributes/>
          </Tab.Pane>
          <Tab.Pane eventKey="c" style={{ marginTop: '60px' }}>
            <AddDoctor/>
          </Tab.Pane>
          
        </Tab.Content>
      </div>
    </Tab.Container>
    {addmodal && rendermodal()}
    
  </div> 
  )
}

export default AdminPage