

import { AvForm } from "availity-reactstrap-validation-safe";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { addfeedback } from "../../../redux/Admin/action";

// import { ErrorToast, SuccessToast } from "../../Common/toastify";
function FeedbackForm({ setAddmodal, addmodal,setModalType,sidebarVisible }) {
  const dispatch = useDispatch();
  const [feedback, setfeedback] = useState();
  const doctordetails = JSON.parse(localStorage.getItem("UserDAta_AyuRx"));

// console.log("doctordetails",doctordetails);


  const handlesumbit = () => {
    if(feedback)
    {
      dispatch(addfeedback({
        Id: 0,
        Feedback: feedback,
        Sysdate: "2023-10-12T05:52:45.846Z",
        UserId: doctordetails.UserId,
        UserTypeid: doctordetails.UserTypeId
      }))
      setfeedback("")
      setAddmodal(!addmodal)

      
    }else{
      alert("Enter the feedback")
    }
    setModalType("")
  };
  return (
    <Modal isOpen={addmodal} toggle={() => setAddmodal(!addmodal)}>
      <AvForm>
        <ModalHeader toggle={() => {setAddmodal(!addmodal)
        setModalType("")}}>
          {"ADD FEEDBACK"}
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <div>
              <Label className="label text-uppercase">Enter Your Feedback Here</Label>
              <Input
                type="textarea"
                id="company_name"
                value={feedback}
                onChange={(e) => setfeedback(e.target.value)}
              />
            </div>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={handlesumbit}>Submit</Button>
        </ModalFooter>
      </AvForm>
    </Modal>
  );
}

export default FeedbackForm;
