import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../CSSstyle/chatpagecss/chat.css";
import { ReactMic } from "react-mic";
import { v4 as uuid } from "uuid";
import { baseUrl, siteUrl } from "../../configs/config";
import { useLocation } from "react-router-dom";
import Doctorchatnav from "./Doctorchatnav";
import {
  AiFillStop,
  AiOutlineDelete,
  AiOutlineSend,
  AiOutlineStop,
} from "react-icons/ai";
import { Button, Input } from "reactstrap";
import { BsFillMicFill } from "react-icons/bs";
import { useRef } from "react";

function Doctorchatpage() {
  const [messages, setMessages] = useState([]);
  const [singleMessage, setSingleMessage] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [audioData, setAudioData] = useState(null);
  const [datavalue, setdatavalue] = useState();
  const [shouldHide, setShouldHide] = useState(true);
  const messageRef = useRef(null);
  const parms = useLocation();
  const patientdetails = JSON.parse(localStorage.getItem("UserDAta_AyuRx"));
  // console.log("audioData", audioData);
  // console.log("parms.state.data",parms.state.doctid);
  useEffect(() => {
    if (parms.state.data) {
      setdatavalue(parms.state.data);
    }
  }, []);


  useEffect(() => {
    messageRef.current.scrollTop = messageRef.current.scrollHeight;
  }, [messages]);

  const [MessageLength, setMessageLength] = useState(0);

  // Use useRef to keep track of the latest MessageLength
  const latestMessageLength = useRef(MessageLength);
  useEffect(() => {
    latestMessageLength.current = MessageLength;
  }, [MessageLength]);

  useEffect(() => {
    const fetchMessages = async () => {
      if (datavalue) {
        try {
          const response = await axios.get(
            siteUrl +
              `/Messages/GetMessageWithChatMasterid?Chatid=${datavalue?.ChatMasterId}`
          );
          if (response.data.Error) {
            setMessages("");
          } else {
            const newLength = response.data.length;
            if (newLength !== latestMessageLength.current) {
              setMessages(response.data);
              setMessageLength(newLength);
            }
          }
        } catch (error) {
          console.error("Error fetching messages:", error);
        }
      }
    };

    fetchMessages();
    const intervalId = setInterval(fetchMessages, 2000);
    return () => {
      clearInterval(intervalId);
    };
  }, [singleMessage, datavalue]);

  // Empty dependency array means this effect runs only once on component mount
  // Handle audio playback errors
 
  
  const handleSendMessage = () => {
    if (singleMessage.trim() !== "") {
      const newMessage = {
        Id: 0,
        ChatMasterId: datavalue?.ChatMasterId,
        Receiver: datavalue?.Userid,
        Sender: parseInt(parms.state.id),
        Text: singleMessage,
        Date: new Date().toISOString(),
        Typeid: 1,
        VoiceName: "",
      };

      if (messages == "") {
        setMessages(newMessage);
      }
      // else{
      //   setMessages([...messages, newMessage]);
      // }

      // Send the message to the server
      axios
        .post(siteUrl + "/Messages/SendMessage", newMessage)
        .then((response) => {
          if (response.data.Error) {
            setMessages("");
          // } else {
          //   setMessages(response.data);
          }
        });

      axios
        .get(
          siteUrl +
            `/Messages/GetMessageWithChatMasterid?Chatid=${datavalue?.ChatMasterId}`
        )
        .then((response) => {
          if (response.data) {
            setMessages(response.data);
          }
        });

      setSingleMessage(""); // Clear the input field
    }
  };
  const onStartRecording = () => {
    setIsRecording(true);
  };

  const onStopRecording = () => {
    setIsRecording(false);
  };

  const onData = (recordedData) => {
    setAudioData(recordedData);
  };

  const clearAudioData = () => {
    setAudioData(null);
  };
  const sendAudioToServer = () => {
    if (audioData) {
      const blob = new Blob([audioData.blob], { type: "audio/mp3" });
      // console.log(blob);
      const unique_id = uuid();

      const fileName = `${parms.state.id + unique_id}_.mp3`;
      const sanitizedFileName = fileName.replace(/[:/]/g, "_");
      const audioFile = new File([blob], sanitizedFileName, {
        type: "audio/mp3",
      });

      const formData = new FormData();
      formData.append("audioFile", audioFile);
      console.log(formData);
      axios
        .post(siteUrl + "/ImageUploader/UploadAudio", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          // setAudioData(null);

          let voiceMessage = {
            Id: 0,
            ChatMasterId: parms.state.data?.ChatMasterId,
            Receiver: parms.state.data?.Userid, // Set the default sender ID
            Sender: parseInt(parms.state.id),
            Text: "",
            Date: new Date().toISOString(),
            Typeid: 2,
            VoiceName: audioFile.name, // Use the audio file name
          };
          console.log("voiceMessage", voiceMessage);
          // Send the message to the server
          axios
            .post(siteUrl + "/Messages/SendMessage", voiceMessage)
            .then((response) => {
              console.log("response.data1", response.data);
              if (response.data) {
                axios
                  .get(
                    siteUrl +
                      `/Messages/GetMessageWithChatMasterid?Chatid=${parms.state.data?.ChatMasterId}`
                  )
                  .then((response) => {
                    console.log("response.data", response.data);
                    if (response.data) {
                      setMessages(response.data);
                    }
                  });
              }
            });

          setSingleMessage("");
        })
        .catch((error) => {
          console.error("Error uploading audio:", error);
        });
    }
    setAudioData(null);
  };
  const StopAudio = (recordedBlob) => {
    setAudioData(recordedBlob);
  };

  return (
    <div className="chat-container">
      <Doctorchatnav
        datavalue={datavalue}
        userid={parms.state.id}
        doctid={parms.state.doctid}
      />
      <div className="message-container" ref={messageRef}>
        {messages.length &&
          messages.map((message, index) => {
            let dateTime = new Date(message.Date);
            const hours = dateTime.getHours();
            const minutes = dateTime.getMinutes();
            const amOrPm = hours >= 12 ? "PM" : "AM";
            const formattedHours = hours % 12 || 12;
            const formattedMinutes = minutes.toString().padStart(2, "0");
            const formattedTime = `${formattedHours}:${formattedMinutes} ${amOrPm}`;
            return (
              <div className="chat">
                <div
                  key={index}
                  className={` message-content ${
                    message.Sender === parseInt(parms.state.id)
                      ? " incoming"
                      : "outgoing"
                  }`}
                >
                  <br />
                  {message.TypeId === 1 ? (
                    <div className="message">
                      <div style={{ wordBreak: "break-word" }}>
                        <lable
                          style={{
                            color: message.IsPanic === 1 ? "Red" : "black",
                          }}
                        >
                          {message.Text}
                        </lable>
                      </div>
                      <span class="timestamp">{formattedTime}</span>
                    </div>
                  ) : message.TypeId === 2 ? (
                    <div className="message">
                      {/* Display the audio message */}
                      <audio
                        controls
                        style={{ width: "186px", height: "33px" }}
                      >
                        <source
                          src={`https://connect.ayurx.initstore.com/Asset/Audio/ChatAudio/${message.VoiceName}`}
                          type="audio/mp3"
                        />
                      </audio>
                      <span class="timestamp">{formattedTime}</span>
                    </div>
                  ) : null}
                </div>
              </div>
            );
          })}
      </div>
      <div style={{ display: shouldHide ? "none" : "block" }}>
        <ReactMic
          record={isRecording}
          className="sound-wave"
          onStop={StopAudio}
          onData={onData}
        />
      </div>

      <div className="d-flex justify-content-between m-2" style={{minHeight:'110px'}}>
        <div>
          <Input
            type="text"
            placeholder="Type your message here..."
            className="input"
            value={singleMessage}
            style={{
              width: "100%",
              borderRadius: "10px",
              wordWrap: "break-word",
              minHeight:'70px'
            }}
            onChange={(e) => setSingleMessage(e.target.value)}
          />
        </div>
        <div>
          {singleMessage ? (
            <AiOutlineSend
              size={30}
              color="green"
              onClick={handleSendMessage}
            />
          ) : isRecording ? (
            <div className="d-flex ml-1">
              <Button color="green" onClick={onStopRecording}>
                stop
              </Button>
            </div>
          ) : audioData ? (
            <div className="d-flex">
              <Button color="green" onClick={sendAudioToServer}>
                send
              </Button>
              <Button color="red" onClick={clearAudioData}>
                delete
              </Button>
            </div>
          ) : (
            <BsFillMicFill size={30} color="green" onClick={onStartRecording} />
          )}
        </div>
      </div>
    </div>
  );
}

export default Doctorchatpage;
