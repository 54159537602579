module.exports = {

    
        siteUrl: 'https://connect.ayurx.initstore.com/api',
        baseUrl: 'https://ayurx.initstore.com',


        // siteUrl: 'https://localhost:7140/api',
        // baseUrl: 'http://localhost:3002',

    }