import React from 'react' 
import{BrowserRouter,Routes,Route}from 'react-router-dom'
import Login from '../Pages/Login'
import Patientlogin from '../Pages/Patientlogin'
import Dashboard from '../Pages/Common/Dashboard'
import AdminPage from '../Pages/AdminPage/AdminPage'
import Homepage from '../Pages/Patient/Homepage'
import Camerapage from '../Pages/Doctor/Camerapage'
import SummaryPrint from '../Pages/Doctor/SummaryPrint'
import Viewpage from '../Pages/Patient/Viewpage'
import Index from '../Pages/Doctor/Index'
import Patientlist from '../Pages/Doctor/Patientlist'
import Dailypatientrport from '../Pages/Report/Dailypatientrport'
import AddClinic from '../Pages/AdminPage/Tabs/AddClinic'
import QRreader from '../Pages/Doctor/QRreader'
import Chatpage from '../Pages/Patient/Chatpage'
import Printbrower from '../Pages/Doctor/Printbrower'
import Printprescription from '../Pages/Doctor/Printprescription'
import Browerprescription from '../Pages/Doctor/Browerprescription'
import Pathyabrower from '../Pages/Doctor/Pathyabrower'
import PathyaApthya from '../Pages/Doctor/PathyaApthya'
import Chattopatient from '../Pages/Doctor/Chattopatient'
import Doctorchatpage from '../Pages/Doctor/Doctorchatpage'
import Feedbacklist from '../Pages/AdminPage/Feedbacklist'





function Router() {
  return (
    <BrowserRouter>
    <Routes>
    <Route path="/" element={<Login />} />
    <Route path="/patientlogin" element={<Patientlogin/>} />
    <Route path="/dashboard" element={<Dashboard />} />
    <Route path="/viewpage" element={<Viewpage />} />
    <Route path="/prescription" element={<Index/>}/>
    <Route path="/Admin" element={<AdminPage/>}/>
    <Route path="/patienthome" element={<Homepage/>}/>
    <Route path="/camera" element={<Camerapage/>}/>
    <Route path="/patientlist" element={<Patientlist/>}/>
    <Route path="/SummaryPrint" element={<SummaryPrint/>}/>
    <Route path="/Printprescription" element={<Printprescription/>}/>
    <Route path="/PathyaApthya" element={<PathyaApthya/>}/>
    <Route path="/dailypatientreport/:id" element={<Dailypatientrport/>}/>
    <Route path="/addclinic" element={<AddClinic/>}/>
    <Route path="/qrscanner" element={<QRreader/>}/>
    <Route path="/chat/:id/:name/:patid/:patuserid" element={<Chatpage/>}/>
    <Route path="/print/:id" element={<Printbrower/>}/>
    <Route path="/prescbrower/:id" element={<Browerprescription/>}/>
    <Route path="/Pathyabrower/:id" element={<Pathyabrower/>}/>
    <Route path="/Chattopatient/:id/:userid" element={<Chattopatient/>}/>
    <Route path="/Doctorchatpage" element={<Doctorchatpage/>}/>
    <Route path="/Feedbacklist" element={<Feedbacklist/>}/>
   

    
  
    </Routes>
    </BrowserRouter>
  )
}

export default Router