export const actionTypes = {
    
    PRESCRIPTION_ADD: "PRESCRIPTION_ADD",
    PRESCRIPTION_ADD_SUCCESS: "PRESCRIPTION_ADD_SUCCESS",
    PRESCRIPTION_ADD_ERROR: "PRESCRIPTION_ADD_ERROR",
    
    ADD_DRUG: "ADD_DRUG",
    ADD_DRUG_SUCCESS: "ADD_DRUG_SUCCESS",
    ADD_DRUG_ERROR: "ADD_DRUG_ERROR",

    DRUG_INSTRUCTION: "DRUG_INSTRUCTION",
    DRUG_INSTRUCTION_SUCCESS: "DRUG_INSTRUCTION_SUCCESS",
    DRUG_INSTRUCTION_ERROR: "DRUG_INSTRUCTION_ERROR",

    ATTRIBUTE: "ATTRIBUTE",
    ATTRIBUTE_SUCCESS: "ATTRIBUTE_SUCCESS",
    ATTRIBUTE_ERROR: "ATTRIBUTE_ERROR",

    PATHYA_APATHYA: "PATHYA_APATHYA",
    PATHYA_APATHYA_SUCCESS: "PATHYA_APATHYA_SUCCESS",
    PATHYA_APATHYA_ERROR: "PATHYA_APATHYA_ERROR",

    SEARCH_PATIENT: "SEARCH_PATIENT",
    SEARCH_PATIENT_SUCCESS: "SEARCH_PATIENT_SUCCESS",
    SEARCH_PATIENT_ERROR: "SEARCH_PATIENT_ERROR",

    AGNI_VALUE: "AGNI_VALUE",
    AGNI_VALUE_SUCCESS: "AGNI_VALUE_SUCCESS",
    AGNI_VALUE_ERROR: "AGNI_VALUE_ERROR",

    KOSHTA_VALUE: "KOSHTA_VALUE",
    KOSHTA_VALUE_SUCCESS: "KOSHTA_VALUE_SUCCESS",
    KOSHTA_VALUE_ERROR: "KOSHTA_VALUE_ERROR",

    JIHWA_VALUE: "JIHWA_VALUE",
    JIHWA_VALUE_SUCCESS: "JIHWA_VALUE_SUCCESS",
    JIHWA_VALUE_ERROR: "JIHWA_VALUE_ERROR",

    NAADI_VALUE: "NAADI_VALUE",
    NAADI_VALUE_SUCCESS: "NAADI_VALUE_SUCCESS",
    NAADI_VALUE_ERROR: "NAADI_VALUE_ERROR",

    MALA_VALUE: "MALA_VALUE",
    MALA_VALUE_SUCCESS: "MALA_VALUE_SUCCESS",
    MALA_VALUE_ERROR: "MALA_VALUE_ERROR",

    MUTRA_VALUE: "MUTRA_VALUE",
    MUTRA_VALUE_SUCCESS: "MUTRA_VALUE_SUCCESS",
    MUTRA_VALUE_ERROR: "MUTRA_VALUE_ERROR",

    NIDRA_VALUE: "NIDRA_VALUE",
    NIDRA_VALUE_SUCCESS: "NIDRA_VALUE_SUCCESS",
    NIDRA_VALUE_ERROR: "NIDRA_VALUE_ERROR",

    PRAKUTI_VALUE: "PRAKUTI_VALUE",
    PRAKUTI_VALUE_SUCCESS: "PRAKUTI_VALUE_SUCCESS",
    PRAKUTI_VALUE_ERROR: "PRAKUTI_VALUE_ERROR",

    DRUG_TEMPLATE: "DRUG_TEMPLATE",
    DRUG_TEMPLATE_SUCCESS: "DRUG_TEMPLATE_SUCCESS",
    DRUG_TEMPLATE_ERROR: "DRUG_TEMPLATE_ERROR",

    SAVE_IMAGE: "SAVE_IMAGE",
    SAVE_IMAGE_SUCCESS: "SAVE_IMAGE_SUCCESS",
    SAVE_IMAGE_ERROR: "SAVE_IMAGE_ERROR",

    ADD_PATHIYA: "ADD_PATHIYA",
    ADD_PATHIYA_SUCCESS: "ADD_PATHIYA_SUCCESS",
    ADD_PATHIYA_ERROR: "ADD_PATHIYA_ERROR",

    SET_CLINIC: "SET_CLINIC",
    SET_CLINIC_SUCCESS: "SET_CLINIC_SUCCESS",
    SET_CLINIC_ERROR: "SET_CLINIC_ERROR",

    SCAN_QR: "SCAN_QR",
    SCAN_QR_SUCCESS: "SCAN_QR_SUCCESS",
    SCAN_QR_ERROR: "SCAN_QR_ERROR",

    PRINT: "PRINT",
    PRINT_SUCCESS: "PRINT_SUCCESS",
    PRINT_ERROR: "PRINT_ERROR",

    CHAT_TO_PATIENT: "CHAT_TO_PATIENT",
    CHAT_TO_PATIENT_SUCCESS: "CHAT_TO_PATIENT_SUCCESS",
    CHAT_TO_PATIENT_ERROR: "CHAT_TO_PATIENT_ERROR",

    DOCTOR_NOTIFICATION: "DOCTOR_NOTIFICATION",
    DOCTOR_NOTIFICATION_SUCCESS: "DOCTOR_NOTIFICATION_SUCCESS",
    DOCTOR_NOTIFICATION_ERROR: "DOCTOR_NOTIFICATION_ERROR",

    NOTIFICATION_STATUS: "NOTIFICATION_STATUS",
    NOTIFICATION_STATUS_SUCCESS: "NOTIFICATION_STATUS_SUCCESS",
    NOTIFICATION_STATUS_ERROR: "NOTIFICATION_STATUS_ERROR",

    DOCTOR_NOTIFICATION_APPROVE: "DOCTOR_NOTIFICATION_APPROVE",
    DOCTOR_NOTIFICATION_APPROVE_SUCCESS: "DOCTOR_NOTIFICATION_APPROVE_SUCCESS",
    DOCTOR_NOTIFICATION_APPROVE_ERROR: "DOCTOR_NOTIFICATION_APPROVE_ERROR",

    SENT_LINK: "SENT_LINK",
    SENT_LINK_SUCCESS: "SENT_LINK_SUCCESS",
    SENT_LINK_ERROR: "SENT_LINK_ERROR",

    GET_LAKSHANA: "GET_LAKSHANA",
    GET_LAKSHANA_SUCCESS: "GET_LAKSHANA_SUCCESS",
    GET_LAKSHANA_ERROR: "GET_LAKSHANA_ERROR",

    GET_CAUSE: "GET_CAUSE",
    GET_CAUSE_SUCCESS: "GET_CAUSE_SUCCESS",
    GET_CAUSE_ERROR: "GET_CAUSE_ERROR",

    HELP: "HELP",
    HELP_SUCCESS: "HELP_SUCCESS",
    HELP_ERROR: "HELP_ERROR",
  };

  export const prescriptionadd = (payload,localstorageData) => {
    console.log("action",localstorageData);
    return {
      type: actionTypes.PRESCRIPTION_ADD,
      payload,localstorageData,

    };
  };
  export const prescriptionaddSucces = (payload) => {
    return {
      type: actionTypes.PRESCRIPTION_ADD_SUCCESS,  
      payload,
    };
  };
  export const prescriptionaddError = (payload) => {
    return {
      type: actionTypes.PRESCRIPTION_ADD_ERROR,
      payload,
    };
  };

  export const saveimage = (payload) => {
    return {
      type: actionTypes.SAVE_IMAGE,
      payload,
    };
  };
  export const saveimageSucces = (payload) => {
    return {
      type: actionTypes.SAVE_IMAGE_SUCCESS,  
      payload,
    };
  };
  export const saveimageError = (payload) => {
    return {
      type: actionTypes.SAVE_IMAGE_ERROR,
      payload,
    };
  };
  
  export const drugadd = (payload) => {
    return {
      type: actionTypes.ADD_DRUG,
      payload,
    };
  };
  export const drugaddSucces = (payload) => {
    return {
      type: actionTypes.ADD_DRUG_SUCCESS,  
      payload,
    };
  };
  export const drugaddError = (payload) => {
    return {
      type: actionTypes.ADD_DRUG_ERROR,
      payload,
    };
  };
  export const Instructiondrug = (payload) => {
    return {
      type: actionTypes.DRUG_INSTRUCTION,
      payload,
    };
  };
  export const InstructiondrugSucces = (payload) => {
    return {
      type: actionTypes.DRUG_INSTRUCTION_SUCCESS,  
      payload,
    };
  };
  export const InstructiondrugError = (payload) => {
    return {
      type: actionTypes.DRUG_INSTRUCTION_ERROR,
      payload,
    };
  };
  export const attribute = (payload) => {
    return {
      type: actionTypes.ATTRIBUTE,
      payload,
    };
  };
  export const attributeSucces = (payload) => {
    return {
      type: actionTypes.ATTRIBUTE_SUCCESS,  
      payload,
    };
  };
  export const attributeError = (payload) => {
    return {
      type: actionTypes.ATTRIBUTE_ERROR,
      payload,
    };
  };
  export const pathyaapathya = (payload) => {
    return {
      type: actionTypes.PATHYA_APATHYA,
      payload,
    };
  };
  export const pathyaapathyaSucces = (payload) => {
    return {
      type: actionTypes.PATHYA_APATHYA_SUCCESS,  
      payload,
    };
  };
  export const pathyaapathyaError = (payload) => {
    return {
      type: actionTypes.PATHYA_APATHYA_ERROR,
      payload,
    };
  };
  export const searchpatient = (payload) => {
    return {
      type: actionTypes.SEARCH_PATIENT,
      payload,
    };
  };
  export const searchpatientSucces = (payload) => {
    return {
      type: actionTypes.SEARCH_PATIENT_SUCCESS,  
      payload,
    };
  };
  export const searchpatientError = (payload) => {
    return {
      type: actionTypes.SEARCH_PATIENT_ERROR,
      payload,
    };
  };
  export const agnivalues = (payload) => {
    return {
      type: actionTypes.AGNI_VALUE,
      payload,
    };
  };
  export const agnivaluesSucces = (payload) => {
    return {
      type: actionTypes.AGNI_VALUE_SUCCESS,  
      payload,
    };
  };
  export const agnivaluesError = (payload) => {
    return {
      type: actionTypes.AGNI_VALUE_ERROR,
      payload,
    };
  };
  export const koshtavalues = (payload) => {
    return {
      type: actionTypes.KOSHTA_VALUE,
      payload,
    };
  };
  export const koshtavaluesSucces = (payload) => {
    return {
      type: actionTypes.KOSHTA_VALUE_SUCCESS,  
      payload,
    };
  };
  export const koshtavaluesError = (payload) => {
    return {
      type: actionTypes.KOSHTA_VALUE_ERROR,
      payload,
    };
  };
  export const jihwavalue = (payload) => {
    return {
      type: actionTypes.JIHWA_VALUE,
      payload,
    };
  };
  export const jihwavalueSucces = (payload) => {
    return {
      type: actionTypes.JIHWA_VALUE_SUCCESS,  
      payload,
    };
  };
  export const jihwavalueError = (payload) => {
    return {
      type: actionTypes.JIHWA_VALUE_ERROR,
      payload,
    };
  };
  export const naadivalue = (payload) => {
    return {
      type: actionTypes.NAADI_VALUE,
      payload,
    };
  };
  export const naadivalueSucces = (payload) => {
    return {
      type: actionTypes.NAADI_VALUE_SUCCESS,  
      payload,
    };
  };
  export const naadivalueError = (payload) => {
    return {
      type: actionTypes.NAADI_VALUE_ERROR,
      payload,
    };
  };


  export const malavalue = (payload) => {
    return {
      type: actionTypes.MALA_VALUE,
      payload,
    };
  };
  export const malavalueSucces = (payload) => {
    return {
      type: actionTypes.MALA_VALUE_SUCCESS,  
      payload,
    };
  };
  export const malavalueError = (payload) => {
    return {
      type: actionTypes.MALA_VALUE_ERROR,
      payload,
    };
  };


  export const mutravalue = (payload) => {
    return {
      type: actionTypes.MUTRA_VALUE,
      payload,
    };
  };
  export const mutravalueSucces = (payload) => {
    return {
      type: actionTypes.MUTRA_VALUE_SUCCESS,  
      payload,
    };
  };
  export const mutravalueError = (payload) => {
    return {
      type: actionTypes.MUTRA_VALUE_ERROR,
      payload,
    };
  };


  export const nidravalue = (payload) => {
    return {
      type: actionTypes.NIDRA_VALUE,
      payload,
    };
  };
  export const nidravalueSucces = (payload) => {
    return {
      type: actionTypes.NIDRA_VALUE_SUCCESS,  
      payload,
    };
  };
  export const nidravalueError = (payload) => {
    return {
      type: actionTypes.NIDRA_VALUE_ERROR,
      payload,
    };
  };


  export const prakutivalue = (payload) => {
    return {
      type: actionTypes.PRAKUTI_VALUE,
      payload,
    };
  };
  export const prakutivalueSucces = (payload) => {
    return {
      type: actionTypes.PRAKUTI_VALUE_SUCCESS,  
      payload,
    };
  };
  export const prakutivalueError = (payload) => {
    return {
      type: actionTypes.PRAKUTI_VALUE_ERROR,
      payload,
    };
  };


  export const drugtemplate = (payload) => {
    return {
      type: actionTypes.DRUG_TEMPLATE,
      payload,
    };
  };
  export const drugtemplateSucces = (payload) => {
    return {
      type: actionTypes.DRUG_TEMPLATE_SUCCESS,  
      payload,
    };
  };
  export const drugtemplateError = (payload) => {
    return {
      type: actionTypes.DRUG_TEMPLATE_ERROR,
      payload,
    };
  };


  export const addpathiyaapathaya = (payload) => {
    return {
      type: actionTypes.ADD_PATHIYA,
      payload,
    };
  };
  export const addpathiyaapathayaSucces = (payload) => {
    return {
      type: actionTypes.ADD_PATHIYA_SUCCESS,  
      payload,
    };
  };
  export const addpathiyaapathayaError = (payload) => {
    return {
      type: actionTypes.ADD_PATHIYA_ERROR,
      payload,
    };
  };


  export const setclinic = (payload) => {
    return {
      type: actionTypes.SET_CLINIC,
      payload,
    };
  };
  export const setclinicSucces = (payload) => {
    return {
      type: actionTypes.SET_CLINIC_SUCCESS,  
      payload,
    };
  };
  export const setclinicError = (payload) => {
    return {
      type: actionTypes.SET_CLINIC_ERROR,
      payload,
    };
  };


  export const scanqr = (payload) => {
    return {
      type: actionTypes.SCAN_QR,
      payload,
    };
  };
  export const scanqrSucces = (payload) => {
    return {
      type: actionTypes.SCAN_QR_SUCCESS,  
      payload,
    };
  };
  export const scanqrError = (payload) => {
    return {
      type: actionTypes.SCAN_QR_ERROR,
      payload,
    };
  };


  export const print = (payload) => {
    return {
      type: actionTypes.PRINT,
      payload,
    };
  };
  export const printSucces = (payload) => {
    return {
      type: actionTypes.PRINT_SUCCESS,  
      payload,
    };
  };
  export const printError = (payload) => {
    return {
      type: actionTypes.PRINT_ERROR,
      payload,
    };
  };

  export const chattopatient = (payload) => {
    return {
      type: actionTypes.CHAT_TO_PATIENT,
      payload,
    };
  };
  export const chattopatientSucces = (payload) => {
    return {
      type: actionTypes.CHAT_TO_PATIENT_SUCCESS,  
      payload,
    };
  };
  export const chattopatientError = (payload) => {
    return {
      type: actionTypes.CHAT_TO_PATIENT_ERROR,
      payload,
    };
  };
  export const notificationstatus = (payload) => {
    return {
      type: actionTypes.NOTIFICATION_STATUS,
      payload,
    };
  };
  export const notificationstatusSucces = (payload) => {
    return {
      type: actionTypes.NOTIFICATION_STATUS_SUCCESS,  
      payload,
    };
  };
  export const notificationstatusError = (payload) => {
    return {
      type: actionTypes.NOTIFICATION_STATUS_ERROR,
      payload,
    };
  };
  export const doctornoti = (payload) => {
    return {
      type: actionTypes.DOCTOR_NOTIFICATION,
      payload,
    };
  };
  export const doctornotiSucces = (payload) => {
    return {
      type: actionTypes.DOCTOR_NOTIFICATION_SUCCESS,  
      payload,
    };
  };
  export const doctornotiError = (payload) => {
    return {
      type: actionTypes.DOCTOR_NOTIFICATION_ERROR,
      payload,
    };
  };
  export const doctornotiapprove = (payload) => {
    return {
      type: actionTypes.DOCTOR_NOTIFICATION_APPROVE,
      payload,
    };
  };
  export const doctornotiapproveSucces = (payload) => {
    return {
      type: actionTypes.DOCTOR_NOTIFICATION_APPROVE_SUCCESS,  
      payload,
    };
  };
  export const doctornotiapproveError = (payload) => {
    return {
      type: actionTypes.DOCTOR_NOTIFICATION_APPROVE_ERROR,
      payload,
    };
  };
  export const sentlink = (payload) => {
    return {
      type: actionTypes.SENT_LINK,
      payload,
    };
  };
  export const sentlinkSucces = (payload) => {
    return {
      type: actionTypes.SENT_LINK_SUCCESS,  
      payload,
    };
  };
  export const sentlinkError = (payload) => {
    return {
      type: actionTypes.SENT_LINK_ERROR,
      payload,
    };
  };
  export const getlakshana = (payload) => {
    return {
      type: actionTypes.GET_LAKSHANA,
      payload,
    };
  };
  export const getlakshanaSucces = (payload) => {
    return {
      type: actionTypes.GET_LAKSHANA_SUCCESS,  
      payload,
    };
  };
  export const getlakshanaError = (payload) => {
    return {
      type: actionTypes.GET_LAKSHANA_ERROR,
      payload,
    };
  };
  
  export const getcause = (payload) => {
    return {
      type: actionTypes.GET_CAUSE,
      payload,
    };
  };
  export const getcauseSucces = (payload) => {
    return {
      type: actionTypes.GET_CAUSE_SUCCESS,  
      payload,
    };
  };
  export const getcauseError = (payload) => {
    return {
      type: actionTypes.GET_CAUSE_ERROR,
      payload,
    };
  };
  
  export const help = (payload) => {
    return {
      type: actionTypes.HELP,
      payload,
    };
  };
  export const helpSucces = (payload) => {
    return {
      type: actionTypes.HELP_SUCCESS,  
      payload,
    };
  };
  export const helpError = (payload) => {
    return {
      type: actionTypes.HELP_ERROR,
      payload,
    };
  };