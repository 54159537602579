import { useEffect } from 'react';
import {App} from '@capacitor/app';

const useHardwareBackButton = (IsExit,handleBackButtonPress) => {
    useEffect(() => {
      const handleBackButton = (event) => {
        if (IsExit === 0) {
          // alert(handleBackButtonPress);
          handleBackButtonPress();
        } else {
            App.exitApp()
        }
      };
  
      // Adding back button listener
      App.addListener('backButton', handleBackButton);
  
      // Removing back button listener on cleanup
      return () => {
        App.removeAllListeners('backButton')
      };
    }, [IsExit, handleBackButtonPress]);
};

export default useHardwareBackButton;