import React, { useState, useRef, useEffect } from "react";
import { Nav, Tab, Carousel } from "react-bootstrap";
import { PlusCircle } from "react-feather";
import "../../CSSstyle/AdminPageCss/AdminPage.css";
import Details from "./Img/personal-information.png";
import AyurVedaPng from "./Img/ayurveda.png";
import LakshanaPng from "./Img/symptom.png";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  AiOutlinePrinter,
  AiFillSave,
  AiOutlineShareAlt,
} from "react-icons/ai";
import { Button, Col, CustomInput, Input, Label, Row, Table } from "reactstrap";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  attribute,
  jihwavalue,
  drugadd,
  Instructiondrug,
  prescriptionadd,
  agnivalues,
  koshtavalues,
  naadivalue,
  malavalue,
  mutravalue,
  nidravalue,
  prakutivalue,
  pathyaapathya,
  drugtemplate,
  addpathiyaapathaya,
  sentlink,
  getlakshana,
  getcause,
} from "../../redux/Doctor/action";
import { useSelector } from "react-redux";
import { WhatsappShareButton } from "react-share";
import { Menu } from "react-feather";
import Select from "react-select";
import AddDrugModal from "./Modals/AddDrugModal";
import MedicalReportModal from "./Modals/MedicalReportModal";
import DoctorNav from "../Common/DoctorNav";
import { idtype } from "../../redux/Admin/action";
import { VscNewFile } from "react-icons/vsc";
import PrintModal from "./Modals/PrintModal";
import { baseUrl } from "../../configs/config";
import { App } from "@capacitor/app";
import useHardwareBackButton from "../useHardwareBackButton";

function NewDesign({
  setdatastore,
  setdisplay,
  cameraimage,
  datastore,
  setcameraimage,
}) {

  const [activeTab, setActiveTab] = useState(1);
  const dispatch = useDispatch();
  const {state} = useLocation();
  const [patientsdetails, setpatientsdetails] = useState();
  const [Patientsname, setPatientsname] = useState();
  const [dob, setdob] = useState();
  const [mobile, setmobile] = useState();
  const [mailid, setmailid] = useState();
  const [address, setaddress] = useState();
  const [sex, setsex] = useState();
  const [idproof, setidproof] = useState();
  const [idproofvalue, setidproofvalue] = useState();
  const [bloodpre, setbloodpre] = useState();
  const [pulserate, setpulserate] = useState();
  const [temperature, settemperature] = useState();
  const [weight, setweight] = useState();
  const [height, setheight] = useState();
  const [BMI, setBMI] = useState();
  const [spo2, setspo2] = useState();
  const [rpg, setrpg] = useState();
  const [agni, setagni] = useState();
  const [koshta, setkoshta] = useState();
  const [jihwa, setjihwa] = useState();
  const [naadi, setnaadi] = useState();
  const [mala, setmala] = useState();
  const [mutra, setmutra] = useState();
  const [nidra, setnidra] = useState();
  const [Prakruti, setPrakruti] = useState();
  const [yogavalue, setyogavalue] = useState([]);
  const [diagnosisnote, setdiagnosisnote] = useState();
  const [complaint, setcomplaint] = useState();
  const [cause, setcause] = useState();
  const [drugimage, setdrugimage] = useState();
  const [hiddenNotes, sethiddenNotes] = useState();
  const [yogam, setyogam] = useState();
  const [serachphone, setserachphone] = useState();
  const [instru, setinstru] = useState();
  const [activeprameha, setactiveprameha] = useState(false);
  const [EditValue, setEditValue] = useState();
  const [day, setday] = useState();
  const [follow, setfollow] = useState("Follow Up Date");
  const [medical, setmedical] = useState([]);
  const [addmodal, setAddmodal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [prameha, setprameha] = useState("");
  const [activevalue, setactivevalue] = useState(false);
  const [validPhoneNumber, setValidPhoneNumber] = useState(true);
  const [kaphaja, setkaphaja] = useState();
  const [pithaja, setpithaja] = useState();
  const [rating, setRating] = useState(50);
  const [vitaja, setvitaja] = useState();
  const [hb, sethb] = useState(false);
  const [templatesave, settemplatesave] = useState(false);
  const [cholesterol, setcholesterol] = useState(false);
  const [sugar, setsugar] = useState(false);
  const [thyriod, setthyriod] = useState(false);

  let finallist;
  let arrayattribute;

  const [characters, updateCharacters] = useState();
  const [agnilistvalue, setagnilistvalue] = useState("");
  const [koshtalistvalue, setkoshtalistvalue] = useState("");
  const [naadilistvalue, setnaadilistvalue] = useState("");
  const [jihwalistvalue, setjihwalistvalue] = useState("");
  const [malalistvalue, setmalalistvalue] = useState("");
  const [mutradata, setmutradata] = useState();
  const [finalmutra, setfinalmutra] = useState();
  const [mutralistvalue, setmutralistvalue] = useState("");
  const [nidralistvalue, setnidralistvalue] = useState("");
  const [prakrutilistvalue, setprakrutilistvalue] = useState("");
  const [clinicname, setclinicname] = useState("");
  const [local, setlocal] = useState();
  const scanDetails = useSelector((state) => state.Doctor.qrscan);
  const druglist = useSelector((state) => state.Doctor.adddrug);
  const instructionlist = useSelector((state) => state.Doctor.druginstruction);
  const attributelist = useSelector((state) => state.Doctor.attribute);
  const prescription = useSelector((state) => state.Doctor.addprescription);
  const patientsearch = useSelector((state) => state.Doctor.searchpatient);
  const pathyapthyalist = useSelector((state) => state.Doctor.pathyapthya);
  const agnilist = useSelector((state) => state.Doctor.agni);
  const koshtalist = useSelector((state) => state.Doctor.koshta);
  const jihwalist = useSelector((state) => state.Doctor.jihwa);
  const naadilist = useSelector((state) => state.Doctor.naadi);
  const malalist = useSelector((state) => state.Doctor.mala);
  const mutralist = useSelector((state) => state.Doctor.mutra);
  const nidralist = useSelector((state) => state.Doctor.nidra);
  const prakutilist = useSelector((state) => state.Doctor.prakuti);
  const addpathiyapathya = useSelector((state) => state.Doctor.pathyaadd);
  const listlakshana = useSelector((state) => state.Doctor.lakshana);
  const listcause = useSelector((state) => state.Doctor.cause);
  const drugtemplatelist = useSelector((state) => state.Doctor.drugtemplate);
  const drugsave = useSelector((state) => state.Admin.drugmaster);
  const doctordetails = JSON.parse(localStorage.getItem("UserDAta_AyuRx"));

  const storedData = JSON.parse(localStorage.getItem("myData"));
  
  const GetIdType = useSelector((state) => state.Admin.idtypeget);

  const shareMessage = "Your share message here";
  const shareUrl = baseUrl + `/Print/${prescription[0]?.Id}`;

  const [birthday, setbirthday] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [age, setAge] = useState(null);

   

//Hardware BackButton Press

console.log("modalType",modalType);
const handleBackButtonPress = () => {
  if(addmodal){
    setAddmodal(false)
  }else{
    if(activeTab===1){
      var confirmation = window.confirm("Are you sure you want to exit the app?");

    // If the user confirms, close the window (exit the app)
    if (confirmation) {
      App.exitApp();
    }
      
    }else{
      setActiveTab(1)
    }
  }
 };
 useHardwareBackButton(0,handleBackButtonPress)


  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 100 }, (_, i) => currentYear - i);

  const currentDate = new Date();
  const birthYear = age ? currentDate.getFullYear() - age : "";
  useEffect(() => {
    if (birthYear) {
      setYear(birthYear);
    }
  }, [birthYear]);

  useEffect(() => {
    if (state&&state.clinicname) {
      setclinicname(state.clinicname);
      window.history.replaceState({}, document.title) 
    }
  }, []);
// console.log("storedData",storedData);
  useEffect(() => {
    if (storedData) {
      setlocal(storedData);
    }
  }, []);

  useEffect(() => {
    dispatch(idtype());
  }, []);

  useEffect(() => {
    if (drugsave) {
      dispatch(
        drugadd({
          Did: doctordetails.Id,
        })
      );
    }
  }, [drugsave]);

  useEffect(() => {
    dispatch(
      drugtemplate({
        Did: doctordetails.Id,
      })
    );
  }, []);

  useEffect(() => {
    if (mutralist) {
      let data = mutralist[0]?.Items;
      setmutradata(data);
    }
  }, []);

  useEffect(() => {
    if (scanDetails) {
      const dateString = scanDetails[0].DateOfBirth;
      const dateComponents = dateString.split("/");
      const [day, month, year] = dateComponents.map(Number);
      setActiveTab(1);
      setaddress(scanDetails[0].Address);
      setPatientsname(scanDetails[0].Pname);
      setmobile(scanDetails[0].Mobile);
      setPatientsname(scanDetails[0].Name);
      setmailid(scanDetails[0].Email);
      setAge(scanDetails[0].Age);
      setbirthday(day);
      setMonth(month);
      setYear(year);
      setclinicname(scanDetails[0].ClinicId);
      setsex(scanDetails[0].gender);
    }
  }, [scanDetails]);

  useEffect(() => {
    console.log('prescription',prescription)
    if (prescription[0]?.Id) {
       dispatch(
        sentlink({
          Mobile: mobile,
          Sub: baseUrl + `/print/${prescription[0]?.Id}`,
        })
      );
    }
  }, [prescription]);

  useEffect(() => {
    if (patientsdetails) {
      const dateString = patientsdetails.DateOfBirth;
      const dateComponents = dateString.split("/");
      const [day, month, year] = dateComponents.map(Number);
      setActiveTab(1);
      setaddress(patientsdetails.Address);
      setPatientsname(patientsdetails.Pname);
      setmobile(patientsdetails.Mobile);
      setmailid(patientsdetails.Email);
      setAge(patientsdetails.Age);
      setbirthday(day);
      setMonth(month);
      setYear(year);
      setclinicname(patientsdetails.ClinicId);
      setsex(patientsdetails.Gender);
    }
  }, [patientsdetails]);

 
  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    setmobile(value);
    validatePhoneNumber(value);
  };

  const validatePhoneNumber = (value) => {
    const phoneRegex = /^[0-9]{10}$/;

    const isValid = phoneRegex.test(value);

    setValidPhoneNumber(isValid);
  };
  
  useEffect(() => {
    if (local) {
      console.log("local.clinicname",local.clinicname);
      setPatientsname(local?.Name);
      setbirthday(local?.birth);
      setMonth(local?.month);
      setYear(local?.year);
      setAge(local?.age);
      setsex(local?.Gender);
      setmobile(local?.Mobile);
      setBMI(local?.bmi);
      setmailid(local?.EmailId);
      setaddress(local?.Address);
      setidproof(local?.IdType);
      setidproofvalue(local?.IdNo);
      setagni(local?.agni);
      setkoshta(local?.koshta);
      setjihwa(local?.jihwa);
      setnaadi(local?.naadi);
      setmala(local?.mala);
      setmutra(local?.mutra);
      setnidra(local?.nidra);
      setPrakruti(local?.Prakruti);
      setfinalmutra(local?.finalmutra);
      setRating(local?.rating);
      setbloodpre(local?.bloodpre);
      setpulserate(local?.pulserate);
      settemperature(local?.temperature);
      setweight(local?.weight);
      setheight(local?.height);
      setspo2(local?.spo2);
      setrpg(local?.rpg);
      setcause(local?.cause);
      setcomplaint(local?.complaint);
      setdiagnosisnote(local?.diagnosisnote);
      setPathyaApathyaUpdatedList(local?.pathyapathya);
      sethiddenNotes(local?.hiddenNotes);
      updateCharacters(local?.characters);
      setday(local?.day);
      setfollow(local?.follow);
      setclinicname(local?.clinicname);
      settemplatesave(local?.templatesave)
      setmedical(local?.medical)
    }
  }, [local]);


  useEffect(() => {
    if (datastore) {
      console.log('datastore.clinicid',datastore.clinicid);
      setActiveTab(2);
      setPatientsname(datastore.Name);
      setbirthday(datastore.birthday);
      setMonth(datastore.month);
      setYear(datastore.year);
      setAge(datastore.age);
      setBMI(datastore.bmi);
      setclinicname(datastore.clinicid);
      setsex(datastore.Gender);
      setmobile(datastore.Mobile);
      setmailid(datastore.EmailId);
      setaddress(datastore.Address);
      setidproof(datastore.IdType);
      setidproofvalue(datastore.IdNo);
      setagni(datastore.agni);
      setkoshta(datastore.koshta);
      setjihwa(datastore.jihwa);
      setnaadi(datastore.naadi);
      setmala(datastore.mala);
      setmutra(datastore.mutra);
      setnidra(datastore.nidra);
      setPrakruti(datastore.Prakruti);
      setfinalmutra(datastore.finalmutra);
      setRating(datastore.rating);
      setbloodpre(datastore.bloodpre);
      setpulserate(datastore.pulserate);
      settemperature(datastore.temperature);
      setweight(datastore.weight);
      setheight(datastore.height);
      setspo2(datastore.spo2);
      setrpg(datastore.rpg);
      setcause(datastore.cause);
      setcomplaint(datastore.complaint);
      setdiagnosisnote(datastore.diagnosisnote);
      setPathyaApathyaUpdatedList(datastore.pathyapathya);
      sethiddenNotes(datastore.hiddenNotes);
      updateCharacters(datastore.characters);
      settemplatesave(datastore.templatesave)
      setmedical(datastore.medical)
      sethb(datastore.hb);
      setcholesterol(datastore.cholesterol);
      setsugar(datastore.sugar);
      setthyriod(datastore.thyriod);
      setday(datastore.day);
      setfollow(datastore.follow);
    }
  }, [datastore]);
// console.log('characters OutSide UseEffect',characters)
// console.log('Datastore outside',datastore)
  useEffect(() => {
    if (EditValue && patientsearch) {
      setyogam(EditValue.drugId);
      setinstru(EditValue.drugInstructionId);
      setday(EditValue.days);
    }
  }, [EditValue, patientsearch]);

  const handleRatingChange = (event) => {
    setRating(event.target.value);
  };
  // console.log("checked", checked);
  const clearData = () => {
    localStorage.removeItem("myData");
    window.location.reload(true);
    
  };

  function handledelete(id) {
    const confirmeassage = "Do you want to delete?";
    if (window.confirm(confirmeassage)) {
      let data = [...characters];
      const index = data.findIndex((delvalue) => delvalue.id === id);
      data.splice(index, 1);
      setyogavalue(data);
      updateCharacters(data);
    }
  }


  
  
 

  const handlesumbit = () => {
    // console.log(PathyaApathyaUpdatedList);
  if(prescription[0]?.Id){
    alert('This prescription has already saved')
    return;
  }
    const FinalPathyaApathya =
      PathyaApathyaUpdatedList &&
      PathyaApathyaUpdatedList.map((obj) => {
        return { Id: obj.value, Pathyapathya: obj.label };
      });
    // console.log(FinalPathyaApathya);
    arrayattribute = [
      ...medical,
      ...agnilistvalue,
      ...koshtalistvalue,
      ...naadilistvalue,
      ...jihwalistvalue,
      ...malalistvalue,
      ...mutralistvalue,
      ...nidralistvalue,
      ...prakrutilistvalue,
      { Id: 0, AttributId: "1", value: bloodpre || "" },
      { Id: 0, AttributId: "2", value: pulserate || "" },
      { Id: 0, AttributId: "3", value: temperature || "" },
      { Id: 0, AttributId: "4", value: weight || "" },
      { Id: 0, AttributId: "5", value: height || "" },
      { Id: 0, AttributId: "6", value: spo2 || "" },
      { Id: 0, AttributId: "7", value: rpg || "" },
    ];

    if (clinicname) {
      if (Patientsname && sex && day && mobile) {
        const dateObject = new Date(follow);
        dateObject?.setMinutes(
          dateObject?.getMinutes() - dateObject?.getTimezoneOffset()
        );
        const followDateString = dateObject?.toISOString();

        const todayDate = new Date();

        dispatch(
          prescriptionadd({
            Id: 0,
            PrescriptionNoSeries: "",
            date: todayDate,
            DoctorId: doctordetails.Id,
            PatientId: 1,
            Istemplate: 0,
            FDate: followDateString,
            HiddenNotes: hiddenNotes || "",
            ImgName: "",
            Istemplate: templatesave ? 1 : 0,
            Attributes: arrayattribute,
            Drugs: characters,
            patients: [
              {
                Id: 0,
                Name: Patientsname,
                DOB:
                  birthday.toString() +
                  "/" +
                  month.toString() +
                  "/" +
                  year.toString(),
                Gender: sex || "",
                Mobile: mobile,
                Address: address || "",
                EmailId: mailid || "",
                IdType: idproof || 2,
                IdNo: idproofvalue || "",
                clinicid: clinicname,
                age: age,
                Remarks: "",
                Status: 0,
              },
            ],
            pathyapathya: FinalPathyaApathya || "",
            lekshana: [
              {
                Id: 0,
                Causeid: cause?.value || 0,
                Complaintid: complaint?.value || 0,
                Metervalue: rating,
              },
            ],
            imagerequests: [
              {
                Id: 0,
                ImageCollection: cameraimage || "",
              },
            ],
            getLabresults: [
              {
                Id: 0,
                IsHB: hb ? 1 : 0,
                IsThyroid: thyriod ? 1 : 0,
                Ischolesterol: cholesterol ? 1 : 0,
                IsSugar: sugar ? 1 : 0,
                Pid: 0,
              },
            ],
          },
          {
          Name: Patientsname,
          DOB: dob,
          Gender: sex,
          bmi: BMI,
          birth: birthday,
          month: month,
          year: year,
          age: age,
          Mobile: mobile,
          Address: address,
          EmailId: mailid,
          IdType: idproof,
          IdNo: idproofvalue,
          agni: agni,
          koshta: koshta,
          jihwa: jihwa,
          naadi: naadi,
          mala: mala,
          finalmutra: finalmutra,
          mutra: mutra,
          nidra: nidra,
          Prakruti: Prakruti,
          bloodpre: bloodpre,
          pulserate: pulserate,
          temperature: temperature,
          weight: weight,
          height: height,
          spo2: spo2,
          rpg: rpg,
          cause: cause,
          complaint: complaint,
          diagnosisnote: diagnosisnote,
          rating: rating,
          day: day,
          follow: follow,
          characters: characters,
          hiddenNotes: hiddenNotes,
          pathyapathya: PathyaApathyaUpdatedList,
          clinicname: clinicname,
          templatesave:templatesave,
          medical:medical
        })
        );
      
      } else {
        alert("Fill All Mandatory  ");
      }
    } else {
      alert("Select Clinic");
    }
  };

  useEffect(() => {
    dispatch(
      drugadd({
        Did: doctordetails.Id,
      })
    );
    dispatch(Instructiondrug());
    dispatch(attribute());
    dispatch(agnivalues());
    dispatch(koshtavalues());
    dispatch(jihwavalue());
    dispatch(naadivalue());
    dispatch(malavalue());
    dispatch(mutravalue());
    dispatch(nidravalue());
    dispatch(prakutivalue());
    dispatch(pathyaapathya());
    dispatch(getlakshana());
    dispatch(getcause());
  }, []);

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(characters);

    const [reorderedItem] = items.splice(result.source.index, 1);

    items.splice(result.destination.index, 0, reorderedItem);

    updateCharacters(items);
    // console.log('items',items)
  }
  const handlecause = (value) => {
    // const agnilistvalues = value.map((item) => ({
    //   AttributId: item.Aid || "",
    //   value: item.label || "",
    //   Id: 0,
    // }));
    // setcause(agnilistvalues);
    setcause(value);
  };

  const handlecomplaint = (value) => {
    // const agnilistvalues = value.map((item) => ({
    //   AttributId: item.Aid || "",
    //   value: item.label || "",
    //   Id: 0,
    // }));
    // setcause(agnilistvalues);
    setcomplaint(
    );
  };

  const handleagni = (value) => {
    const agnilistvalues = value.map((item) => ({
      AttributId: item.Aid || "",
      value: item.label || "",
      Id: 0,
    }));
    setagnilistvalue(agnilistvalues);
    setagni(value);
    const elementToScroll = document.getElementById("scroll_id");
    if (elementToScroll) {
      elementToScroll.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handlekoshta = (value) => {
    const koshtalistvalues = value.map((item) => ({
      AttributId: item.Aid || "",
      value: item.label || "",
      Id: 0,
    }));
    setkoshtalistvalue(koshtalistvalues);
    setkoshta(value);
    const elementToScroll = document.getElementById("scroll_id");
    if (elementToScroll) {
      elementToScroll.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handlenaadi = (value) => {
    const naadilistvalues = value.map((item) => ({
      AttributId: item.Aid || "",
      value: item.label || "",
      Id: 0,
    }));
    setnaadilistvalue(naadilistvalues);
    setnaadi(value);
    const elementToScroll = document.getElementById("scroll_id");
    if (elementToScroll) {
      elementToScroll.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handlejihwa = (value) => {
    const jihwalistvalues = value.map((item) => ({
      AttributId: item.Aid || "",
      value: item.label || "",
      Id: 0,
    }));
    setjihwalistvalue(jihwalistvalues);
    setjihwa(value);
    const elementToScroll = document.getElementById("scroll_id");
    if (elementToScroll) {
      elementToScroll.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handlemala = (value) => {
    const malalistvalues = value.map((item) => ({
      AttributId: item.Aid || "",
      value: item.label || "",
      Id: 0,
    }));
    setmalalistvalue(malalistvalues);
    setmala(value);
    const elementToScroll = document.getElementById("scroll_id");
    if (elementToScroll) {
      elementToScroll.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handlemutra = (value) => {
    const mutralistvalues = value.map((item) => ({
      AttributId: item.Aid || "",
      value: item.label || "",
      Id: 0,
    }));
    setmutralistvalue(mutralistvalues);
    setfinalmutra(value);
    const elementToScroll = document.getElementById("scroll_id");
    if (elementToScroll) {
      elementToScroll.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handlenidra = (value) => {
    const nidralistvalues = value.map((item) => ({
      AttributId: item.Aid || "",
      value: item.label || "",
      Id: 0,
    }));
    setnidralistvalue(nidralistvalues);
    setnidra(value);
    const elementToScroll = document.getElementById("scroll_id");
    if (elementToScroll) {
      elementToScroll.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handlePrakruti = (value) => {
    const prakrutilistvalues = value?.map((item) => ({
      AttributId: item.Aid | "",
      value: item.label || "",
      Id: 0,
    }));
    setprakrutilistvalue(prakrutilistvalues);
    setPrakruti(value);
    const elementToScroll = document.getElementById("scroll_id");
    if (elementToScroll) {
      elementToScroll.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (yogavalue&&yogavalue.length>0) {
      // console.log('yogavalue',yogavalue)
      finallist = yogavalue.map((data, index) => ({
        id: (index + 1).toString(),
        ...data,
      }));
      updateCharacters(finallist);
      // console.log('finallist',finallist)
    }
  }, [yogavalue]);

  const handleTabSelect = (tab) => {
    // const NumberTab = parseInt(tab);
    setActiveTab(tab);
  };

  const calculateBMI = (weights, heights) => {
    if (weights && heights) {
      const bmi = ((weights / (heights * heights)) * 100).toFixed(1);
      setBMI(bmi);
    }
  };

  const calculateAge = (YY) => {
    const currentYear = new Date().getFullYear();
    const parsedBirthYear = parseInt(YY, 10);

    if (!isNaN(parsedBirthYear)) {
      const calculatedAge = currentYear - parsedBirthYear;
      setAge(calculatedAge);
    } else {
      setAge(null);
    }
  };
// console.log("medical",medical);
  const rendermodal = () => {
    switch (modalType) {
      case "MR":
        return (
          <MedicalReportModal
            addmodal={addmodal}
            setAddmodal={setAddmodal}
            attributelist={attributelist}
            setmedical={setmedical}
            medical={medical}
            setModalType={setModalType}
          />
        );

      case "drug":
        return (
          <AddDrugModal
            addmodal={addmodal}
            setAddmodal={setAddmodal}
            EditValue={EditValue}
            setEditValue={setEditValue}
            yogam={yogam}
            setyogam={setyogam}
            instru={instru}
            setinstru={setinstru}
            day={day}
            setday={setday}
            characters={characters}
            setModalType={setModalType}
            yogavalue={yogavalue}
            setyogavalue={setyogavalue}
            druglist={druglist}
            instructionlist={instructionlist}
            drugtemplatelist={drugtemplatelist}
          />
        );
      case "print":
        return (
          <PrintModal
            addmodal={addmodal}
            setAddmodal={setAddmodal}
            setModalType={setModalType}
          />
        );

      default:
        break;
    }
  };
  //Select PathyaApathya
  const [IsPlusButtonCliked, setIsPlusButtonCliked] = useState(false);
  const [PathyaApathyaUpdatedList, setPathyaApathyaUpdatedList] = useState([]);
  const [NewPathyaApathyValue, setNewPathyaApathyValue] = useState("");
  const [IsCheckBoxClicked, setIsCheckBoxClicked] = useState(false);
  // console.log("PathyaApathyaUpdatedList",PathyaApathyaUpdatedList);
  const PlusButtonClick = () => {
    setIsPlusButtonCliked(true);
  };
  const SetPathyaApathyaValues = (values) => {
    // console.log("values", values);
    const newArray = values.map((item) => ({
      value: item.value,
      label: item.label,
    }));

    // console.log(newArray)
    setPathyaApathyaUpdatedList(newArray);
  };
  const SetCheckBoxClick = () => {
    setIsCheckBoxClicked(true);

    if (NewPathyaApathyValue) {
      dispatch(
        addpathiyaapathaya({
          Id: 0,
          Pathyapathya: NewPathyaApathyValue,
        })
      );
    }
  };
  useEffect(() => {
    if (addpathiyapathya && addpathiyapathya[0].Error === 0) {
      const newArray = [
        ...PathyaApathyaUpdatedList,
        {
          value: addpathiyapathya && addpathiyapathya[0].Id,
          label: NewPathyaApathyValue,
        },
      ];
      setPathyaApathyaUpdatedList(newArray);
      setIsCheckBoxClicked(false);
      setNewPathyaApathyValue("");
      setIsPlusButtonCliked(false);
    }
  }, [addpathiyapathya]);
 
 
  return (
    <div className="bodybg cv-bodypadding" tabIndex={0}>
      <DoctorNav
        serachphone={serachphone}
        setserachphone={setserachphone}
        setpatientsdetails={setpatientsdetails}
        patientsdetails={patientsdetails}
        setclinicname={setclinicname}
        clinicname={clinicname}
        addmodal={addmodal}
         setAddmodal={setAddmodal}
      />

      <Tab.Container activeKey={activeTab} onSelect={handleTabSelect}>
        <div className="savedate-sidebar drawerstyle">
          <Nav
            className="flex-column "
            fill
            variant="pills"
            id="v-pills-tab"
            aria-orientation="vertical"
          >
            <Nav.Link eventKey={1} style={{ minWidth: "33.3333%" }}>
              <span
                style={{
                  backgroundImage: `url(${Details})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></span>
              <h6>Personal Details</h6>
            </Nav.Link>

            <Nav.Link eventKey={2} style={{ minWidth: "33.3333%" }}>
              <span
                style={{
                  backgroundImage: `url(${LakshanaPng})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></span>
              <h6>Lakshana</h6>
            </Nav.Link>

            <Nav.Link eventKey={3} style={{ minWidth: "33.3333%" }}>
              <span
                style={{
                  backgroundImage: `url(${AyurVedaPng})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></span>
              <h6>Add Drug</h6>
            </Nav.Link>
          </Nav>
        </div>
        <div className="container-fluid">
          <Tab.Content id="v-pills-tabContent">
            <Tab.Pane eventKey={1} style={{ marginTop: "60px" }}>
              
            {/* <button onClick={handleExitApp}>Exit App</button> */}
              <Row>
                <div>
                  <Col sm="6" md="12">
                    <div className=" mt-3">
                      {/* <Label className="label text-uppercase" style={{fontFamily:'avenirRegular'}}>
                        Patients Name<span className="field-required">*</span>
                      </Label> */}
                      <Input
                        type="text"
                        value={Patientsname}
                        placeholder=" Patient's Name*"
                        onChange={(e) => setPatientsname(e.target.value)}
                      />
                    </div>
                  </Col>
                  <Col sm="6" md="12">
                    {/* <div className="d-flex   mt-2"> */}
                    <div className=" d-flex mt-2">
                      <Input
                        type="select"
                        value={birthday}
                        onChange={(e) => {
                          setbirthday(e.target.value);
                        }}
                      >
                        <option>Day</option>
                        {days.map((d) => (
                          <option key={d} value={d}>
                            {d}
                          </option>
                        ))}
                      </Input>
                      <Input
                        type="select"
                        value={month}
                        onChange={(e) => {
                          setMonth(e.target.value);
                        }}
                      >
                        <option>Month</option>
                        {months.map((m, index) => (
                          <option key={m} value={index + 1}>
                            {m}
                          </option>
                        ))}
                      </Input>
                      {/* {age?<Input type="text"
                        value={birthYear}/>:} */}
                      <Input
                        type="select"
                        value={year}
                        onChange={(e) => {
                          setYear(e.target.value);
                          calculateAge(e.target.value);
                        }}
                      >
                        <option>Year</option>

                        {years.map((y) => (
                          <option key={y} value={y}>
                            {y}
                          </option>
                        ))}
                      </Input>

                      <Input
                        type="text"
                        placeholder="Age"
                        value={age}
                        onChange={(e) => {
                          setAge(e.target.value);
                        }}
                      />
                    </div>
                  </Col>
                  <Col sm="6" md="12">
                    <div className="mt-2">
                      <Input
                        type="select"
                        value={sex}
                        placeholder="Gender*"
                        onChange={(e) => setsex(e.target.value)}
                      >
                        <option>Select Gender</option>
                        <option value={"Female"}>Female</option>
                        <option value={"Male"}>Male</option>
                        <option value={"Other"}>Other</option>
                      </Input>
                    </div>
                  </Col>

                  <Col sm="6" md="12">
                    <div className=" d-flex justify-content-between mt-2">
                      {/* <Label className="label text-uppercase">
                        Mobile Number<span className="field-required">*</span>
                      </Label> */}
                      <Input
                        type="number"
                        value={mobile}
                        onChange={handlePhoneNumberChange}
                        placeholder="Mobile No*"
                      />

                      <Input
                        type="text"
                        value={mailid}
                        placeholder="Email id"
                        onChange={(e) => setmailid(e.target.value)}
                      />
                    </div>
                  </Col>
                  {!validPhoneNumber && (
                    <p style={{ color: "red" }}>
                      {" "}
                      enter a valid 10-digit phone .
                    </p>
                  )}
                  <Col sm="6" md="12">
                    <div className=" mt-2">
                      {/* <Label className="label text-uppercase">Address</Label> */}
                      <Input
                        type="textarea"
                        value={address}
                        placeholder="Address"
                        onChange={(e) => setaddress(e.target.value)}
                      />
                    </div>
                  </Col>

                  <Col sm="6" md="12">
                    <div className="d-flex  mt-2 mb-2">
                      <div>
                        {/* <Label className="label text-uppercase">
                          Id proof<span className="field-required">*</span>
                        </Label> */}
                        <Input
                          type="select"
                          placeholder="ID Proof"
                          value={idproof}
                          onChange={(e) => setidproof(e.target.value)}
                        >
                          <option>Select ID Proof</option>
                          {GetIdType.length &&
                            GetIdType.map((item, i) => {
                              return (
                                <option key={i} value={item.Id}>
                                  {item.Name}
                                </option>
                              );
                            })}
                        </Input>
                      </div>
                      <div>
                        {/* <Label className="label text-uppercase">
                          Id proof Number
                          <span className="field-required">*</span>
                        </Label> */}
                        <Input
                          type="text"
                          value={idproofvalue}
                          placeholder="ID NO"
                          onChange={(e) => setidproofvalue(e.target.value)}
                        />
                      </div>
                    </div>
                  </Col>
                </div>
              </Row>
              <div className="headling mt-2">
                <h3>Assessment</h3>
              </div>
              <div className=" mt-2">
                <Select
                  options={
                    prakutilist && prakutilist.length > 0
                      ? prakutilist.map((item) => {
                          return {
                            value: item.Id,
                            label: item.Values,
                            Aid: item.Aid,
                          };
                        })
                      : []
                  }
                  placeholder="Select Prakruti"
                  value={Prakruti}
                  onChange={handlePrakruti}
                  isSearchable={false}
                  isMulti
                />
              </div>
              <div className=" mt-2">
                <Select
                  options={
                    agnilist && agnilist.length > 0
                      ? agnilist.map((item) => {
                          return {
                            value: item.Id,
                            label: item.Values,
                            Aid: item.Aid,
                          };
                        })
                      : []
                  }
                  placeholder="Select agni"
                  value={agni}
                  onChange={handleagni}
                  isSearchable={false}
                  isMulti
                />
              </div>
              <div className=" mt-2">
                <Select
                  options={
                    koshtalist && koshtalist.length > 0
                      ? koshtalist.map((item) => {
                          return {
                            value: item.Id,
                            label: item.Values,
                            Aid: item.Aid,
                          };
                        })
                      : []
                  }
                  placeholder="Select koshta"
                  value={koshta}
                  onChange={handlekoshta}
                  isSearchable={false}
                  isMulti
                />
              </div>
              <div className=" mt-2">
                <Select
                  options={
                    jihwalist && jihwalist.length > 0
                      ? jihwalist.map((item) => {
                          return {
                            value: item.Id,
                            label: item.Values,
                            Aid: item.Aid,
                          };
                        })
                      : []
                  }
                  placeholder="Select Jihwa"
                  value={jihwa}
                  onChange={handlejihwa}
                  isSearchable={false}
                  isMulti
                />
              </div>
              <div className=" mt-2">
                <Select
                  options={
                    naadilist && naadilist.length > 0
                      ? naadilist.map((item) => {
                          return {
                            value: item.Id,
                            label: item.Values,
                            Aid: item.Aid,
                          };
                        })
                      : []
                  }
                  placeholder="Select Naadi"
                  value={naadi}
                  onChange={handlenaadi}
                  isSearchable={false}
                  isMulti
                  menuPlacement="top"
                />
              </div>
              <div className=" mt-2">
                <Select
                  options={
                    malalist && malalist.length > 0
                      ? malalist.map((item) => {
                          return {
                            value: item.Id,
                            label: item.Values,
                            Aid: item.Aid,
                          };
                        })
                      : []
                  }
                  placeholder="Select Mala"
                  value={mala}
                  onChange={handlemala}
                  isSearchable={false}
                  isMulti
                  menuPlacement="top"
                />
              </div>
              <div className=" mt-2">
                <Input
                  type="select"
                  value={mutra}
                  onChange={(e) => {
                    setmutra(e.target.value);
                    if (e.target.value == 1) {
                      setactiveprameha(true);
                      setprameha(mutralist[0].Items);
                    } else setactiveprameha(false);
                  }}
                >
                  <option>Select Mutra</option>
                  <option value={1}>Specific to Prameha</option>
                  {mutradata &&
                    mutradata.map((item) => {
                      return <option value={item.Id}>{item.Values}</option>;
                    })}
                </Input>
                <div className="mt-2 mb-2">
                  {activeprameha ? (
                    <Input
                      type="select"
                      value={mutra}
                      onChange={(e) => {
                        setmutra(e.target.value);
                        if (e.target.value == 8) {
                          setactivevalue(true);
                          setkaphaja(mutralist[1].Items);
                        } else if (e.target.value == 9) {
                          setactivevalue(true);
                          setpithaja(mutralist[2].Items);
                        } else if (e.target.value == 10) {
                          setactivevalue(true);
                          setvitaja(mutralist[3].Items);
                        }
                      }}
                    >
                      <option>Select Prameha</option>
                      <option value={8}>Kaphaja</option>
                      <option value={9}>Pithaja</option>
                      <option value={10}>Vataja</option>
                    </Input>
                  ) : (
                    ""
                  )}
                </div>
                {mutra == 8 ? (
                  <Select
                    options={
                      kaphaja && kaphaja.length > 0
                        ? kaphaja.map((item) => {
                            return {
                              value: item.Id,
                              label: item.Values,
                              Aid: item.Aid,
                            };
                          })
                        : []
                    }
                    placeholder="Select kaphaja"
                    value={finalmutra}
                    onChange={handlemutra}
                    isSearchable={false}
                    isMulti
                    menuPlacement="top"
                  />
                ) : mutra == 9 ? (
                  <Select
                    options={
                      pithaja && pithaja.length > 0
                        ? pithaja.map((item) => {
                            return {
                              value: item.Id,
                              label: item.Values,
                              Aid: item.Aid,
                            };
                          })
                        : []
                    }
                    placeholder="Select pithaja"
                    value={finalmutra}
                    onChange={handlemutra}
                    isSearchable={false}
                    isMulti
                    menuPlacement="top"
                  />
                ) : mutra == 10 ? (
                  <Select
                    options={
                      vitaja && vitaja.length > 0
                        ? vitaja.map((item) => {
                            return {
                              value: item.Id,
                              label: item.Values,
                              Aid: item.Aid,
                            };
                          })
                        : []
                    }
                    placeholder="Select vitaja"
                    value={finalmutra}
                    onChange={handlemutra}
                    isSearchable={false}
                    isMulti
                    menuPlacement="top"
                  />
                ) : (
                  ""
                )}
              </div>
              <div className=" mt-2" style={{ marginBottom: "124px" }}>
                <Select
                  options={
                    nidralist && nidralist.length > 0
                      ? nidralist.map((item) => {
                          return {
                            value: item.Id,
                            label: item.Values,
                            Aid: item.Aid,
                          };
                        })
                      : []
                  }
                  placeholder="Select Nidra"
                  value={nidra}
                  onChange={handlenidra}
                  isSearchable={false}
                  isMulti
                  menuPlacement="top" // Set the menu position to "top"
                />
              </div>
              <div id="scroll_id"></div>
            </Tab.Pane>

            <Tab.Pane eventKey={2} style={{ marginTop: "80px" }}>
              <Row>
                <Col>
                  {" "}
                  <div className=" mt-2">
                    {/* <Label className="label text-uppercase">BP</Label> */}
                    <Input
                      type="number"
                      value={bloodpre}
                      placeholder="BP"
                      onChange={(e) => {
                        setbloodpre(e.target.value);
                      }}
                    />
                  </div>
                </Col>
                <Col>
                  {" "}
                  <div className=" mt-2">
                    {/* <Label className="label text-uppercase">Pulse</Label> */}
                    <Input
                      type="number"
                      value={pulserate}
                      placeholder="Pulse"
                      onChange={(e) => {
                        setpulserate(e.target.value);
                      }}
                    />
                  </div>
                </Col>
                <Col>
                  <div className=" mt-2">
                    {/* <Label className="label text-uppercase">temperature</Label> */}
                    <Input
                      type="number"
                      value={temperature}
                      placeholder="Temp."
                      onChange={(e) => settemperature(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <div className=" mt-2">
                    {/* <Label className="label text-uppercase">weight</Label> */}
                    <Input
                      type="number"
                      value={weight}
                      placeholder="Weight"
                      onChange={(e) => (
                        setweight(e.target.value),
                        calculateBMI(height, e.target.value)
                      )}
                    />
                  </div>
                </Col>
                <Col>
                  <div className=" mt-2">
                    {/* <Label className="label text-uppercase">Height</Label> */}
                    <Input
                      type="number"
                      value={height}
                      placeholder="Height"
                      onChange={(e) => (
                        setheight(e.target.value),
                        calculateBMI(e.target.value, weight)
                      )}
                    />
                  </div>
                </Col>
                <Col>
                  <div className=" mt-2">
                    {/* <Label className="label text-uppercase">Height</Label> */}
                    <Input
                      type="number"
                      value={BMI}
                      readOnly
                      placeholder="BMI"
                      onChange={(e) => setBMI(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  {" "}
                  <div className=" mt-2">
                    {/* <Label className="label text-uppercase">SpO2</Label> */}
                    <Input
                      type="number"
                      value={spo2}
                      placeholder="SpO2"
                      onChange={(e) => setspo2(e.target.value)}
                    />
                  </div>
                </Col>
                <Col>
                  <div className=" mt-2">
                    {/* <Label className="label text-uppercase">RPG</Label> */}
                    <Input
                      type="text"
                      value={rpg}
                      placeholder="RPG"
                      onChange={(e) => setrpg(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
              <div className=" mt-2">
                {/* <Label className="label text-uppercase">Diagnosis Note</Label> */}
                <Input
                  type="textarea"
                  value={diagnosisnote}
                  placeholder="Diagnosis Note"
                  onChange={(e) => setdiagnosisnote(e.target.value)}
                />
              </div>
              <div className=" mt-2">
                <Select
                  options={
                    listcause && listcause.length > 0
                      ? listcause.map((item) => {
                          return {
                            value: item.Id,
                            label: item.Cause,
                          };
                        })
                      : []
                  }
                  placeholder="Select cause"
                  value={cause}
                  onChange={handlecause}
                  isSearchable={false}
                  // isMulti
                  menuPlacement="top"
                />
              </div>

              <div className="mt-1">
                <Select
                  options={
                    listlakshana && listlakshana.length > 0
                      ? listlakshana.map((item) => {
                          return {
                            value: item.Id,
                            label: item.Complaint,
                          };
                        })
                      : []
                  }
                  placeholder="Select Lakshana"
                  value={complaint}
                  onChange={handlecomplaint}
                  isSearchable={false}
                  // isMulti
                  menuPlacement="top"
                />
              </div>

             
              <div className="d-flex mt-2">
                <Label className="label text-uppercase">Disease Meter</Label>
              </div>

              <div className="range-meter-container">
                <input
                  type="range"
                  min="0"
                  max="100"
                  value={rating}
                  onChange={handleRatingChange}
                  className="range-meter"
                />
                <div className="range-value">{rating}%</div>
              </div>
              <div className="d-flex justify-content-between">
                <div className=" mt-3">
                  <Button
                    style={{
                      color: "black",
                      backgroundColor: "rgb(153 204 103)",
                      fontWeight: "400",
                      fontSize:'small',
                    }}
                    onClick={() => {
                      setModalType("MR");
                      setAddmodal(true);
                    }}
                  >
                    MEDICAL CONDITION
                  </Button>
                </div>

                <div className=" mt-3  ">
                  <Button
                    style={{
                      color: "black",
                      backgroundColor: "rgb(153 204 103)",
                      fontWeight: "400",
                      fontSize:'small',
                    }}
                    onClick={() => {
                      setdisplay("camera");
                      let data = [
                        {
                          Name: Patientsname,
                          birthday: birthday,
                          month: month,
                          year: year,
                          clinicid: clinicname,
                          age: age,
                          bmi: BMI,
                          Gender: sex,
                          Mobile: mobile,
                          Address: address,
                          EmailId: mailid,
                          IdType: idproof,
                          IdNo: idproofvalue,
                          agni: agni,
                          koshta: koshta,
                          jihwa: jihwa,
                          naadi: naadi,
                          mala: mala,
                          finalmutra: finalmutra,
                          mutra: mutra,
                          nidra: nidra,
                          Prakruti: Prakruti,
                          bloodpre: bloodpre,
                          pulserate: pulserate,
                          temperature: temperature,
                          weight: weight,
                          height: height,
                          spo2: spo2,
                          rpg: rpg,
                          cause: cause,
                          complaint: complaint,
                          diagnosisnote: diagnosisnote,
                          rating: rating,
                          day: day,
                          hb:hb,
                          cholesterol:cholesterol,
                          sugar:sugar,
                          thyriod:thyriod,
                          follow: follow,
                          characters: characters,
                          hiddenNotes: hiddenNotes,
                          pathyapathya: PathyaApathyaUpdatedList,
                          templatesave:templatesave,
                          medical:medical
                        },
                      ];
                      setdatastore(data);
                    }}
                  >
                    <i
                      class="fa-solid fa-upload"
                      style={{ marginRight: "10px" }}
                    ></i>
                    Take Image
                  </Button>
                </div>
              </div>

              {cameraimage?.length > 0 ? (
                <div
                  className=" mt-2  "
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Carousel interval={null} wrap={false} data-bs-theme="dark">
                    {cameraimage.map((item, index) => (
                      <Carousel.Item key={index}>
                        <img
                          className="d-block w-100"
                          src={item.Base64Image}
                          alt={`Captured ${index + 1}`}
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                </div>
              ) : (
                ""
              )}
            </Tab.Pane>

            <Tab.Pane eventKey={3} style={{ marginTop: "60px" }}>
              
              <Row>
              <div className=" mb-1 mt-3 ">
                  <strong className="">Pathya Apathya </strong>
                </div>

                <div className=" mt-2 ">
                  <div className="  d-flex mt-2">
                    <div style={{ width: "310px" }}>
                      {" "}
                      <Select
                        value={PathyaApathyaUpdatedList}
                        onChange={(selectedOptions) =>
                          SetPathyaApathyaValues(selectedOptions)
                        }
                        options={
                          pathyapthyalist && pathyapthyalist.length > 0
                            ? pathyapthyalist.map((item) => {
                                return {
                                  value: item.Id,
                                  label: item.Pathyaapathya,
                                };
                              })
                            : []
                        }
                        placeholder="Select Pathya Apathya"
                        isMulti
                        isSearchable={false}
                      />
                    </div>
                    <div style={{ marginTop: "5px" }}>
                      <PlusCircle size={24} onClick={PlusButtonClick} />
                    </div>
                  </div>
                </div>
                {IsPlusButtonCliked && (
                  <div className=" mt-2 ">
                    <div className=" d-flex ">
                      <div>
                        <Input
                          type="text"
                          value={NewPathyaApathyValue}
                          onChange={(e) =>
                            setNewPathyaApathyValue(e.target.value)
                          }
                        />
                      </div>
                      <div>
                        <Input
                          style={{
                            width: "20px",
                            height: "20px",
                            marginLeft: "10px",
                          }}
                          type="checkbox"
                          checked={IsCheckBoxClicked}
                          onChange={SetCheckBoxClick}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className=" mt-2">
                  <Input
                    type="textarea"
                    value={hiddenNotes}
                    placeholder="Hidden note"
                    onChange={(e) => sethiddenNotes(e.target.value)}
                  />
                </div>
                <div className=" mb-3 mt-3 ">
                  <strong className="">Drug Details </strong>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="mb-2">
                    
                    <Input
                      type="number"
                      
                      placeholder="Days*"
                      value={day}
                      style={{width:'70%'}}
                      onChange={(e) => {
                        setday(e.target.value);
                        const currentDate = new Date();
                        const dayNumber = parseInt(e.target.value, 10);
                        const newDate = new Date(currentDate);
                        newDate.setDate(currentDate.getDate() + dayNumber);

                        const options = {
                          year: "numeric",
                          month: "short",
                          day: "2-digit",
                        };
                        const formattedDate = newDate.toLocaleDateString(
                          "en-US",
                          options
                        );

                        setfollow(formattedDate);
                      }}
                    />
                  </div>
                  <Button
  className="btn"
  style={{
    backgroundColor: "rgb(153 204 103)",
    color: "black",
    fontWeight: "400",
fontSize:'small',
    height: "fit-content",
  }}
  onClick={() => {
   
    if (day) {
      setModalType("drug");
      setAddmodal(true);
    } else {
      alert("First enter the day");
    }
  }}
>
  <i className="fas fa-plus"></i>  Drug
</Button>
                 

                </div>
              
              {characters && characters.length>0? <DragDropContext onDragEnd={handleOnDragEnd}>
  <Droppable droppableId="characters">
    {(provided) => (
      <Table
        responsive
        className="table"
        style={{fontSize:'14px'}}
        {...provided.droppableProps}
        innerRef={provided.innerRef}
      >
        <thead>
          <tr>
            <th scope="col">Yogam</th>
            <th scope="col">Instruction</th>
            <th scope="col">Days</th>
            <th scope="col" >
              <Menu size={20} className="title-header" />
            </th>
          </tr>
        </thead>
        <tbody>
          {characters &&
            characters.map((item, index) => (
              <Draggable
                key={item.id}
                draggableId={item.id}
                index={index}
              >
                {(provided) => (
                  <tr
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <td>{item.yogam}</td>
                    <td>{item.instruction}</td>
                    <td>{item.Days}</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <button
                          className="btn btn-light"
                          onClick={(e) => {
                            // console.log("item", item);
                            setEditValue(item);
                            setModalType("drug");
                            setAddmodal(true);
                          }}
                        >
                          <i className="far fa-pen-to-square" style={{ fontWeight: 700 }}></i>
                        </button>
                        <button
                          className="btn btn-light ml-2"
                          onClick={() => handledelete(item.id)}
                        >
                          <i className="fas fa-trash"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                )}
              </Draggable>
            ))}
          {provided.placeholder}
        </tbody>
      </Table>
    )}
  </Droppable>
</DragDropContext>:''}
<div className="d-flex mt-2">
  <div style={{marginRight:'10px'}}> Set Drug As Template </div>
  <div >  <Input
                      type="checkbox"
                      id="template"
                      value={templatesave}
                      checked={templatesave}
                      onChange={(e)=>{
                        settemplatesave(e.target.checked);
                      }}
                      style={{
                        marginRight: "10px",
                        height: "20px",
                        width: "20px",
                      }}
                    /></div>
           </div>
                <div>
                 
                  <strong className="">Investigation</strong>
                </div>
                <div >
                  <div>
                    <Input
                      type="checkbox"
                      id="hb"
                      value={hb}
                      checked={hb}
                      onChange={(e)=>{
                        sethb(e.target.checked);
                      }}
                      style={{
                        marginRight: "10px",
                        height: "20px",
                        width: "20px",
                      }}
                    />
                    HB
                  </div>
                  <div>
                    <Input
                      type="checkbox"
                      id="cholesterol"
                      value={cholesterol}
                      checked={cholesterol}
                      onChange={(e)=>{
                        setcholesterol(e.target.checked);
                      }}
                      style={{
                        marginRight: "10px",
                        height: "20px",
                        width: "20px",
                      }}
                    />
                    cholesterol
                  </div>
                  <div>
                    <Input
                      type="checkbox"
                      id="sugar"
                      value={sugar}
                      checked={sugar}
                      onChange={(e)=>{
                        setsugar(e.target.checked);
                      }}
                      style={{
                        marginRight: "10px",
                        height: "20px",
                        width: "20px",
                      }}
                    />
                    sugar
                  </div>
                  <div>
                    <Input
                      type="checkbox"
                      id="thyriod"
                      value={thyriod}
                      checked={thyriod}
                      onChange={(e)=>{
                        setthyriod(e.target.checked);
                      }}
                      style={{
                        marginRight: "10px",
                        height: "20px",
                        width: "20px",
                      }}
                    />
                    thyriod
                  </div>
                </div>
                <div className="d-flex mt-2">
                  <strong className="">Add Image</strong>
                </div>
                <div>
                  <Input
                    type="file"
                    value={drugimage}
                    onChange={(e) => setdrugimage(e.target.value)}
                  />
                </div>

               

                <div className=" mt-2" style={{ marginBottom: "80px" }}>
                  <Input
                    type="text"
                    value={follow}
                    onChange={(e) => setfollow(e.target.value)}
                  />
                </div>
              </Row>
            </Tab.Pane>
            <div className="cvfooter-wrap">
              <div className="progressbar">
                <hr style={{ width: "10%" }} />
              </div>
              <div className="row">
                <div className="col-3 d-flex align-items-center justify-content-center">
                  <AiFillSave
                    color="black"
                    size={35}
                    onClick={() => {

                      handlesumbit();
                    }}
                  />
                </div>
                <div className="col-3 d-flex align-items-center justify-content-center">
                  <AiOutlinePrinter
                    color="black"
                    size={35}
                    onClick={(event) => {
                      if (prescription[0]?.Id) {
                        event.preventDefault();
                        setModalType("print");
                        setAddmodal(true);
                       
                      } else {
                        alert("Please save prescription");
                      }
                    }}
                  />
                </div>
                <div className="col-3 d-flex align-items-center justify-content-center">
                  {prescription[0]?.Id ? (
                    <WhatsappShareButton url={shareUrl} title={shareMessage}>
                      <AiOutlineShareAlt color="black" size={35} />
                    </WhatsappShareButton>
                  ) : (
                    // alert("Please save prescription");
                    <AiOutlineShareAlt color="black" size={35} onClick={()=>alert("Please save prescription")}/>
                  )}
                </div>

                <div className="col-3 d-flex align-items-center justify-content-center">
                  <VscNewFile color="black" size={35} onClick={clearData} />
                </div>
              </div>
            </div>
          </Tab.Content>
        </div>
      </Tab.Container>

      {addmodal && rendermodal()}
    </div>
  );
}

export default NewDesign;
