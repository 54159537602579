import ApiClient from '../ApiClient'


class patientservice {
    constructor(callback) {
        this.callback = callback
    }
        async patienthistory(payload) {
        const response = await ApiClient.get(
            `Patient/GetPatientHistory?PatientId=${payload.PatientId}`,
            payload,
        ).then((response) => {
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
    
        async doctorchatid(payload) {
        const response = await ApiClient.get(
            `Messages/GetDoctorIdForChat?PatientId=${payload.PatientId}`,
            payload,
        ).then((response) => {
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
    
}
export default new patientservice();