import ApiClient from '../ApiClient'
import { Slide, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ErrorToast, SuccessToast } from '../../Pages/Common/toastify';
const apiToken = JSON.parse(localStorage.getItem('ApiToken_AyuRx'));
class adminservice {



    constructor(callback) {
        this.callback = callback
    }

    async adduser(payload) {
        const response = await ApiClient.post(
            'Master/SetUser',
            payload,{
            headers:{Authorization: `Bearer${apiToken}`|| ''}},
        ).then((response) => {
            console.log("response",response.data );
            if (response.data?.Error === 0 && response.data?.Id > 0) {
                toast.success(
                  <SuccessToast message={"User saved succesfully"} />,
                  { transition: Slide, hideProgressBar: true, autoClose: 2000 }
                );
             
              }
              else {
                toast.error(<ErrorToast msg={"Error"} />, {
                  transition: Slide,
                  hideProgressBar: true,
                  autoClose: 2000,
                });
              }
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
        
        return response;
    }
    async getusertype(payload) {
        const response = await ApiClient.get(
            'Master/GetUserType',
            payload,
        ).then((response) => {
            return response.data;
            
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
    async addattribute(payload) {
        const response = await ApiClient.post(
            'Master/SetAttributtes',
            payload,
        ).then((response) => {
           
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
    async drugmaster(payload) {
        const response = await ApiClient.post(
            'Master/SetDrugMaster',
            payload,
        ).then((response) => {
            if (response.data[0]?.Error === 0 && response.data[0]?.Id > 0) {
                toast.success(
                  <SuccessToast message={"Drug saved succesfully"} />,
                  { transition: Slide, hideProgressBar: true, autoClose: 2000 }
                );
             
              }
              else {
                toast.error(<ErrorToast msg={"Error"} />, {
                  transition: Slide,
                  hideProgressBar: true,
                  autoClose: 2000,
                });
              }
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
    async adddoctor(payload) {
        const response = await ApiClient.post(
            'Doctor/SetDoctor',
            payload,
        ).then((response) => {
           
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
    async idtype(payload) {
        const response = await ApiClient.get(
            'Master/GetIdType',
            payload,
        ).then((response) => {
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
    async addclinic(payload) {
        const response = await ApiClient.post(
            'Doctor/SetClinic',
            payload,
        ).then((response) => {
           
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
    async adminnotifi(payload) {
        const response = await ApiClient.post(
            `Messages/SendPushNotificationsAdminThrougWhatsapp?Message=${payload.Message}`,
            payload,
        ).then((response) => {
           
            if (response.data) {
                toast.success(
                  <SuccessToast message={"Notification Saved Succesfully"} />,
                  { transition: Slide, hideProgressBar: true, autoClose: 2000 }
                );
             
              }
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
    async getfeedback(payload) {
        const response = await ApiClient.get(
           'Master/GetFeedBack',
            payload,
        ).then((response) => {
           
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
    async addfeedback(payload) {
        const response = await ApiClient.post(
            'Master/AddFeedBack',
            payload,
        ).then((response) => {
            console.log("response",response.data );
            if (response.data) {
                toast.success(
                  <SuccessToast message={"Feedback Saved Succesfully"} />,
                  { transition: Slide, hideProgressBar: true, autoClose: 2000 }
                );
             
              }
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
   
    
}
export default new adminservice();