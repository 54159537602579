import { takeEvery, put, call, all } from "redux-saga/effects";
import {actionTypes,adduserSucces,adduserError,drugmasterError,
  getusertypeSucces,getusertypeError,drugmasterSucces,addattributeSucces,
  addattributeError,adddoctorSucces,adddoctorError,idtypeSucces,
  idtypeError,addclinicSucces,addclinicError,addfeedbackSucces,addfeedbackError,
  adminnotifiSucces,adminnotifiError,getfeedbackSucces,getfeedbackError} from "./action";
import adminservice from "../../service/admin/adminservice"
import {startLoader,stopLoader} from '../Common/Loader/action'


function* adduser({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(adminservice.adduser, payload);
    yield put(adduserSucces(data));
  } catch (error) {
    yield put(adduserError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}
function* getusertype({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(adminservice.getusertype, payload);
    yield put(getusertypeSucces(data));
  } catch (error) {
    yield put(getusertypeError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}
function* addattribute({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(adminservice.addattribute, payload);
    yield put(addattributeSucces(data));
  } catch (error) {
    yield put(addattributeError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}
function* drugmaster({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(adminservice.drugmaster, payload);
    yield put(drugmasterSucces(data));
  } catch (error) {
    yield put(drugmasterError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}
function* adddoctor({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(adminservice.adddoctor, payload);
    yield put(adddoctorSucces(data));
  } catch (error) {
    yield put(adddoctorError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}
function* idtype({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(adminservice.idtype, payload);
    yield put(idtypeSucces(data));
  } catch (error) {
    yield put(idtypeError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}
function* addclinic({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(adminservice.addclinic, payload);
    yield put(addclinicSucces(data));
  } catch (error) {
    yield put(addclinicError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}
function* addfeedback({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(adminservice.addfeedback, payload);
    yield put(addfeedbackSucces(data));
  } catch (error) {
    yield put(addfeedbackError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}
function* adminnotifi({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(adminservice.adminnotifi, payload);
    yield put(adminnotifiSucces(data));
  } catch (error) {
    yield put(adminnotifiError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}
function* getfeedback({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(adminservice.getfeedback, payload);
    yield put(getfeedbackSucces(data));
  } catch (error) {
    yield put(getfeedbackError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}



export default function* rootSaga() {
  yield all([takeEvery(actionTypes.ADD_USER, adduser)]);
  yield all([takeEvery(actionTypes.GET_USER_TYPE, getusertype)]);
  yield all([takeEvery(actionTypes.ADD_ATTRIBUTE, addattribute)]);
  yield all([takeEvery(actionTypes.DRUG_MASTER, drugmaster)]);
  yield all([takeEvery(actionTypes.ADD_DOCTOR, adddoctor)]);
  yield all([takeEvery(actionTypes.ID_TYPE, idtype)]);
  yield all([takeEvery(actionTypes.ADD_CLINIC, addclinic)]);
  yield all([takeEvery(actionTypes.ADD_FEEDBACK, addfeedback)]);
  yield all([takeEvery(actionTypes.ADMIN_NOTIFICATION, adminnotifi)]);
  yield all([takeEvery(actionTypes.GET_FEEDBACK, getfeedback)]);
 
}