import { Fragment } from 'react'

import { Check, X } from 'react-feather'



export const SuccessToast = ({ message }) => (
    <Fragment>
        <div className='toastify-header'>
            <div className='title-wrapper'>
               
                <h6 className='toast-title'>Success!</h6>
            </div>
        </div>
        <div className='toastify-body'>
            <span role='img' aria-label='toast-text'>
                {message}
            </span>
        </div>
    </Fragment>
)

export const ErrorToast = ({ message }) => (
    <Fragment>
        <div className='toastify-header'>
            <div className='title-wrapper'>
                
                <h6 className='toast-title'>Error!</h6>
            </div>
        </div>
        <div className='toastify-body'>
            <span role='img' aria-label='toast-text'>
                {message}
            </span>
        </div>
    </Fragment>
)