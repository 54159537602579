

import { AvForm } from "availity-reactstrap-validation-safe";
import React, { useEffect, } from "react";
import QRCode from "react-qr-code";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  Button,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { patienthistory } from "../../redux/Patient/action";

function QRcodemodel({ setAddmodal, addmodal,setSidebarVisible,sidebarVisible }) {
    const dispatch = useDispatch()
  
    const patientdetails = JSON.parse(localStorage.getItem("UserDAta_AyuRx"));
    const patient = useSelector((state) => state.Patient.patienthistorydetails);
    console.log("patient", patient[0]);
  const PatientData= patient[0]
  console.log(PatientData);
    useEffect(() => {
      dispatch(
        patienthistory({
          PatientId: patientdetails.Id,
        })
      );
    }, []);
    
  let value=patient[0]?.prescriptionNo.toString()
  return (
    <Modal isOpen={addmodal} toggle={() => setAddmodal(!addmodal)}>
      <AvForm>
        <ModalHeader toggle={() => setAddmodal(!addmodal)}>
          {"QR CODE"}
        </ModalHeader>
        <ModalBody>
          <FormGroup>
          <div>
        {(patient&&patient.length>0)&&<QRCode
    size={230}
    style={{ height: "auto", maxWidth: "50%", width: "50%" }}
    value={value}
    viewBox={`0 0 256 256`}
    />}
        </div>
          </FormGroup>
        </ModalBody>
        
      </AvForm>
    </Modal>
  );
}

export default QRcodemodel;
