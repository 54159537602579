import React from 'react'
import { useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { ArrowLeft } from 'react-feather';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { dailypatient } from '../../redux/Report/action';

import * as XLSX from 'xlsx';

import { RiFileExcel2Fill } from 'react-icons/ri';
import { useParams } from "react-router-dom"; 
// import { Plugins } from '@capacitor/core';
// const { Filesystem } = Plugins;

function Dailypatientrport () {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {id} =useParams()
    const dailyreport = useSelector((state) => state.Report.patientdailybased);
 


    useEffect(()=>{
      dispatch(dailypatient({
          Did:id,
      }))
  },[])

    const exportToExcel = () => {
      
      let newArray = dailyreport.map(obj => {
        // Create a shallow copy of the object to avoid modifying the original
        let newObj = { ...obj };
 
        delete newObj.Id;
        return newObj;
      });
     
      const dataArray = newArray.map(item => Object.values(item));
   
      const ws = XLSX.utils.aoa_to_sheet([Object.keys(newArray[0]), ...dataArray]);
  
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
      XLSX.writeFile(wb, 'Daily Patient Report.xlsx');
    };
    

   
  return (
    <div>
         <div className="auth-wrapper">
        
        <div className=" pt-3 pl- underline d-flex ">
          <div><h3 className="text-capitalize d-flex" style={{ marginLeft: "18px" }}>
            {/* <ArrowLeft
              size={22}
              onClick={() => {
                navigate("/prescription");
              }}
            /> */}
            <div className="mr-2" style={{ marginLeft: "18px" }}>
             Daily Patient Report
            </div>
          </h3></div>
          <div style={{ marginLeft: "38px" }}><RiFileExcel2Fill size={30} color='green' onClick={exportToExcel} /></div>
          
          
        </div>
        <div className='mt-3'>
        <div className="ResponsiveTable" style={{paddingTop:'15px'}}>
  <div id="wrapper">
    <table>
      <thead>
        <tr>
          <th>SlNo</th>
          <th>Name</th>
          <th>Mobile</th>
          <th>Address</th>
         
        </tr>
      </thead>
      <tbody>
      {dailyreport &&
        dailyreport.map((item, i) => (
        <tr key={i}>
          <td data-label="SlNo:">{i+1}</td>
          <td data-label="Name:">{item.Name}</td>
          <td data-label="Mobile:">{item.Mobile}</td>
          <td data-label="Address:">{item.Address}</td>
        </tr>))}
      </tbody>
    </table>
  </div>
</div>
            </div>
        </div>
    </div>
  )
}

export default Dailypatientrport