

import { AvForm } from "availity-reactstrap-validation-safe";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { addfeedback } from "../../../redux/Admin/action";
import { help } from "../../../redux/Doctor/action";

// import { ErrorToast, SuccessToast } from "../../Common/toastify";
function HelpModal({ setAddmodal, addmodal,setSidebarVisible,sidebarVisible,setModalType }) {
  const dispatch = useDispatch();
  const [Message, setMessage] = useState();
  const doctordetails = JSON.parse(localStorage.getItem("UserDAta_AyuRx"));


  const handlesumbit = () => {
    if(Message)
    {
      dispatch(help({
        Message:Message,
        DoctorID: doctordetails.Id,
       
      }))
      setMessage("")
      setAddmodal(!addmodal)
      
    }else{
      alert("Enter the message")
    }
    setModalType("")
  };
  return (
    <Modal isOpen={addmodal} toggle={() => setAddmodal(!addmodal)}>
      <AvForm>
        <ModalHeader toggle={() => {setAddmodal(!addmodal)
        setModalType("")}}>
          {"Sent Message to Admin"}
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <div>
              <Label className="label text-uppercase">Enter Your Message</Label>
              <Input
                type="textarea"
                id="company_name"
                value={Message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={handlesumbit}>Submit</Button>
        </ModalFooter>
      </AvForm>
    </Modal>
  );
}

export default HelpModal;
