import { takeEvery, put, call, all } from "redux-saga/effects";
import {actionTypes,dailypatientSucces,dailypatientError} from "./action";
import {startLoader,stopLoader} from '../Common/Loader/action'
import reportservice from "../../service/report/reportservice";

function* dailypatient({ payload }) {
    try {
      yield put(startLoader());
      const data = yield call(reportservice.dailypatient, payload);
      yield put(dailypatientSucces(data));
    } catch (error) {
      yield put(dailypatientError(error));
      console.log(error);
    } finally {
      yield put(stopLoader());
    }
  }

  export default function* rootSaga() {
    yield all([takeEvery(actionTypes.DAILY_PATIENT, dailypatient)]);
    
   
  }