import ApiClient from "../ApiClient";


class reportservice {
  constructor(callback) {
    this.callback = callback;
  }

  async dailypatient(payload) {
    const response = await ApiClient.get(
      `controller/GetDailyPatientReport?Did=${payload.Did}`,
      payload
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
    return response;
  }
}
export default new reportservice();
