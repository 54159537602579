const initialState = {
    loader: false
}

const loaderreducer = (state = initialState, action) => {
    switch (action.type) {
        case 'START_LOADER':
            return { ...state, loader: true }
        case 'STOP_LOADER':
            return { ...state, loader: false }
        default:
            return state
    }
}

export default loaderreducer