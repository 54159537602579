import React, { useEffect, useRef, useState } from "react";
import Logo from "../../Images/Img/Logo.png";
import { Table } from "reactstrap";
import '../../CSSstyle/patientpagecss/patientview.css'
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { print } from "../../redux/Doctor/action";
import { useReactToPrint } from "react-to-print";
import { useParams } from "react-router-dom";

function formatDate(inputDate) {
  const date = new Date(inputDate);
  const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
  return date.toLocaleDateString(undefined, options);
}



function Printbrower() {
  const [PrintValue, setPrintValue] = useState();
  const dispatch =useDispatch()
  const {id} =useParams()

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  }

  const Dataprint = useSelector((state) => state.Doctor.printdata);
  useEffect(()=>{
    dispatch(
      print({
        PatientId:id
      })
    )
  },[])
  useEffect(()=>{
    if(Dataprint){
      setPrintValue(Dataprint[0])
    }
  },[Dataprint])
  
  console.log("PrintValue",PrintValue);

  const followup = PrintValue?.FollowUpDate;
  const datefollowup = formatDate(followup);

  const prescDate = PrintValue?.Date;
  const prescriptiondate = formatDate(prescDate);

  const componentRef = useRef();
  const handleprint =()=>{
    window.print()
  }

  
  
  useEffect(() => {
      if(PrintValue){
        handleprint()
      }
  }, [PrintValue])
  return (
    <div id="print-page" className="container print-page p-2 viewpage_header"  >
      <div ref={componentRef}>
        <div className="d-flex justify-content-center">
          <img src={Logo} alt="AruRX" height="50px" width="50px"  crossOrigin="anonymous" />
        </div>

       
        <div className="d-flex justify-content-between  underline">
          <div className="mt-3">
            <div className="title-name d-flex">
          Serial No:{PrintValue?.PatientSerial}
              <div style={{ marginLeft: "20px" }}>{PrintValue?.Name}</div>
            </div>
            
            
          </div>
          
        </div>
       
      
{PrintValue?.Pathyapathya ?( <div>
          <div className="title-name">Pathya Apathya:</div>
          {PrintValue?.Pathyapathya && PrintValue?.Pathyapathya.map((item,i)=>{
                return(
                  <div>
                    <div>{i + 1}.{item.PathyaPathya}</div>
                    
                    </div>
                )
              })}
        </div>):""}
       
       
        <div className="d-flex justify-content-end title-name">
          Follow up :{datefollowup}
        </div>
        <div className="d-flex justify-content-between p-2 mt-5">
          <div>Signature</div>
       
        </div>
      </div>
    </div>
  );
}

export default Printbrower;
