import React, { useState } from 'react'
import "../../CSSstyle/chatpagecss/chat.css";
import { ArrowLeft } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import profic from '../../Images/Default_pfp.jpg'
import ReactSwitch from 'react-switch';

function Chatnavbar({checked,setChecked,doctor}) {
    const navigate =useNavigate()
   

    const handleChange = (val) => {
   
      
  if(checked ==false){
    alert("Do you want to allow the panic alert?")
    setChecked(val);
  }
  else{
    setChecked(val);
  }
      
      // dispatch(
      //   ActiveInactive({
      //     organization_id: userData.organization_id,
      //     business_unit_id: userData.business_unit_id,
      //     asset_register_id: selectedItem.asset_register_id,
      //     equipment_status: !checked == true ? "active" : "inactive",
      //   })
      // );
    };
    const contact = {
        name: 'achu',
        profilePic: profic,
        status: 'Online',
      };
  return (
    <div className='chatnav'>
        <div className="d-flex justify-content-between">
          <div className="d-flex" ><h3 className="text-capitalize">
            {/* <ArrowLeft
              size={22}
              onClick={() => {
                navigate("/dashboard");
              }}
            /> */}
            </h3>
             <img src={contact.profilePic} alt={contact.name} style={{marginLeft:'5px'}} className="profile-pic" />
             <h3 style={{marginTop:'5px'}}>{doctor}</h3></div>
             <div title="Active Equipment" data-placement="bottom">
<ReactSwitch
  checked={checked}
  onChange={handleChange}
/>
</div>
             
        </div>
    </div>
  )
}

export default Chatnavbar