// ** Redux Imports
import { combineReducers } from 'redux'

import loader from './Loader/reducer'
// import pickLocation from './pickLo/cation/reducer'


const commonReducer = combineReducers({
    loader,
    // pickLocation
})

export default commonReducer