import { actionTypes } from "./action";
export const initialState = {
    patienthistorydetails: "",
    doctorchat:'',
}
const patientReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.PATIENT_HISTORY:
            return { ...state, patienthistorydetails: "" };
        case actionTypes.PATIENT_HISTORY_SUCCESS:
            return { ...state, patienthistorydetails: action.payload };
        case actionTypes.PATIENT_HISTORY_ERROR:
            return { ...state, patienthistorydetails: "error" };

            
        case actionTypes.GET_DOCTOR_ID_CHAT:
            return { ...state, doctorchat: "" };
        case actionTypes.GET_DOCTOR_ID_CHAT_SUCCESS:
            return { ...state, doctorchat: action.payload };
        case actionTypes.GET_DOCTOR_ID_CHAT_ERROR:
            return { ...state, doctorchat: "error" };


 default:
            return state;
    }
};

export default patientReducer;