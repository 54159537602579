import React, { useEffect, useState } from "react";
import "../../../CSSstyle/AdminPageCss/AddUser.css";
import { useDispatch, useSelector } from "react-redux";
import { adduser, getusertype } from "../../../redux/Admin/action";
import { Slide, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Input, Label } from "reactstrap";
import { ErrorToast, SuccessToast } from "../../Common/toastify";


function AddUser() {
  const [Name, setName] = useState("");
  const [UserTypeId, setUserTypeId] = useState("3");
  const [Gender, setGender] = useState("default");
  const [Mobile, setMobile] = useState();
  const [validPhoneNumber, setValidPhoneNumber] = useState(true);
  const dispatch = useDispatch();
  
  


  const Clear = () => {
    setName("");
    setUserTypeId("3");
    setGender("default");
    setMobile("");
  };

  const Response = useSelector((state) => state.Admin.adduservalue);
  const UserType = useSelector((state) => state.Admin.getusertypevalue);
  const saveuser =useSelector((state)=> state.Admin.adduservalue)

  // console.log("UserType",UserType);

 
  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    setMobile(value);
    validatePhoneNumber(value);
  };

  const validatePhoneNumber = (value) => {
    const phoneRegex = /^[0-9]{10}$/;

    const isValid = phoneRegex.test(value);

    setValidPhoneNumber(isValid);
  };
  useEffect(() => {
    if (saveuser) {
      if (saveuser?.Error === 0 && saveuser?.Id > 0) {
        
        Clear()
     
      } 
    }
    
  }, [saveuser]);

  const handleadduser = () => {
   if(Name && UserTypeId && Gender && Mobile){
    dispatch(
      adduser({
        Name: Name,
        UserTypeId: UserTypeId,
        Gender: Gender,
        Mobile: Mobile,
        // UserId:
      })
    );
   
   }else{
    alert("Fill All Mandatory")
   }
  };

 

  // console.log("Gender", Gender);
  useEffect(() => {
    dispatch(getusertype());
  }, []);

 
  return (
    <div className="login-box">
      <div className="AddUser">
        <div className="mb-3">
          <Label className="label text-uppercase">Mobile No:<span className="field-required">*</span></Label>
          <Input
            type="number"
            name=""
            required=""
            className="no-spinners"
            value={Mobile}
            onChange={handlePhoneNumberChange}
          />
           {!validPhoneNumber && (
        <p style={{ color: 'red' }}> enter a valid 10-digit phone .</p>
      )}
        </div>
        <div className="mb-3">
          <Label className="label text-uppercase">Name:<span className="field-required">*</span></Label>
          <Input
            type="text"
            value={Name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <Label className="label text-uppercase">Gender:<span className="field-required">*</span></Label>
          <Input
            type="select"
            name="customerName"
            id="customerNameGender"
            required
            className="UserTypeSelect"
            value={Gender}
            onChange={(e) => setGender(e.target.value)}
          >
            <option value="default" disabled>
              Select Gender
            </option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </Input>
        </div>
        <div className="mb-3">
          <Label className="label text-uppercase">Type:<span className="field-required">*</span></Label>
          <Input
            name="customerName"
            id="customerNameUserType"
            type="select"
            className="UserTypeSelect"
            value={UserTypeId}
            onChange={(e) => setUserTypeId(e.target.value)}
          >
            <option value="3" >
             Admin
            </option>
            {/* {UserType.length &&
              UserType?.map((user, index) => (
                <option key={index} value={user.Id}>
                  {user.Name}
                </option>
              ))} */}
          </Input>
        </div>
        <div className="row d-flex">
          <div className="col-4">
            <Button className="ClearButton" onClick={Clear}>
              <i
                class="fa-solid fa-arrows-rotate"
                style={{ marginRight: "8px" }}
              ></i>
              Clear
            </Button>
          </div>
          <div className="col-8">
            <Button onClick={handleadduser}>
              <i
                class="fa-solid fa-user-plus"
                style={{ marginRight: "8px" }}
              ></i>
              Add
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddUser;
