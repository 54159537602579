import { actionTypes } from "./action";
export const initialState = {
    addprescription:'',
    adddrug:'',
    druginstruction:'',
    attribute:'',
    pathyapthya:'',
    searchpatient:"",
    agni:'',
    koshta:'',
    jihwa:'',
    naadi:'',
    mala:'',
    mutra:'',
    nidra:'',
    prakuti:'',
    drugtemplate:'',
    saveimage:'',
    pathyaadd:'',
    clinicset:'',
    qrscan:'',
    printdata:'',
    chatwithpatient:'',
    doctornotification:'',
    doctornotificationapprove:'',
    linksent:'',
    lakshana:'',
    cause:'',
    statusnoti:'',
    helpdoctor:'',
}

const doctorReducer = (state = initialState, action) => {
    switch (action.type) {
       
        case actionTypes.PRESCRIPTION_ADD:
            return { ...state, addprescription: "" };
        case actionTypes.PRESCRIPTION_ADD_SUCCESS:
            return { ...state, addprescription: action.payload,store:action.localstorageData };
        case actionTypes.PRESCRIPTION_ADD_ERROR:
            return { ...state, addprescription: "" };

        case actionTypes.ADD_DRUG:
            return { ...state, adddrug: "" };
        case actionTypes.ADD_DRUG_SUCCESS:
            return { ...state, adddrug: action.payload };
        case actionTypes.ADD_DRUG_ERROR:
            return { ...state, adddrug: "error" };

        case actionTypes.SAVE_IMAGE:
            return { ...state, saveimage: "" };
        case actionTypes.SAVE_IMAGE_SUCCESS:
            return { ...state, saveimage: action.payload };
        case actionTypes.SAVE_IMAGE_ERROR:
            return { ...state, saveimage: "error" };

        case actionTypes.DRUG_INSTRUCTION:
            return { ...state, druginstruction: "" };
        case actionTypes.DRUG_INSTRUCTION_SUCCESS:
            return { ...state, druginstruction: action.payload };
        case actionTypes.DRUG_INSTRUCTION_ERROR:
            return { ...state, druginstruction: "error" };

        case actionTypes.ATTRIBUTE:
            return { ...state, attribute: "" };
        case actionTypes.ATTRIBUTE_SUCCESS:
            return { ...state, attribute: action.payload };
        case actionTypes.ATTRIBUTE_ERROR:
            return { ...state, attribute: "error" };

        case actionTypes.PATHYA_APATHYA:
            return { ...state, pathyapthya: "" };
        case actionTypes.PATHYA_APATHYA_SUCCESS:
            return { ...state, pathyapthya: action.payload };
        case actionTypes.PATHYA_APATHYA_ERROR:
            return { ...state, pathyapthya: "error" };

        case actionTypes.SEARCH_PATIENT:
            return { ...state, searchpatient: "" };
        case actionTypes.SEARCH_PATIENT_SUCCESS:
            return { ...state, searchpatient: action.payload };
        case actionTypes.SEARCH_PATIENT_ERROR:
            return { ...state, searchpatient: '' };

        case actionTypes.AGNI_VALUE:
            return { ...state, agni: "" };
        case actionTypes.AGNI_VALUE_SUCCESS:
            return { ...state, agni: action.payload };
        case actionTypes.AGNI_VALUE_ERROR:
            return { ...state, agni: "error" };

        case actionTypes.KOSHTA_VALUE:
            return { ...state, koshta: "" };
        case actionTypes.KOSHTA_VALUE_SUCCESS:
            return { ...state, koshta: action.payload };
        case actionTypes.KOSHTA_VALUE_ERROR:
            return { ...state, koshta: "error" };

        case actionTypes.JIHWA_VALUE:
            return { ...state, jihwa: "" };
        case actionTypes.JIHWA_VALUE_SUCCESS:
            return { ...state, jihwa: action.payload };
        case actionTypes.JIHWA_VALUE_ERROR:
            return { ...state, jihwa: "error" };

        case actionTypes.NAADI_VALUE:
            return { ...state, naadi: "" };
        case actionTypes.NAADI_VALUE_SUCCESS:
            return { ...state, naadi: action.payload };
        case actionTypes.NAADI_VALUE_ERROR:
            return { ...state, naadi: "error" };

        case actionTypes.MALA_VALUE:
            return { ...state, mala: "" };
        case actionTypes.MALA_VALUE_SUCCESS:
            return { ...state, mala: action.payload };
        case actionTypes.MALA_VALUE_ERROR:
            return { ...state, mala: "error" };

        case actionTypes.MUTRA_VALUE:
            return { ...state, mutra: "" };
        case actionTypes.MUTRA_VALUE_SUCCESS:
            return { ...state, mutra: action.payload };
        case actionTypes.MUTRA_VALUE_ERROR:
            return { ...state, mutra: "error" };

        case actionTypes.NIDRA_VALUE:
            return { ...state, nidra: "" };
        case actionTypes.NIDRA_VALUE_SUCCESS:
            return { ...state, nidra: action.payload };
        case actionTypes.NIDRA_VALUE_ERROR:
            return { ...state, nidra: "error" };

        case actionTypes.PRAKUTI_VALUE:
            return { ...state, prakuti: "" };
        case actionTypes.PRAKUTI_VALUE_SUCCESS:
            return { ...state, prakuti: action.payload };
        case actionTypes.PRAKUTI_VALUE_ERROR:
            return { ...state, prakuti: "error" };

        case actionTypes.DRUG_TEMPLATE:
            return { ...state, drugtemplate: "" };
        case actionTypes.DRUG_TEMPLATE_SUCCESS:
            return { ...state, drugtemplate: action.payload };
        case actionTypes.DRUG_TEMPLATE_ERROR:
            return { ...state, drugtemplate: "error" };

        case actionTypes.ADD_PATHIYA:
            return { ...state, pathyaadd: "" };
        case actionTypes.ADD_PATHIYA_SUCCESS:
            return { ...state, pathyaadd: action.payload };
        case actionTypes.ADD_PATHIYA_ERROR:
            return { ...state, pathyaadd: "error" };


        case actionTypes.SET_CLINIC:
            return { ...state, clinicset: "" };
        case actionTypes.SET_CLINIC_SUCCESS:
            return { ...state, clinicset: action.payload };
        case actionTypes.SET_CLINIC_ERROR:
            return { ...state, clinicset: "error" };

        case actionTypes.SCAN_QR:
            return { ...state, qrscan: "" };
        case actionTypes.SCAN_QR_SUCCESS:
            return { ...state, qrscan: action.payload };
        case actionTypes.SCAN_QR_ERROR:
            return { ...state, qrscan: "error" };


        case actionTypes.PRINT:
            return { ...state, printdata: "" };
        case actionTypes.PRINT_SUCCESS:
            return { ...state, printdata: action.payload };
        case actionTypes.PRINT_ERROR:
            return { ...state, printdata: "error" };

        case actionTypes.CHAT_TO_PATIENT:
            return { ...state, chatwithpatient: "" };
        case actionTypes.CHAT_TO_PATIENT_SUCCESS:
            return { ...state, chatwithpatient: action.payload };
        case actionTypes.CHAT_TO_PATIENT_ERROR:
            return { ...state, chatwithpatient: "error" };

        case actionTypes.DOCTOR_NOTIFICATION:
            return { ...state, doctornotification: "" };
        case actionTypes.DOCTOR_NOTIFICATION_SUCCESS:
            return { ...state, doctornotification: action.payload };
        case actionTypes.DOCTOR_NOTIFICATION_ERROR:
            return { ...state, doctornotification: "error" };

        case actionTypes.NOTIFICATION_STATUS:
            return { ...state, statusnoti: "" };
        case actionTypes.NOTIFICATION_STATUS_SUCCESS:
            return { ...state, statusnoti: action.payload };
        case actionTypes.NOTIFICATION_STATUS_ERROR:
            return { ...state, statusnoti: "error" };

        case actionTypes.DOCTOR_NOTIFICATION_APPROVE:
            return { ...state, doctornotificationapprove: "" };
        case actionTypes.DOCTOR_NOTIFICATION_APPROVE_SUCCESS:
            return { ...state, doctornotificationapprove: action.payload };
        case actionTypes.DOCTOR_NOTIFICATION_APPROVE_ERROR:
            return { ...state, doctornotificationapprove: "error" };

        case actionTypes.SENT_LINK:
            return { ...state, linksent: "" };
        case actionTypes.SENT_LINK_SUCCESS:
            return { ...state, linksent: action.payload };
        case actionTypes.SENT_LINK_ERROR:
            return { ...state, linksent: "error" };

        case actionTypes.GET_LAKSHANA:
            return { ...state, lakshana: "" };
        case actionTypes.GET_LAKSHANA_SUCCESS:
            return { ...state, lakshana: action.payload };
        case actionTypes.GET_LAKSHANA_ERROR:
            return { ...state, lakshana: "error" };


        case actionTypes.GET_CAUSE:
            return { ...state, cause: "" };
        case actionTypes.GET_CAUSE_SUCCESS:
            return { ...state, cause: action.payload };
        case actionTypes.GET_CAUSE_ERROR:
            return { ...state, cause: "error" };


        case actionTypes.HELP:
            return { ...state, helpdoctor: "" };
        case actionTypes.HELP_SUCCESS:
            return { ...state, helpdoctor: action.payload };
        case actionTypes.HELP_ERROR:
            return { ...state, helpdoctor: "error" };

        default:
            return state;
    }
};

export default doctorReducer;