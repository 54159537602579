import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "../../CSSstyle/chatpagecss/chat.css";
import { ReactMic } from "react-mic";
import { v4 as uuid } from "uuid";
import { Button, Input } from "reactstrap";
import { AiOutlineDelete, AiOutlineSend } from "react-icons/ai";
import { BsFillMicFill } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { doctorchatid } from "../../redux/Patient/action";
import { useSelector } from "react-redux";
import Chatnavbar from "./Chatnavbar";

import { useLocation, useParams } from "react-router-dom";
import { baseUrl, siteUrl } from "../../configs/config";

function Chatpage() {
  const [messages, setMessages] = useState([]);
  const [singleMessage, setSingleMessage] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [audioData, setAudioData] = useState(null);
  const [doctorid,setdoctorid] =useState()
  const [checked, setChecked] = useState(false);
  const messageRef = useRef(null);
  const dispatch = useDispatch();
  const [shouldHide, setShouldHide] = useState(true);
  const {id,name,patid,patuserid} =useParams()
 

  const doctoridchat = useSelector((state) => state.Patient.doctorchat);


  useEffect(() => {
    dispatch(
      doctorchatid({
        PatientId: patid,
      })
    );
  }, []);

  useEffect(()=>{
    if(doctoridchat){
      setdoctorid(doctoridchat[0]?.Id)
    }
  },[])

  useEffect(() => {
    messageRef.current.scrollTop = messageRef.current.scrollHeight;
  }, [messages]);
  const [MessageLength, setMessageLength] = useState(0);

  // Use useRef to keep track of the latest MessageLength
  const latestMessageLength = useRef(MessageLength);
  useEffect(() => {
    latestMessageLength.current = MessageLength;
  }, [MessageLength]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          siteUrl +
            `/Messages/GetMessageWithChatMasterid?Chatid=${parseInt(id)}`
        );
        if (response.data.Error) {
          setMessages("");
        } else {
          const newLength = response.data.length;
          if (newLength !== latestMessageLength.current) {
            console.log('Message',response.data)
            setMessages(response.data);
            setMessageLength(newLength);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();

    const intervalId = setInterval(fetchData, 2000);
    return () => clearInterval(intervalId);
  }, [singleMessage]);

// console.log("parms.state.data",parms.state.data);

  const handleSendMessage = () => {
    if (singleMessage.trim() !== "") {
      const newMessage = {
        Id: 0,
        ChatMasterId:parseInt(id),
        Sender: parseInt(patuserid),
        Receiver: doctoridchat[0]?.Id,
        Text: singleMessage,
        Date: new Date().toISOString(),
        IsPanic: checked == true ? 1 : 0,
        Typeid: 1,
        VoiceName: "",
      };
      // console.log("newMessage",newMessage);
      // if (messages == "") {
      //   setMessages(newMessage);
      //   console.log('handleSendMessage',newMessage)
      // }
      // setMessages([...messages, newMessage]);

      
      axios
        .post(siteUrl + "/Messages/SendMessage", newMessage)
        .then((response) => {
          if (response.data.Error) {
            setMessages("");
          // } else {
           
          }
        });

      axios
        .get(
          siteUrl +
            `/Messages/GetMessageWithChatMasterid?Chatid=${parseInt(id)}`
        )
        .then((response) => {
          if (response.data) {
            console.log('handleSendMessage /Messages/GetMessageWithChatMasterid?Chatid',response.data)
            setMessages(response.data);
          }
        });

      setSingleMessage(""); 
    }
  };

  const onStartRecording = () => {
    setIsRecording(true);
  };
  
  const onStopRecording = () => {
    setIsRecording(false);
   
  };
  
  const onData = (recordedData) => {
    setAudioData(recordedData);
  };
  
  const clearAudioData = () => {
    setAudioData(null);
  };
  const onStop = (recordedBlob) => {
    setAudioData(recordedBlob);
   
  };

  const sendAudioToServer =()=>{
    if (audioData) {
      const blob = new Blob([audioData.blob], { type: "audio/mp3" });
      const unique_id = uuid();

      const fileName = `${patuserid + unique_id}_.mp3`;
      const sanitizedFileName = fileName.replace(/[:/]/g, "_");
      const audioFile = new File([blob], sanitizedFileName, {
        type: "audio/mp3",
      });

      const formData = new FormData();
      formData.append("audioFile", audioFile);

      axios
        .post(siteUrl + "/ImageUploader/UploadAudio", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          axios
                  .get(
                    siteUrl +
                      `/Messages/GetDoctorIdForChat?PatientId=${patid}`
                  )
                  .then((response) => {
                    console.log("responsedoc",response.data[0].Id);
                  

          let voiceMessage = {
            Id: 0,
            ChatMasterId: parseInt(id),
            Sender: parseInt(patuserid), // Set the default sender ID
            Receiver:response.data[0].Id,
            Text: "",
            Date: new Date().toISOString(),
            Typeid: 2,
            VoiceName: audioFile.name, // Use the audio file name
          };
// console.log("voiceMessage",voiceMessage)
          // Send the message to the server
          axios
            .post(siteUrl + "/Messages/SendMessage", voiceMessage)
            .then((response) => {
              if (response.data) {
                axios
                  .get(
                    siteUrl +
                      `/Messages/GetMessageWithChatMasterid?Chatid=${parseInt(id)}`
                  )
                  .then((response) => {
                    if (response.data) {
                      console.log("sendAudioToServer",response.data)
                      setMessages(response.data);
                    }
                  });
              }
            });

          setSingleMessage("");
        })
        .catch((error) => {
          console.error("Error uploading audio:", error);
        });
      });
    }
    setAudioData(null);
  }

  return (
    <div className="chat-container">
    <Chatnavbar setChecked={setChecked} checked={checked} 
    doctor={name}
    />
      <div className="message-container" ref={messageRef}>
      {messages.length &&
          messages.map((message, index) => {
        let dateTime = new Date(message.Date);
            const hours = dateTime.getHours();
            const minutes = dateTime.getMinutes();
            const amOrPm = hours >= 12 ? "PM" : "AM";
            const formattedHours = hours % 12 || 12;
            const formattedMinutes = minutes.toString().padStart(2, "0");
            const formattedTime = `${formattedHours}:${formattedMinutes} ${amOrPm}`;
        return(
         
       
    <div className="chat">
    <div
        key={index}
        className={` message-content ${
          message.Sender === parseInt(patuserid)? " incoming" : "outgoing"
        }`}
       
      >
        <br />
        {message.TypeId === 1 ? (
          <div className="message">
            <div style={{wordBreak:'break-word'}}><lable
              style={{ color: message.IsPanic === 1 ? "Red" : "black" }}
            >
              {message.Text}
            </lable></div>
            <span class="timestamp">{formattedTime}</span>
            
          </div>
        ) : message.TypeId === 2 ? (
          <div className="message">
            {/* Display the audio message */}
            <audio controls style={{ width: "186px", height: "33px" }}>
              <source
                src={`https://connect.ayurx.initstore.com/Asset/Audio/ChatAudio/${message.VoiceName}`}
                type="audio/mp3"
              />
            </audio>
            <span class="timestamp">{formattedTime}</span>
          </div>
        ) : null}
      </div>
    </div>
        )
      })}
      </div>
      <div style={{ display: shouldHide ? 'none' : 'block' }}><ReactMic
  record={isRecording}
  className="sound-wave"
  onStop={onStop}
  onData={onData}
  
/></div>
      



<div className="d-flex justify-content-between m-2" style={{minHeight:'110px'}}>
        <div>
          <Input
            type="text"
            placeholder="Type your message here..."
            className="input"
            value={singleMessage}
            style={{
              width: "100%",
              borderRadius: "10px",
              wordWrap: "break-word",
              minHeight:'70px'
            }}
            onChange={(e) => setSingleMessage(e.target.value)}
          />
        </div>
        <div>
          {singleMessage ? (
            <AiOutlineSend
              size={30}
              color="green"
              onClick={handleSendMessage}
            />
          ) : isRecording ? (
            <div className="d-flex ml-1">
              <Button color="green" onClick={onStopRecording}>
                stop
              </Button>
            </div>
          ) : audioData ? (
            <div className="d-flex">
              <Button color="green" onClick={sendAudioToServer}>
                send
              </Button>
              <Button color="red" onClick={clearAudioData}>
                delete
              </Button>
            </div>
          ) : (
            <BsFillMicFill size={30} color="green" onClick={onStartRecording} />
          )}
        </div>
      </div>
    </div>
  );
}

export default Chatpage;;