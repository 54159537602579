import React, { Fragment, useState } from 'react'
import Camerapage from './Camerapage';
import Prescription from './Prescription';

function Index() {
    const [display, setdisplay] = useState("list");
    const [cameraimage,setcameraimage] =useState([]);
    const [datastore,setdatastore] =useState();
    
  
    const renderBody = () => {
      switch(display){
        case 'list':
          return <Prescription display={display} setdisplay={setdisplay}cameraimage={cameraimage} setcameraimage={setcameraimage} setdatastore={setdatastore} datastore={datastore}/>;
          case 'camera':
            return <Camerapage display={display} setdisplay={setdisplay} cameraimage={cameraimage} setcameraimage={setcameraimage} setdatastore={setdatastore} datastore={datastore}/>;
          default:
            break;
      }
    }
    return (
      <div>
        <Fragment>
         
          <div id="item-profile " className="item-module">
            <section id="profile-info">{renderBody()}</section>
          </div>
        </Fragment>
      </div>
    )
  }
  
  export default Index