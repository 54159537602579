import React from "react";
import { Table } from "reactstrap";
import Logo from "../../Images/Img/Logo.png";
import "../../CSSstyle/patientpagecss/patientview.css";
import { ArrowLeft } from "react-feather";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";



function Viewpage() {
  const navigate = useNavigate();
  const parms = useLocation();

  const [patientdata, setpatientdata] = useState();


  useEffect(() => {
    if (parms.state) {
      setpatientdata(parms.state.data);
    }
  }, []);
  console.log("patientdata", patientdata);

  return (
    <div className="p-2 viewpage_headers">
      <div className=" pt-1 ">
        <div className="d-flex justify-content-between">
          <h3 className="text-capitalize">
            <ArrowLeft
              size={22}
              onClick={() => {
                navigate("/dashboard");
              }}
            />
          </h3>
        </div>
        <div className="d-flex justify-content-center">
          <img src={Logo} alt="AruRX" height="50px" width="50px" />
        </div>
        <div>
          {}
          <div className=" d-flex justify-content-between">
            <div className="division">
              <div className="title-name">DR.{patientdata?.DoctorName}</div>
              <div>Mobile No: {patientdata?.DoctorMobile}</div>
            </div>
            <div className="division">
              <div>{patientdata?.Clinic}</div>
            </div>
          </div>
        </div>
        <div className="m-3 d-flex justify-content-between">
          <div>Serial No :{patientdata?.PatientSerial} </div>
        </div>

        <div className="mt-3">
          <Table responsive>
            <thead>
              <tr>
                <th scope="col"> NO</th>
                <th scope="col">MEDICINE </th>
                <th scope="col">DOSAGE</th>
                <th scope="col">DURATION</th>
              </tr>
            </thead>
            {patientdata?.DrugDetailes ? (
              patientdata?.DrugDetailes.map((data, i) => {
                return (
                  <tbody key={i} className="list-body">
                    <tr>
                      <td>{i + 1}</td>
                      <td>{data.Drugname}</td>
                      <td>{data.Instruction}</td>
                      <td>{data.Days}</td>
                    </tr>
                  </tbody>
                );
              })
            ) : (
              <div className="text-center mt-3">No Records..!</div>
            )}
          </Table>
        </div>
        {patientdata?.PathyaApathya?(<div className="viewpage_complaint mt-2">
          
          <div className="title-name">Pathya Apathya:</div>
          <div> {patientdata?.PathyaApathya && patientdata?.PathyaApathya.map((item,i)=>{
            return(
              <div>
                <div>{i + 1}.{item.PathyaPathya}</div>
                
                </div>
            )
          })}</div>
        </div>):""}
        
        
      </div>
    </div>
  );
}

export default Viewpage;
