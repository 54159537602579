import React, { Fragment, useEffect, useRef, useState } from "react";
import Logo from "../../Images/Img/Logo.png";
import { useSelector } from "react-redux";
import { Table } from "reactstrap";
import '../../CSSstyle/patientpagecss/patientview.css'
import { ArrowLeft } from "react-feather";
import { useLocation, useNavigate } from "react-router-dom";
import { AiOutlinePrinter } from "react-icons/ai";
import { useReactToPrint } from 'react-to-print';

import { useDispatch } from "react-redux";
import { print } from "../../redux/Doctor/action";
import { baseUrl } from "../../configs/config";
// const { WebView } = Plugins;
function formatDate(inputDate) {
  const date = new Date(inputDate);
  const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
  return date.toLocaleDateString(undefined, options);
}

function SummaryPrint() {
  const navigate =useNavigate()
  const dispatch =useDispatch()
  const parms = useLocation()

  const [PrintValue, setPrintValue] = useState([]);
  const Dataprint = useSelector((state) => state.Doctor.printdata);
console.log("parms.state.data",parms.state.data);

 


 
 
useEffect(()=>{
  dispatch(
    print({
      PatientId:parms.state.data
    })
  )
},[])
    useEffect(()=>{
      if(Dataprint){
        setPrintValue(Dataprint[0])
      }
    },[Dataprint])

    console.log("PrintValue",PrintValue);


    const followup = PrintValue?.FollowUpDate;
    const datefollowup = formatDate(followup);

    const prescDate = PrintValue?.Date;
    const prescriptiondate = formatDate(prescDate);


 console.log("prescriptiondate",prescriptiondate);   
 
 
  return (
    <div id="print-page" className="container print-page p-2 viewpage_header">
  <div className="d-flex justify-content-between">
    <h3 className="text-capitalize">
      <ArrowLeft
        size={22}
        onClick={(e) => {
          e.preventDefault();
          navigate("/Prescription");
        }}
      />
    </h3>
    <div>
      <AiOutlinePrinter
        size={30}
        onClick={() => {
          window.open(baseUrl + `/print/${parms.state.data}`);
        }}
      />
    </div>
  </div>
  <div>
    <div className="d-flex justify-content-center">
      <img src={Logo} alt="AruRX" height="50px" width="50px" />
    </div>
    <div className="d-flex flex-column justify-content-between underline">
      <div className="mb-3">
        <div className="title-name">{PrintValue?.DoctorName}</div>
        <div>{PrintValue?.qualification}</div>
        <div>Reg No:{PrintValue?.RegNo}</div>
      </div>
      <div className="mb-3">
        <div className="title-name">{PrintValue?.Clinic}</div>
        <div>Mobile No: {PrintValue?.DoctorMobile}</div>
      </div>
    </div>
    <div className="d-flex justify-content-between underline">
      <div className="mt-3">
        <div className="title-name ">
          Serial No:{PrintValue?.PatientSerial}
          <div >Name:{PrintValue?.Name}</div>
        </div>
        <div className="title-name ">Address:{PrintValue?.Address}</div>
      </div>
      <div className="mt-3">
        <div>Date:{prescriptiondate}</div>
      </div>
    </div>
    <div className="mt-2">
      <div className="title-name">Chief Complaint</div>
      {PrintValue?.Complaint}
    </div>
    <div className="mt-3">
      <Table responsive>
        <thead>
          <tr>
            <th scope="col"> NO</th>
            <th scope="col">MEDICINE </th>
            <th scope="col">DOSAGE</th>
            <th scope="col">DURATION</th>
          </tr>
        </thead>
        {PrintValue?.DrugDetailes ? (
          PrintValue?.DrugDetailes.map((data, i) => {
            return (
              <tbody key={i} className="list-body">
                <tr>
                  <td>{i + 1}</td>
                  <td>{data.Drugname}</td>
                  <td>{data.Instruction}</td>
                  <td>{data.Days}</td>
                </tr>
              </tbody>
            );
          })
        ) : (
          <div className="text-center mt-3">No Records..!</div>
        )}
      </Table>
    </div>
    {PrintValue?.Pathyapathya ? (
      <div>
        <div className="title-name">Pathya Apathya:</div>
        {PrintValue?.Pathyapathya &&
          PrintValue?.Pathyapathya.map((item, i) => {
            return (
              <div key={i}>
                <div>{i + 1}.{item.PathyaPathya}</div>
              </div>
            );
          })}
      </div>
    ) : (
      ""
    )}
    <div className="d-flex justify-content-end title-name">
      Follow up :{datefollowup}
    </div>
    <div className="d-flex justify-content-between p-2 mt-5">
      <div>Signature</div>
    </div>
  </div>
</div>

    
  );
}

export default SummaryPrint;
