import { actionTypes } from "./action";
export const initialState = {
  patientdailybased: "",
};

const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DAILY_PATIENT:
      return { ...state, patientdailybased: "" };
    case actionTypes.DAILY_PATIENT_SUCCESS:
      return { ...state, patientdailybased: action.payload };
    case actionTypes.DAILY_PATIENT_ERROR:
      return { ...state, patientdailybased: "error" };
    default:
      return state;
  }
};

export default reportReducer;