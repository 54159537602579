import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Button, Input, Label } from "reactstrap";
import { addclinic, addclinicError } from '../../../redux/Admin/action';
import { toast, Slide } from 'react-toastify'
import { ErrorToast, SuccessToast } from '../../Common/toastify';
import { ArrowLeft } from 'react-feather';
import { useNavigate } from 'react-router-dom';


function AddClinic() {

    const[name,setname]=useState();
    const[email,setemail]=useState();
    const[phone,setphone]=useState();
    const[address,setaddress]=useState();
    const[website,setwebsite]=useState();
    const [validPhoneNumber, setValidPhoneNumber] = useState(true);
    const dispatch =useDispatch();
    const navigate = useNavigate();

    const saveclinc =useSelector((state)=> state.Admin.clinc)
    const doctordetails = JSON.parse(localStorage.getItem("UserDAta_AyuRx"));

   

 
  useEffect(() => {
    if (saveclinc) {
      
      if(saveclinc[0]?.Error === 0 && saveclinc[0]?.Id>0 ){
        toast.success(
            <SuccessToast message={'Clinic saved succesfully'} />,
            { transition: Slide, hideProgressBar: true, autoClose: 2000 }
        )
        Clear();
      // }else {
      //   toast.error(
      //       <ErrorToast msg={'Error'} />,
      //       { transition: Slide, hideProgressBar: true, autoClose: 2000 }
      //   )
    }

    }  
}, [saveclinc])

    const Clear = () => {
      setname("");
      setemail("");
      setphone("");
      setaddress("");
      setwebsite("");
      dispatch(addclinicError({}))
    };
  

    const handleSumbit = () =>{
      
      if(name && phone && email && phone && address && website){
        dispatch(
          addclinic({
            id: 0,
            name: name,
            address: address,
            mobile: phone,
            emailId: email,
            webSite: website,
            DoctorId:doctordetails.Id,
            status: 0
          })
        )
       
      }else{
        alert("Fill All Mandatory")
       }
    }

    const handlePhoneNumberChange = (e) => {
      const value = e.target.value;
      setphone(value);
      validatePhoneNumber(value);
    };
  
    const validatePhoneNumber = (value) => {
      const phoneRegex = /^[0-9]{10}$/;
  
      const isValid = phoneRegex.test(value);
  
      setValidPhoneNumber(isValid);
    };
  return (
    <div className='m-2'>
      <div className="AddUser">
      <div className=" pt-3 pl- underline ">
        <h3 className="text-capitalize d-flex" style={{ marginLeft: "18px" }}>
          {/* <ArrowLeft
            size={22}
            onClick={() => {
              navigate("/prescription");
            }}
          /> */}
          <div className="mr-2" style={{ marginLeft: "18px" }}>
          Add Clinic
          </div>
        </h3>
      </div>
        <div className="mb-2 mt-3">
          <Label className="label text-uppercase"> Clinic's Name<span className="field-required">*</span></Label>
          <Input
            type="text"
            required=""
            className="no-spinners"
            value={name}
            onChange={(e) => setname(e.target.value)}
          />
        </div>
        <div className="mb-2">
          <Label className="label text-uppercase"> Email id<span className="field-required">*</span></Label>
          <Input
            type="text"
            required=""
            className="no-spinners"
            value={email}
            onChange={(e) => setemail(e.target.value)}
          />
        </div>
        
          <div className="mb-2">
            
            <Label className="label text-uppercase"> Phone number<span className="field-required">*</span></Label>
            <Input
              type="number"
              required=""
              className="no-spinners"
              value={phone}
              onChange={handlePhoneNumberChange}
            />
             {!validPhoneNumber && (
        <p style={{ color: 'red' }}> enter a valid 10-digit phone .</p>
      )}
          </div>
          <div className="mb-2">
            
            <Label className="label text-uppercase"> Address<span className="field-required">*</span></Label>
            <Input
              type="textarea"
              required=""
              className="no-spinners"
              value={address}
              onChange={(e) => setaddress(e.target.value)}
            />
          </div>
          <div className="mb-2">
            
            <Label className="label text-uppercase"> website<span className="field-required">*</span></Label>
            <Input
              type="text"
              required=""
              className="no-spinners"
              value={website}
              onChange={(e) => setwebsite(e.target.value)}
            />
          </div>
          <div className="row  d-flex mt-4">
          <div className="col-4">
            <Button
              color="danger"
              className="ClearButton"
                onClick={Clear}
            >
              <i
                class="fa-solid fa-arrows-rotate"
                style={{ marginRight: "8px" }}
              ></i>
              Clear
            </Button>
          </div>
          <div className="col-8">
            <Button color="success"  onClick={handleSumbit}>
              <i
                className="fa-solid fa-check"
                style={{ marginRight: "8px" }}
              ></i>
              Save
            </Button>
          </div>
        </div>
        </div>
        </div>
  )
}

export default AddClinic