import { all } from 'redux-saga/effects'
import common from '../Common/commonSaga'
import Admin from '../Admin/saga'
import Doctor from '../Doctor/saga'
import Report from '../Report/saga'
import patient from '../Patient/saga'

export default function* rootSaga() {
    yield all([
        common(),
        Admin(),
        Doctor(),
        Report(),
        patient()
    ]);
}